<template>
<div class="container mx-auto">
    <loading v-if="isLoading" />
  <div v-if="!isLoading">
    <c-heading pt="2rem">Welcome, {{user.username}}</c-heading>
    <c-grid  :template-columns="['repeat(1, 1fr)','repeat(1, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)']" gap="6"
    justify="space-between" py="2rem" :px="['2rem','3rem','4rem','5rem']" alignItems="center" >
        <router-link to="/database">
            <c-stack 
            bgColor="white" 
            p="5rem" 
            :spacing="10" 
            :h="['380px','380px','430px','430px']"
            alignItems="center" 
            border-width="1px" 
            shadow="md"
            class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            :href="'/add-by-csv'"
            >
                <c-icon name="database" :size="['7.5rem','7.5rem','10rem','10rem']" />
                <c-text fontSize="3xl" class="text-center">Database </c-text>
            </c-stack>
        </router-link>
        <router-link to="/form">
            <c-stack 
            bgColor="white" 
            p="6rem" 
            :h="['380px','380px','430px','430px']"
            alignItems="center" 
            border-width="1px" 
            shadow="md"
            class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                <c-icon name="keyboard" :size="['7.5rem','7.5rem','7.5rem','10rem']" />
                <c-text fontSize="3xl" class="text-center">Insert new record</c-text>
            </c-stack>
        </router-link>
        <router-link to="/account">
            <c-stack 
            bgColor="white" 
            p="6rem" 
            :h="['380px','380px','430px','430px']"
            alignItems="center" 
            border-width="1px" 
            shadow="md"
            class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                <c-icon name="user-cog" :size="['7.5rem','7.5rem','7.5rem','10rem']" />
                <c-text fontSize="3xl" class="text-center">Account settings</c-text>
            </c-stack>
        </router-link>
    </c-grid>
  </div>
</div>
</template>

<script>
import UserStore from '../store/User'
export default {
    data() {
        return {
            user: {},
            isLoading: false,
        }
    },
    created() {
        UserStore.dispatch('toggleMenu', 2)
        this.getMe();
    },
    methods: {
        addDataByCSV() {
            this.$router.push('/form/add-by-csv')
        },
        async getMe() {
            try {
                this.isLoading = true
                let res = await UserStore.dispatch("getMe");
                if (res.status === 200) {
                    this.user = res.data;
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                console.error(error.message);
            }
        },
    }
}
</script>

<style>

</style>