import { render, staticRenderFns } from "./AddMicroorganismMethod.vue?vue&type=template&id=30d90733&"
import script from "./AddMicroorganismMethod.vue?vue&type=script&lang=js&"
export * from "./AddMicroorganismMethod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CIcon: require('@chakra-ui/vue').CIcon, CText: require('@chakra-ui/vue').CText, CStack: require('@chakra-ui/vue').CStack, CGrid: require('@chakra-ui/vue').CGrid})
