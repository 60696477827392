import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      organizations: [],
  },
  getters: {
      getOrganizations: (state) => state.organizations,
  },
  mutations: {
      setOrganizations(state, data) {
          state.organizations = data;
      }
  },
  actions: {
      async fetchOrganizations({ commit }) {
          try {
              let res = await axios.get(`/dropdown`);
              if (res.status === 200) {
                  commit('setOrganizations', res.data.organization)
                  return {
                      status: 200,
                  }
              }
          } catch (error) {
              throw error;
          }
      }
  },
  modules: {
  }
})
