<template>
  <div class="container px-40 mx-auto">
    <loading v-if="isLoading" />
    <c-flex flexDirection="row" justify="flex-start">
      <c-button
        size="lg"
        mt="2rem"
        variant-color="blue"
        @click="handleBackToWelcome"
      >
        <c-icon name="home" mr="0.5rem" />กลับหน้าหลัก</c-button
      >
    </c-flex>
    <c-text fontSize="3xl" mt="1rem">{{ heading }}</c-text>
    <hr />
    <c-text mt="1rem"
      ><span class="text-red-600">* is required field</span></c-text
    >
    <c-stack my="2rem">
      <div class="relative pt-1">
        <div class="relative flex justify-between items-center mb-4">
          <div
            class="
              bg-blue-500
              h-12
              w-12
              mr-4
              rounded-full
              flex
              items-center
              justify-center
              text-white
              cursor-pointer
              z-10
            "
            @click="
              step = 0;
              percent = (step / 5) * 100;
            "
          >
            1
            <span
              class="
                absolute
                mt-24
                md:text-xl
                text-base
                left-0
                text-black
                italic
                block
                invisible
                md:visible
              "
              >General
              <br />
              Information</span
            >
          </div>
          <div
            class="
              h-12
              w-12
              rounded-full
              flex
              mr-4
              items-center
              justify-center
              text-white
              cursor-pointer
              z-10
            "
            :class="step >= 1 ? 'bg-blue-500' : classStepper.stepTwo"
            @click="
              if (toggle.stepTwo) {
                step = 1;
                percent = (step / 5) * 100;
              }
            "
          >
            2
            <span
              class="
                absolute
                mt-24
                md:text-xl
                text-base text-center text-black
                italic
                invisible
                md:visible
              "
              >Location</span
            >
          </div>
          <div
            class="
              h-12
              w-12
              rounded-full
              flex
              mr-4
              items-center
              justify-center
              text-white
              cursor-pointer
              z-10
            "
            :class="step >= 2 ? 'bg-blue-500' : classStepper.stepThree"
            @click="
              if (toggle.stepThree) {
                step = 2;
                percent = (step / 5) * 100;
              }
            "
          >
            3
            <span
              class="
                absolute
                mt-24
                text-xl text-center text-black
                italic
                invisible
                md:visible
              "
              >Condition</span
            >
          </div>
          <div
            class="
              h-12
              w-12
              rounded-full
              flex
              mr-4
              items-center
              justify-center
              text-white
              cursor-pointer
              z-10
            "
            :class="step >= 3 ? 'bg-blue-500' : classStepper.stepFour"
            @click="
              if (toggle.stepFour) {
                step = 3;
                percent = (step / 5) * 100;
              }
            "
          >
            4
            <span
              class="
                absolute
                mt-24
                text-xl text-center text-black
                italic
                invisible
                md:visible
              "
              >Application</span
            >
          </div>
          <div
            class="
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              text-white
              cursor-pointer
              z-10
            "
            :class="step >= 4 ? 'bg-blue-500' : classStepper.stepFive"
            @click="
              if (toggle.stepFive) {
                step = 4;
                percent = (step / 5) * 100;
              }
            "
          >
            5
            <span
              class="
                absolute
                mt-24
                text-xl text-center text-black
                italic
                invisible
                md:visible
              "
              >Bibliography</span
            >
          </div>
          <div
            class="
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              text-white
              cursor-pointer
              z-10
            "
            :class="step >= 5 ? 'bg-blue-500' : classStepper.stepSix"
            @click="
              if (toggle.stepSix) {
                step = 5;
                percent = (step / 5) * 100;
              }
            "
          >
            6
            <span
              class="
                absolute
                mt-24
                text-xl
                right-0
                text-black
                italic
                invisible
                md:visible
              "
              >Upload <br />
              Image</span
            >
          </div>

          <div
            class="
              absolute
              overflow-hidden
              h-8
              text-xs
              flex
              rounded
              bg-blue-200
              px-4
              ml-5
            "
            style="width: 97.5%"
          >
            <div
              :style="{ width: percent + '%' }"
              class="
                shadow-none
                flex flex-col
                text-center
                whitespace-nowrap
                text-white
                justify-center
                bg-blue-500
              "
            ></div>
          </div>
        </div>
      </div>
      <div
        class="
          flex flex-row
          justify-between
          underline
          cursor-pointer
          mb-0
          md:mb-6
        "
        @click="
          step = 5;
          percent = (step / 5) * 100;
        "
      >
        <!-- <span class="text-3xl"><font-awesome-icon
              icon="image"
              class=" text-gray-500   mt-1"
            />upload
            </span> -->
      </div>
      <general-information
        ref="general"
        v-if="step == 0"
        :validator="validator"
        @toggleStepTwo="toggleStepper"
      />
      <location
        v-if="step == 1"
        :validator="validator"
        @toggleStepThree="toggleStepper"
      />
      <condition
        v-if="step == 2"
        :validator="validator"
        @toggleStepFour="toggleStepper"
      />
      <application
        v-if="step == 3"
        :validator="validator"
        @toggleStepFive="toggleStepper"
      />
      <bibliography
        v-if="step == 4"
        :validator="validator"
        @toggleStepSix="toggleStepper"
      />
      <images v-if="step == 5" />
      <div
        class="flex w-full my-4"
        :class="step == 0 ? 'justify-end' : 'justify-between'"
      >
        <c-button
          class=""
          v-if="step != 0"
          left-icon="chevron-left"
          variant-color="blue"
          variant="solid"
          @click="prev()"
        >
          Prev
        </c-button>
        <c-button
          class="order-last"
          v-if="step != 5"
          right-icon="chevron-right"
          variant-color="blue"
          variant="solid"
          :disabled="!toggle.next"
          @click="
            if (toggle.next) {
              next();
            }
          "
        >
          Next
        </c-button>
      </div>

      <c-button
        v-if="step == 5"
        size="lg"
        @click="
          if (toggle.saveBtn) {
            submitForm();
          }
        "
        variant-color="blue"
        :disabled="false"
      >
        <c-icon name="save" mr="0.5rem" />Save</c-button
      >
    </c-stack>
  </div>
</template>

<script>
import GeneralInformation from "../components/form/GeneralInformation.vue";
import Location from "../components/form/Location.vue";
import Condition from "../components/form/Condition.vue";
import Application from "../components/form/Application.vue";
import Bibliography from "../components/form/Bibliography.vue";
import Images from "../components/form/ImageForm.vue";
import MicroorganismStore from "../store/MicroorganismStore";
import {
  validateStepTwo,
  validateStepThree,
  validateStepFour,
  validateStepFive,
  validateStepSix,
  validateSaveBtn,
} from "../helpers/Validate";
import Alert from "../helpers/Alert";
import UserStore from "../store/User";

export default {
  data() {
    return {
      step: 0,
      percent: 0,
      cmd: "",
      heading: "New microorganism",
      validator: {},
      toggle: {
        stepOne: true,
        stepTwo: false,
        stepThree: false,
        stepFour: false,
        stepFive: false,
        stepSix: false,
        next: true,
        saveBtn: true,
      },
      classStepper: {
        stepOne: {
          "cursor-pointer": false,
          "cursor-not-allowed": true,
          "bg-blue-200": true,
        },
        stepTwo: {
          "cursor-pointer": false,
          "cursor-not-allowed": true,
          "bg-blue-200": true,
        },
        stepThree: {
          "cursor-pointer": false,
          "cursor-not-allowed": true,
          "bg-blue-200": true,
        },
        stepFour: {
          "cursor-pointer": false,
          "cursor-not-allowed": true,
          "bg-blue-200": true,
        },
        stepFive: {
          "cursor-pointer": false,
          "cursor-not-allowed": true,
          "bg-blue-200": true,
        },
        stepSix: {
          "cursor-pointer": false,
          "cursor-not-allowed": true,
          "bg-blue-200": true,
        },
      },
      isLoading: false,
    };
  },
  components: {
    GeneralInformation,
    Location,
    Condition,
    Application,
    Bibliography,
    Images,
  },
  created() {
    UserStore.dispatch("toggleMenu", 2);
    this.cmd = this.$route.name.split(" ")[0];
    if (this.cmd === "edit") {
      this.heading = "Edit microorganism";
    }
  },
  methods: {
    async submitForm() {
      try {
        this.isLoading = true;
        if (this.cmd === "edit") {
          let res = await MicroorganismStore.dispatch(
            "updateMicroorganism",
            this.$route.params.id
          );
          if (res.status === 200) {
            this.isLoading = false;
            this.$router.push(`/microorganism/${res.id}`);
          }
        } else {
          let res = await MicroorganismStore.dispatch("submitForm");
          if (res.status === 201) {
            this.isLoading = false;
            this.$router.push(`/microorganism/${res.id}`);
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        // await Alert.window('error', 'Cannot insert data', 'Redirect to homepage')
        // MicroorganismStore.dispatch('clearForm');
        // this.$router.push('/')
      }
    },
    next() {
      this.step += 1;
      this.percent = (this.step / 5) * 100;
      this.toggle.next = false;
      window.scrollTo(0, 0);
    },
    prev() {
      MicroorganismStore.dispatch("toggleClearForm", false);
      this.step -= 1;
      this.percent = (this.step / 5) * 100;
      this.toggle.next = true;
      window.scrollTo(0, 0);
    },
    toggleStepper(validationSchema) {
      switch (validationSchema.cmd) {
        case "stepTwo":
          if (validateStepTwo(validationSchema)) {
            this.toggleIfTrue("stepTwo");
          } else {
            this.toggleIfFalse("stepTwo");
          }
          break;
        case "stepThree":
          if (validateStepThree(validationSchema)) {
            this.toggleIfTrue("stepThree");
          } else {
            this.toggleIfFalse("stepThree");
          }
          break;
        case "stepFour":
          if (validateStepFour(validationSchema)) {
            this.toggleIfTrue("stepFour");
          } else {
            this.toggleIfFalse("stepFour");
          }
          break;
        case "stepFive":
          if (validateStepFive(validationSchema)) {
            this.toggleIfTrue("stepFive");
          } else {
            this.toggleIfFalse("stepFive");
          }
          break;
        case "stepSix":
          if (validateStepSix(validationSchema)) {
            this.toggleIfTrue("stepSix");
          } else {
            this.toggleIfFalse("stepSix");
          }
          break;
        case "saveBtn":
          if (validateSaveBtn(validationSchema)) {
            this.toggle.saveBtn = true;
          }
          break;
      }
    },
    toggleIfTrue(step) {
      this.toggle[step] = true;
      this.classStepper[step]["cursor-not-allowed"] = false;
      this.classStepper[step]["cursor-pointer"] = true;
      this.toggle.next = true;
    },
    toggleIfFalse(step) {
      this.toggle[step] = false;
      this.classStepper[step]["cursor-not-allowed"] = true;
      this.classStepper[step]["cursor-pointer"] = false;
      this.toggle.next = false;
    },
    async handleBackToWelcome() {
      const result = await Alert.yesNo(
        "Back to homepage will erase all data in field you are filling"
      );
      if (result) this.$router.push("/welcome");
    },
  },
};
</script>

<style>
.transitionImage {
  transition: 500ms;
  transition-property: all;
  transition-timing-function: ease-in;
}
.transitionImage:hover {
  color: red;
  transform: translateX();
}
</style>
