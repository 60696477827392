<template>
  <c-box mt="2rem">
    <loading v-if="isLoading" />
    <c-text
      fontStyle="italic"
      textDecoration="underline"
      fontSize="2xl"
      mb="1rem"
      >Application Information</c-text
    >
    <c-form-control>
      <div class="flex flex-col space-y-5">
        <div id="agri_application">
          <c-form-label
            >Agriculture Application (ประโยชน์ทางการเกษตร)<span
              class="text-2xl text-red-600"
              >*</span
            ></c-form-label
          >
          <c-select
            id="m-agriculture-apps"
            v-model="application.agricultural_application"
          >
            <option value="" style="display: none">
              Agriculture Application
            </option>
            <option
              v-for="(option, index) in agriculturalApplication"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </c-select>
        </div>
        <div id="agri_description">
          <c-form-label
            >Agriculture Application Description
            (รายละเอียดของประโยชน์ด้านการเกษตร)</c-form-label
          >
          <c-textarea
            id="m-agriculture-apps-detail"
            placeholder="Agriculture Application Description"
            v-model="application.agriculture_application_details"
            v-bind:value="application.agriculture_application_details"
          ></c-textarea>
        </div>
        <div id="industrial_app">
          <c-form-label
            >Industrial Application (ประโยชน์ทางอุตสาหกรรม)<span
              class="text-2xl text-red-600"
              >*</span
            ></c-form-label
          >
          <c-select
            id="m-industrial-apps"
            v-model="application.industrial_application"
          >
            <option value="" style="display: none">
              Industrial Application
            </option>
            <option
              v-for="(option, index) in industrialApplication"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </c-select>
        </div>
        <div id="industrial_description">
          <c-form-label
            >Industrial Application Description
            (รายละเอียดของประโยชน์ด้านอุตสาหกรรม)</c-form-label
          >
          <c-textarea
            id="m-industrial-apps-detail"
            placeholder="Industrial Application Description"
            v-model="application.industrial_application_details"
            v-bind:value="application.industrial_application_details"
          ></c-textarea>
        </div>
        <div id="other_app">
          <c-form-label>Others Applications (ประโยชน์อื่น ๆ)</c-form-label>
          <c-textarea
            id="m-others-app-detail"
            placeholder="Other Applications"
            v-model="application.others_applications"
            v-bind:value="application.others_applications || ''"
          ></c-textarea>
        </div>
      </div>
    </c-form-control>
  </c-box>
</template>

<script>
import MicroorganismStore from "../../store/MicroorganismStore";
import ApplicationStore from "../../store/ApplicationStore";

export default {
  data() {
    return {
      application: {
        agricultural_application: "",
        agriculture_application_details: "",
        industrial_application: "",
        industrial_application_details: "",
        others_applications: "",
      },
      agriculturalApplication: [],
      industrialApplication: [],
      isLoading: true,
    };
  },
  async created() {
    await this.fetchApplication();
    // fetch from API
    let cmd = this.$route.name.split(" ")[0];
    if (cmd === "edit") {
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        for (const key of Object.keys(this.application)) {
          this.application[key] = microorganism[key];
        }
      }
    } else if (cmd === "add") {
      // new object
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        this.application = { ...microorganism };
      }
    }
  },
  watch: {
    application: {
      handler(e) {
        MicroorganismStore.commit("updateInformation", this.application);
      },
      deep: true,
    },
    "application.agricultural_application": function (val, oldVal) {
      this.toggleStepFive();
    },
    "application.industrial_application": function (val, oldVal) {
      this.toggleStepFive();
    },
  },
  methods: {
    toggleStepFive() {
      let value = {
        cmd: "stepFive",
        agricultural_application: this.application.agricultural_application,
        industrial_application: this.application.industrial_application,
      };
      this.$emit("toggleStepFive", value);
    },
    async fetchApplication() {
      this.isLoading = true;
      let dropdownData = await ApplicationStore.dispatch("fetchDropDown");
      if (dropdownData.status === 200) {
        this.agriculturalApplication =
          ApplicationStore.getters.getAgricuturalApplication;
        this.industrialApplication =
          ApplicationStore.getters.getIndustrialApplication;

        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>