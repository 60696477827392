<template>
  <div>
    <loading v-if="isLoading" />
    <div class="flex justify-center items-center pt-8 py-8 my-8  ">
      <div
        class="  w-1/2 h-3/4  border rounded-lg flex-col p-5 sm:p-10 shadow-lg bg-white"
      >
        <div class="w-full text-center text-7xl">
          Register
        </div>
        <div class="grid grid-cols-2 gap-4 h-full pt-8 ">
          <div class="border-r-2 h-full  pr-8 pl-4">
            <c-form-control>
              <c-stack :spacing="4">
                <c-form-label>Username</c-form-label>
                <c-input
                  placeholder="username"
                  :class="errors.username ? 'border-red-500 border-2' : ''"
                  v-model="registerForm.username"
                />
                <ul v-if="errors.username" class="text-red-400">
                  <li v-for="(message, index) in errors.username" :key="index">
                    {{ message }}
                  </li>
                </ul>
                <c-form-label>Email</c-form-label>
                <c-input
                  placeholder="e-mail"
                  type = "email"
                  v-model="registerForm.email"
                  :class="errors.email ? 'border-red-500 border-2' : ''"
                />
                <ul v-if="errors.email" class="text-red-400">
                  <li v-for="(message, index) in errors.email" :key="index">
                    {{ message }}
                  </li>
                </ul>
                <c-form-label>Password</c-form-label>
                <c-input
                  placeholder="Password"
                  v-model="registerForm.password"
                  type="password"
                  :class="errors.password ? 'border-red-500 border-2' : ''"
                />
                <ul v-if="errors.password" class="text-red-400">
                  <li v-for="(message, index) in errors.password" :key="index">
                    {{ message }}
                  </li>
                </ul>
                <c-form-label>Confirm-Password</c-form-label>
                <c-input
                  placeholder="Confirm-Password"
                  type="password"
                  v-model="registerForm.password_confirmation"
                  :class="errors.password ? 'border-red-500 border-2' : ''"
                />
              </c-stack>
            </c-form-control>
          </div>
          <div class=" pr-4">
            <c-form-control>
              <c-stack :spacing="4">
                <c-form-label>Firstname</c-form-label>
                <c-input
                  placeholder="Firstname"
                  v-model="firstname"
                  :class="errors.name ? 'border-red-500 border-2' : ''"
                />
                <ul v-if="errors.name" class="text-red-400">
                  <li v-for="(message, index) in errors.name" :key="index">
                    {{ message }}
                  </li>
                </ul>
                <c-form-label>Lastname</c-form-label>
                <c-input
                  placeholder="Lastname"
                  v-model="lastname"
                  :class="errors.name ? 'border-red-500 border-2' : ''"
                />
                <ul v-if="errors.name" class="text-red-400">
                  <li v-for="(message, index) in errors.name" :key="index">
                    {{ message }}
                  </li>
                </ul>
                <c-form-label>Department</c-form-label>
                <c-input
                  placeholder="Department"
                  v-model="registerForm.organization"
                  :class="errors.organization ? 'border-red-500 border-2' : ''"
                />
                <ul v-if="errors.organization" class="text-red-400">
                  <li
                    v-for="(message, index) in errors.organization"
                    :key="index"
                  >
                    {{ message }}
                  </li>
                </ul>
              </c-stack>
            </c-form-control>
          </div>
          <div class="w-full my-4 mx-auto col-span-2">
            <c-button variant-color="blue" class="w-full" @click="register()"
              >Register</c-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/helpers/Alert";
import UserStore from "@/store/User.js";
export default {
  data() {
    return {
      errors: [],
      firstname: "",
      lastname: "",
      registerForm: {
        email: "",
        username: "",
        password: "",
        password_confirmation: "",
        name: "",
        organization: "",
      },
      isLoading: false,
    };
  },
  created() {
    UserStore.dispatch('toggleMenu', 0)
  },
  methods: {
    async register() {
      try {
        this.isLoading = true;
        this.registerForm.name = this.firstname + " " + this.lastname;
        let payload = new FormData();
        Object.keys(this.registerForm).forEach((key) => {
          payload.append(key, this.registerForm[key]);
        });
        let res = await UserStore.dispatch("register", payload);
        Alert.mixin("success", res.data.message);
        this.$router.push("Login");
      } catch (error) {
        this.errors = error.response.data;
        let isOk = await Alert.window(
          "error",
          "Register Failed",
          "Sorry, an unexpected error occurred. Please try again."
        );
        if (isOk) {
          this.isLoading = false;
        }
        console.error(error.response);
      }
    },
  },
};
</script>

<style></style>
