<template>
  <div class="container mx-auto">
    <c-heading pt="2rem">Choose insert methods</c-heading>
    <c-grid :template-columns="['repeat(1, 1fr)','repeat(1, 1fr)','repeat(2, 1fr)','repeat(2, 1fr)']" gap="6"
     py="2rem" :px="['2rem','3rem','4rem','5rem']"  alignItems="center">
        <c-stack 
        bgColor="white" 
        p="7rem"
        :spacing="10" 
        alignItems="center" 
        border-width="1px" 
        shadow="md"
        class="text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer"
        @click="openUploadCSV"
        >
            <c-icon name="file-csv" size="10rem" />
            <c-text fontSize="3xl">Upload CSV File</c-text>
        </c-stack>
        <router-link to="/form/add-manual">
            <c-stack 
            bgColor="white" 
            p="8rem"
            alignItems="center" 
            border-width="1px" 
            shadow="md"
            class="text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            @click="toggleClearForm">
                <c-icon name="keyboard" size="10rem" />
                <c-text fontSize="3xl">Insert manually</c-text>
            </c-stack>
        </router-link>
        <MicroorganismFormCSV ref="formCSV"/>
    </c-grid>
  </div>
</template>

<script>
import MicroorganismFormCSV from '../components/form/MicroorganismFormCSV.vue'
import MicroorganismStore from '../store/MicroorganismStore'

export default {
    components: {
        MicroorganismFormCSV,
    },
    methods: {
        addDataByCSV() {
            this.$router.push('/form/add-by-csv')
        },
        openUploadCSV() {
            this.$refs.formCSV.open();
        },
        toggleClearForm() {
            MicroorganismStore.dispatch('toggleClearForm', true);
        }
    }
}
</script>

<style>

</style>