<template>
  <div style="flex: 1">
    <loading v-if="isLoading" />
    <c-stack w="100%" my="2rem">
      <div class="flex">

      <c-text fontSize="2xl">Transaction</c-text>
      <c-box mx="2rem" class="flex justify-end w-full ">
        <div class="flex ">
          <c-input
            id="m-location-survey-date"
            type="date"
            placeholder="Survey date"
            v-model="filterDate"
          />
          <c-button
            w="100%"
            variant-color="blue"
            @click="filterLog"
            ><font-awesome-icon icon="search" /></c-button
          >
        </div>
      </c-box>
      </div>
      <ve-table
        id="data"
        :columns="columns"
        :table-data="tableData"
        :paging-index="(pageIndex - 1) * pageSize"
        row-key-field-name="id"
      />
      <div class="text-center">
        <ve-pagination
          v-if="total > 10"
          @on-page-number-change="pageChange"
          @on-page-size-change="pageSizeChange"
          :total="total"
          :page-size="pageSize"
        />
      </div>
    </c-stack>
  </div>
</template>

<script>
import moment from "moment";
import UserStore from "@/store/User";
import Loading from "../misc/Loading.vue";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      columns: [
        {
          field: "created_at",
          key: "c",
          title: "Date",
          align: "center",
          width: 20,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return (
              <span>
                {moment(text).format("L") + " " + moment(text).format("LT") ||
                  "-"}
              </span>
            );
          },
        },
        {
          field: "action",
          key: "a",
          align: "center",
          title: "History",
          width: 60,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span>{text || "-"}</span>;
          },
        },
        {
          field: "status",
          key: "b",
          title: "status",
          align: "center",
          width: 20,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span>{text || "-"}</span>;
          },
        },
      ],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      isLoading: true,
      filterDate: "",
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      try {
        let payload = {
          date: this.filterDate,
          page: this.pageIndex,
          page_size: this.pageSize,
        };
        let res = await UserStore.dispatch("getHistory", payload);
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.error(e);
        if (error.response.status == 401) {
          let isOk = await Alert.window(
            "error",
            "ํYour token has expired",
            "please login again"
          );
          if (isOk) {
            localStorage.removeItem("biodatabase");
            this.$router.push({
              path: "/login",
            });
            window.location.reload(true);
          }
        }
      }
    },
    pageChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getHistory();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getHistory();
    },
    async filterLog(){
        this.isLoading = true;
      try {
        this.pageIndex = 1
        this.pageIndex = 1
        let payload = {
          date: this.filterDate,
          page: this.pageIndex,
          page_size: this.pageSize,
        };
        let res = await UserStore.dispatch("getHistory", payload);
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.isLoading = false;
      } 
      catch(error){
        this.isLoading = false;
        console.error(error)

      }
    }
  },
};
</script>

<style></style>
