<template>
  <div>
      <c-flex 
      @click="selectType" 
      style="cursor: pointer" 
      class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 "
      >
          <c-box class="rounded-lg" :p="['1rem','2rem','2.5rem','3rem']" bgColor="white" border-width="1px" shadow="md" w="100%">
            <c-heading>{{ formatHumanName(type) }}</c-heading>
          </c-box>
      </c-flex>
  </div>
</template>

<script>
export default {
    props: {
        type: String,
    },
    methods: {
        selectType() {
            this.$router.push({ name:"Show data by type",params: {type: this.$props.type.toLowerCase() }})
        },
        formatHumanName(humanName){
        humanName = humanName.toLowerCase();
        const arr = humanName.split(" ");

        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  
        }
        return arr.join(" ");
    },
    }
}
</script>

<style>

</style>