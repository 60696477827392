<template>
  <c-box mt="2rem">
    <c-text
      fontStyle="italic"
      textDecoration="underline"
      fontSize="2xl"
      mb="1rem"
      >Storage Methods & Conditions</c-text
    >
    <c-form-control is-required>
      <div class="flex flex-col space-y-5">
        <div id="ecology">
          <c-form-label>Ecology (ระบบนิเวศ/สภาพถิ่นที่อยู่อาศัย)</c-form-label>
          <c-textarea
            id="m-ecology"
            placeholder="Ecology"
            v-model="condition.ecology"
            v-bind:value="condition.ecology"
          ></c-textarea>
        </div>
        <div id="storage_method">
          <c-form-label>Storage Method (วิธีการเก็บรักษา)</c-form-label>
          <c-textarea
            id="m-storage-method"
            placeholder="Storage Method"
            v-model="condition.storage_method"
            v-bind:value="condition.storage_method"
          ></c-textarea>
        </div>
        <div id="special_characteristic">
          <c-form-label
            >Special Characteristic (ลักษณะเด่นของจุลินทรีย์)</c-form-label
          >
          <c-textarea
            id="m-special-characteristic"
            placeholder="Special Characteristic"
            v-model="condition.special_characteristic"
            v-bind:value="condition.special_characteristic"
          ></c-textarea>
        </div>
        <div id="culture_media">
          <c-form-label>Culture Media (อาหารเลี้ยงเชื้อ)</c-form-label>
          <c-textarea
            id="m-culture-media"
            placeholder="Culture Media"
            v-model="condition.culture_media"
            v-bind:value="condition.culture_media"
          ></c-textarea>
        </div>
        <div id="temperature_for_growth">
          <c-form-label
            >Temperature for Growth (อุณหภูมิเพื่อการเจริญ)</c-form-label
          >
          <c-input
            id="m-temperature-for-growth"
            placeholder="Temperature for Growth"
            v-model="condition.temperature_for_growth"
          />
        </div>
        <div id="oxygen_demand">
          <c-form-label>Oxygen Demand (ความต้องการอากาศ)</c-form-label>
          <c-select id="m-oxygen-demand" v-model="condition.oxygen_demand">
            <option value="" style="display: none">Oxygen Demand</option>
            <option value="aerobe">aerobe</option>
            <option value="anaerobe">anaerobe</option>
            <option value="facultative anaerobe">facultative anaerobe</option>
            <option value="microaerobe">microaerobe</option>
          </c-select>
        </div>
        <div id="storage_condition">
          <c-form-label
            >Storage Condition (สภาวะในการเก็บรักษาเชื้อ)</c-form-label
          >
          <c-input
            id="m-storage-condition"
            placeholder="Storage Condition"
            v-model="condition.storage_condition"
          />
        </div>
        <div id="growth_condition">
          <c-form-label>Growth Condition (สภาวะสำหรับการเจริญ)</c-form-label>
          <c-input
            id="m-growth-condition"
            placeholder="Growth Condition/สภาวะสำหรับการเจริญ"
            v-model="condition.growth_condition"
          />
        </div>
        <div id="ph_for_media">
          <c-form-label
            >pH for Culture Media
            (ความเป็นกรดด่างของอาหารเลี้ยงเชื้อ)</c-form-label
          >
          <c-input
            id="m-pH"
            placeholder="pH for Culture Media"
            v-model="condition.ph_for_culture_media"
          />
        </div>
      </div>
    </c-form-control>
  </c-box>
</template>

<script>
import MicroorganismStore from "../../store/MicroorganismStore";
export default {
  data() {
    return {
      condition: {
        ecology: "",
        storage_method: "",
        special_characteristic: "",
        culture_media: "",
        temperature_for_growth: "",
        oxygen_demand: "",
        storage_condition: "",
        growth_condition: "",
        ph_for_culture_media: "",
      },
    };
  },
  created() {
    // fetch from API
    let cmd = this.$route.name.split(" ")[0];
    if (cmd === "edit") {
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        for (const key of Object.keys(this.condition)) {
          this.condition[key] = microorganism[key];
        }
      }
    } else if (cmd === "add") {
      // new object
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        this.condition = { ...microorganism };
      }
    }
  },
  watch: {
    condition: {
      handler(e) {
        MicroorganismStore.commit("updateInformation", this.condition);
      },
      deep: true,
    },
    "condition.ecology": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.storage_method": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.special_characteristic": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.culture_media": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.temperature_for_growth": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.oxygen_demand": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.storage_condition": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.growth_condition": function (val, oldVal) {
      this.toggleStepFour();
    },
    "condition.ph_for_culture_media": function (val, oldVal) {
      this.toggleStepFour();
    },
  },
  methods: {
    toggleStepFour() {
      let value = {
        cmd: "stepFour",
        ecology: this.condition.ecology,
        storage_method: this.condition.storage_method,
        special_characteristic: this.condition.special_characteristic,
        culture_media: this.condition.culture_media,
        temperature_for_growth: this.condition.temperature_for_growth,
        oxygen_demand: this.condition.oxygen_demand,
        storage_condition: this.condition.storage_condition,
        growth_condition: this.condition.growth_condition,
        ph_for_culture_media: this.condition.ph_for_culture_media,
      };
      this.$emit("toggleStepFour", value);
    },
  },
};
</script>

<style>
</style>