<template>
<div style="flex: 1">
  <loading v-if="isLoading" />
  <div v-if="!isLoading">
    <c-stack w="100%" my="2rem">
      <c-flex alignItems="center">
        <c-box mx="1rem">
          <div class="img-thumbnail img-circle">
          <div style="position: relative; padding: 0;" type="file">
            <img :src="url" class="img-circle" style="width: 140px; height: 140px;" >
            <img v-if="!toggleEditInfo" :src="url || `../../assets/profile.png`" class="img-circle" style="width: 140px; height: 140px;" >
            <span class="upload-label" v-if="toggleEditInfo" style="position: absolute;">
              UPLOAD
              <input type="file" accept="image/*" 
              class="hidden-upload" 
              @change="fileChanges($event)"/>
            </span>
          </div>
          </div>
        </c-box>
        <c-text fontSize="2xl">{{user.name}}</c-text>
      </c-flex>
      <c-divider width="95%" class="mx-auto" />
      <c-simple-grid :columns="2" w="50%" mx="4rem" :spacing="5">
        <c-box>
          <c-text>Username:</c-text>
        </c-box>
        <c-box>
          <c-text>{{user.username}}</c-text>
        </c-box>

        <c-box>
          <c-text>Name:</c-text>
        </c-box>
        <c-box v-if="!toggleEditInfo">
          <c-text>{{formatHumanName(user.researcher.name)}}</c-text>
        </c-box>
        <c-box v-if="toggleEditInfo">
          <c-input placeholder="Name" v-model="form.name"/>
        </c-box>

        <c-box>
          <c-text>E-mail:</c-text>
        </c-box>
        <c-box>
          <c-text>{{user.email}}</c-text>
        </c-box>

        <c-box>
          <c-text>Phone:</c-text>
        </c-box>
        <c-box v-if="!toggleEditInfo">
          <c-text>{{user.researcher.phone_number || "-"}}</c-text>
        </c-box>
        <c-box v-if="toggleEditInfo">
          <c-input placeholder="Phone" v-model="form.phone_number"/>
        </c-box>

        <c-box>
          <c-text>Organization:</c-text>
        </c-box>
        <c-box v-if="!toggleEditInfo">
          <c-text>{{ formatHumanName(user.researcher.organization.name) || "-"}}</c-text>
        </c-box>
        <c-box v-if="toggleEditInfo">
          <c-select v-model="form.organization">
            <option value="" style="display: none">Organization</option>
            <option
              v-for="(option, index) in organizations"
              :key="index"
              :value="option"
              >{{ option }}</option
            >
          </c-select>
        </c-box>

        <c-box>
          <c-text>Position:</c-text>
        </c-box>
        <c-box v-if="!toggleEditInfo">
          <c-text>{{ user.researcher.rank || "-" }}</c-text>
        </c-box>
        <c-box v-if="toggleEditInfo">
          <c-input placeholder="Position" v-model="form.rank"/>
        </c-box>
      </c-simple-grid>
      <c-box textAlign="center" v-if="!toggleEditInfo">
          <c-button w="50%" class="mx-auto" mt="2rem" left-icon="edit" variant-color="blue" @click="handleEditProfile">Edit Info</c-button>
      </c-box>
      <c-stack v-if="toggleEditInfo">
        <c-stack is-inline justify="center">
          <c-box textAlign="center" w="25%">
            <c-button w="100%" class="mx-auto" mt="2rem" variant-color="blue" @click="handleUpdateProfile"
              >Save</c-button
            >
          </c-box>
          <c-box textAlign="center" w="25%">
          <c-button w="100%" class="mx-auto" mt="2rem" variant-color="red" @click="handleCancel"
            >Cancel</c-button
          >
          </c-box>
        </c-stack>
      </c-stack>
    </c-stack>
  </div>
</div>
</template>

<script>
import UserStore from "@/store/User.js";
import Alert from '../../helpers/Alert';
import axios from 'axios';


export default {
  data() {
    return {
      user: "",
      isLoading: false,
      toggleEditInfo: false,
      form: {
        name: "",
        organization: "",
        phone_number: "",
        rank: "",
      },
      files: [],
      url: "",
      organizations: [],
    };
  },
  created(){
      this.getMe();
      this.getDropDownData();

  },
  methods: {
    async getMe() {
      try {
        this.isLoading = true
        let res = await UserStore.dispatch("getMe");
        if (res.status === 200) {
          this.user = res.data;
          this.form.name = this.user.researcher.name;
          this.form.phone_number = this.user.researcher.phone_number;
          this.form.organization = this.user.researcher.organization.name;
          this.form.rank = this.user.researcher.rank;
          this.url = process.env.VUE_APP_APIURL + ":" + process.env.VUE_APP_PORT + this.user.profile;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        if (error.response.status == 401) {
          let isOk = await Alert.window("error", "Your token has expired", "please login again");
          if (isOk) {
            localStorage.removeItem("biodatabase")
            this.$router.push({
              path: "/login",
            })
            window.location.reload(true);
          }
        }
      }
    },
    handleEditProfile() {
      this.toggleEditInfo = true;
    },
    handleCancel() {
      this.toggleEditInfo = false;
      this.form =  {
        name: this.user.researcher.name,
        organization: this.user.researcher.organization.name,
        phone_number: this.user.researcher.phone_number,
        rank: this.user.researcher.rank,
      }
      URL.revokeObjectURL(this.url);
      this.url = process.env.VUE_APP_APIURL + ":" + process.env.VUE_APP_PORT + this.user.profile;
      this.files = [];
    },
    fileChanges(event) {
      this.files = event.target.files[0];
      this.url = URL.createObjectURL(this.files);
    },
    async handleUpdateProfile() {
      try {
        if(this.form.name === ""){
          Alert.window("warning" , "Unable to update profile." , "Name field is required.");
        }
        let data = new FormData();
        data.append('profile', this.files);
        data.append('name', this.form.name);
        data.append('organization', this.form.organization);
        data.append('phone_number', this.form.phone_number);
        data.append('rank', this.form.rank);

        const res = await UserStore.dispatch('updateProfile', data);
        if (res.status === 201) {
          const result = await Alert.window('success', 'Update Profile', 'Profile updated successfully');
          if (result) {
            this.toggleEditInfo = false;
            location.reload();
          }
        }else{
        }
      } catch (error) {
        this.isLoading = false; 
        if (error.response.status == 400) {
          await Alert.window("error", "Profile failed to update", "Please check if name or organization is provided")
        }
        else if (error.response.status == 401) {
          let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
          if (isOk) {
            localStorage.removeItem("biodatabase")
            this.$router.push({
              path: "/login",
            })
            window.location.reload(true);
          }
        }
      }
    },
    async getDropDownData(){
      let res = await axios.get("/dropdown");
      this.organizations = res.data.organization;
    },
    formatHumanName(humanName){
        humanName = humanName.toLowerCase();
        const arr = humanName.split(" ");

        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  
        }
        return arr.join(" ");
    },
  },
};
</script>

<style>
.img-circle {
  border-radius: 50%;
}

.upload-label {
 left:0;
 text-align:center;
 width:100%;
 background:rgba(255,255,255,0.5);
 bottom:0;
 padding:15px 0;
 color: rgb(0, 0, 0);
}

.upload-label:hover {
 background:rgba(37, 37, 37, 0.5);
 color: white;
}

.img-thumbnail {
  border-radius: 0;
  display:inline-block;
  padding:1rem;
}

.img-thumbnail > div {
  display:inline-block;
  overflow: hidden;
  height: 140px;
  border: 5px solid #fff;
  border-radius:50%;
}

.hidden-upload {
  opacity: 0.0; 
  position: absolute; 
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0; 
  width: 100%; 
  height:100%;
}

</style>