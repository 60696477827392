<template>
  <c-box my="2rem">
    <loading v-if="isLoading" />
    <c-text
      fontStyle="italic"
      textDecoration="underline"
      fontSize="2xl"
      mb="1rem"
      >Bibliography</c-text
    >
    <c-form-control>
      <div class="flex flex-col space-y-5">
        <div id="proj_number">
          <c-form-label
            >Project Number (โครงการวิจัยย่อยที่)<span
              class="text-2xl text-red-600"
              >*</span
            ></c-form-label
          >
          <c-input
            id="m-project-number"
            placeholder="Project Number"
            v-model="bibliography.project_number"
            aria-describedby="keyword-helper-text"
          />
        </div>
        <div id="keywords">
          <c-form-label>Keywords (คำสำคัญ)</c-form-label>
          <c-form-helper-text id="keyword-helper-text"
            >Seperate each keyword(s) by "," (comma)</c-form-helper-text
          >
          <c-input
            id="m-keywords"
            placeholder="Keyword"
            v-model="bibliography.keyword"
            aria-describedby="keyword-helper-text"
          />
        </div>
        <div id="organization">
          <c-form-label
            >Organization (หน่วยงานที่ค้นพบ)<span class="text-2xl text-red-600"
              >*</span
            ></c-form-label
          >
          <c-select id="m-organization" v-model="bibliography.organization_by">
            <option value="" style="display: none">Organization</option>
            <option
              v-for="(option, index) in organizations"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </c-select>
        </div>
        <div id="published_docs">
          <c-form-label>Published Document (ชื่อเอกสารที่ตีพิมพ์)</c-form-label>
          <c-textarea
            id="m-published-docs"
            placeholder="Published Document"
            v-model="bibliography.document_published"
            v-bind:value="bibliography.document_published"
          ></c-textarea>
        </div>
        <div id="docs_url">
          <c-form-label>Document URL (ลิงก์เอกสาร)</c-form-label>
          <c-textarea
            id="m-docs-url"
            placeholder="Document URL"
            v-model="bibliography.document_url"
            v-bind:value="bibliography.document_url"
          ></c-textarea>
        </div>
      </div>
    </c-form-control>
  </c-box>
</template>

<script>
import MicroorganismStore from "../../store/MicroorganismStore";
import OrganizationStore from "../../store/OrganizationStore";

export default {
  data() {
    return {
      bibliography: {
        project_number: "",
        keyword: "",
        organization_by: "",
        document_published: "",
        document_url: "",
      },
      organizations: [],
      isLoading: false,
    };
  },
  async created() {
    await this.fetchOrganization();
    let cmd = this.$route.name.split(" ")[0];
    if (cmd === "edit") {
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        for (const key of Object.keys(this.bibliography)) {
          if (key == "organization_by") {
            this.bibliography["organization_by"] =
              microorganism["organization"].name;
            continue;
          }
          this.bibliography[key] = microorganism[key];
        }
      }
    } else if (cmd === "add") {
      // new object
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        this.bibliography = { ...microorganism };
      }
    }
  },
  watch: {
    bibliography: {
      handler(e) {
        MicroorganismStore.commit("updateInformation", this.bibliography);
      },
      deep: true,
    },
    "bibliography.organization_by": function (val, oldVal) {
      this.toggleStepSix();
    },
    "bibliography.project_number": function (val, oldVal) {
      this.toggleStepSix();
    },
  },
  methods: {
    async fetchOrganization() {
      try {
        this.isLoading = true;
        let res = await OrganizationStore.dispatch("fetchOrganizations");
        if (res.status === 200) {
          this.organizations = OrganizationStore.getters.getOrganizations;
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response.status == 401) {
          let isOk = await Alert.window(
            "error",
            "ํYour token has expired",
            "please login again"
          );
          if (isOk) {
            localStorage.removeItem("biodatabase");
            this.$router.push({
              path: "/login",
            });
            window.location.reload(true);
          }
        }
      }
    },
    toggleStepSix() {
      let value = {
        cmd: "stepSix",
        organization_by: this.bibliography.organization_by,
        project_number: this.bibliography.project_number,
      };
      this.$emit("toggleStepSix", value);
    },
  },
};
</script>

<style>
</style>