<template>
  <footer class="footer bg-black relative py-8">
    <div class="container mx-auto px-6">
      <div class="sm:flex sm:mt-8">
        <div
          class="
            mt-8
            sm:mt-0
            sm:w-full
            sm:px-8
            flex flex-col
            md:flex-row
            justify-between
          "
        >
          <div class="flex=1 flex-col">
            <img src="../assets/2-removebg.png" style="width: 65%;" alt="" />
            <c-flex py="1rem" flexDirection="column" px="0.3rem">
              <c-text color="white">Department of Microbiology<br /></c-text>
              <c-text color="white">Faculty of Science<br /></c-text>
              <c-text color="white"
                >Biodiversity Center Kasetsart University (BDCKU),
                <br />Faculty of Science, Kasetsart University, 50 Ngam Wong Wan Road,
                <br />Chatuchak, Bangkok 10900, Thailand</c-text
              >
              <c-text color="white">Tel. 02 562 5555<br /></c-text>
            </c-flex>
          </div>
          <div class="flex-1 flex-col">
            <c-box class="border-b" mt="0.5rem">
              <c-text color="white">SHORTCUT</c-text>
            </c-box>
            <c-flex flexDirection="column" pt="1.5rem" class="text-white">
              <router-link to="/" >Home</router-link>
              <router-link to="/database">Database</router-link>
              <router-link to="#">Contact us</router-link>
            </c-flex>
          </div>
          <div class="flex-1 flex-col">
            <c-flex flexDirection="column">
              <c-text color="white" pt="21vh"
                >Copyright &reg; {{ new Date().getFullYear() }} KU BIORESOURCES
                ONLINE DATABASE</c-text
              >
            </c-flex>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { CText, CBox, CFlex } from "@chakra-ui/vue";
export default {
  components: {
    CText,
    CBox,
    CFlex,
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toDatabase() {
      this.$router.push("/database");
    },
  },
};
</script>

<style></style>
