<template>
  <div>
    <c-flex flexDirection="column">
        <c-box m="2rem" mb="0">
            <c-box>
                
                <c-text fontSize="3xl" fontStyle="italic">
                    <SciNameComponent :sci_name="microorganismName || ''"/>
                </c-text>
            </c-box>
            <c-box>
                <c-text>Microorganism Type: <span class="text-gray-500"> {{ formatHumanName(microorganismType) || "-" }}</span></c-text>
            </c-box>
            <c-box>
                <c-text>Source: <span class="text-gray-500">{{ source || "-" }} </span></c-text>
            </c-box>
            <c-box>
                <c-text>คำสำคัญ: <span class="text-gray-500">{{ keyword }}</span></c-text>
            </c-box>
            <c-box>
                <c-text>Recorder: <span class="text-gray-500">{{ recorder || "-" }}</span></c-text>
            </c-box>
        </c-box>
    </c-flex>
  </div>
</template>

<script>
import SciNameComponent from '../../components/SciNameComponent.vue';
export default {
  props: {
      microorganismName: String,
      microorganismType: String,
      source: String,
      keyword: String,
      recorder: String
  },
  components: {
    SciNameComponent
  },
  methods:{
    formatHumanName(humanName){
            humanName = humanName.toLowerCase();
            const arr = humanName.split(" ");

            //loop through each element of the array and capitalize the first letter.


            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

            }
            return arr.join(" ");
    },
  }
};
</script>

<style>
</style>