<template>
  <div class="container mx-auto">
      <c-heading my="2rem">Account Settings</c-heading>
      <c-flex class="flex flex-col md:flex-row" bgColor="white" my="2rem">
          <sidebar />
          <password-setting/>
      </c-flex>
  </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue'
import PasswordSetting from '../../components/users/PasswordSetting.vue'

export default {
    components: {
        Sidebar,
        PasswordSetting
    }
}
</script>

<style>

</style>