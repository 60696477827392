<template>
  <div>
    <sci-name-component style="display: none;"/>
    <ve-table
      id="data"
      :columns="columns"
      :table-data="tableData"
      :paging-index="(pageIndex - 1) * pageSize"
      :expand-option="expandOption"
      row-key-field-name="id"
      :cell-style-option="cellStyleOption"
      :row-style-option="rowStyleOption"
      :event-custom-option="eventCustomOption"
    />
    <div v-show="showEmpty" class="empty-data bg-white">{{ handleInstructionText() }}</div>
    <div class="text-center mt-2 p-2 rounded-md">
      <ve-pagination
        @on-page-number-change="pageChange"
        @on-page-size-change="pageSizeChange"
        :total="total"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MicroorganismStore from "../store/MicroorganismStore";
import UserStore from "../store/User";
import AuthHelper from "../helpers/Header";
import SciNameComponent from './SciNameComponent.vue';

export default {
  props: {
    type: String,
  },
  components: {
    SciNameComponent
  },
  data() {
    return {
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (column.title === "Isolate code") {
            return "table-header-cell-class";
          }
          if (column.title === "Scientific Name") {
            return "table-header-cell-class";
          }
          if (column.title === "Microorganism Type") {
            return "table-header-cell-class";
          }
          if (column.title === "Agriculture Application") {
            return "table-header-cell-class";
          }
          if (column.title === "Industrial Application") {
            return "table-header-cell-class";
          }
          if (column.title === "") {
            return "table-header-cell-class";
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (rowIndex % 2 === 0) {
            return "table-body-cell-class";
          }
        },
      },
      rowStyleOption: {
        stripe: true,
        hoverHighLight: true,
      },
      eventCustomOption: {
        // body row event custom
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            click: (event) => {
              let authen = UserStore.getters.isAuthen;
              if (authen) {
				let routeData = this.$router.resolve({
                  name: "Microorganism Information",
                  params: { id: row.id },
                });
				window.open(routeData.href, '_blank');
              } else {
				let routeData = this.$router.resolve({
                  name: "Microorganism guest",
                  params: { id: row.id },
                });
				window.open(routeData.href, '_blank');
              }
            },
            dblclick: (event) => {},
            contextmenu: (event) => {},
            mouseenter: (event) => {},
            mouseleave: (event) => {},
          };
        },
      },
      expandOption: {
        render: ({ row, column, rowIndex }, h) => {
          let authen = UserStore.getters.isAuthen;
          if (authen) {
            let routeData = this.$router.resolve({
              path: "microorganism/" + rowIndex.id,
            });
            window.open(routeData.href, "_blank");
            // this.$router.push({
            //   path: "microorganism/" + row.id,
            // });
          } else {
            this.$router.push({
              path: "guest/microorganism/" + row.id,
            });
          }
        },
      },
      loadingInstance: null,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      columns: [
        {
          field: "",
          key: "g",
          title: "",
          width: 50,
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return ++rowIndex + (this.pageIndex - 1) * this.pageSize;
          },
        },
        {
          field: "isolate_code",
          key: "h",
          title: "Isolate code",
          width: 200,
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span>{text || "-"}</span>;
          },
        },
        {
          field: "scientific_name",
          key: "a",
          title: "Scientific Name",
          align: "center",
          
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <div><SciNameComponent sci_name={text}/></div>;
          },
        },
        {
          field: "microorganism_type",
          key: "b",
          title: "Microorganism Type",
          align: "center",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            const str2 = text.charAt(0).toUpperCase() + text.slice(1);
              return <span>{str2 || "-"}</span>;
          },
        },
        {
          field: "agricultural_application",
          key: "d",
          title: "Agriculture Application",
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span >{text || "-"}</span>;
          },
        },
        {
          field: "industrial_application",
          key: "e",
          title: "Industrial Application",
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span>{text || "-"}</span>;
          },
        },
        {
          field: "",
          key: "f",
          type: "expand",
          title: "",
          width: 100,
          align: "center",
        },
      ],
      data: {},
      tableData: [],
      sourceData: ["bacteria", "microalgae", "yeast", "fungi"],
      isLoading: true,
      instructionText: "Type scientific name or  to search",
      toggleInstructionText: false,
      searchStatus: false,
      searchName: "",
      searchTab: 0,
      searchBody: {},
      showEmpty: true,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    
    async fetchData() {
      // this.loadingInstance = this.$veLoading({
      //   target: document.querySelector("data"),
      //   name: "wave",
      // });
      // this.show();
      const AC = UserStore.getters.isAuthen ? "AUTHORIZED" : "GUEST";
      this.isLoading = true;
      let res;

      if (AC === "AUTHORIZED") {
        switch (this.$route.params.type) {
          case "actinobacteria":
            res = await this.fetchMicroorganismByType(this.$route.params.type);
            break;
          case "bacteria":
            res = await this.fetchMicroorganismByType(this.$route.params.type);
            break;
          case "fungi":
            res = await this.fetchMicroorganismByType(this.$route.params.type);
            break;
          case "yeast":
            res = await this.fetchMicroorganismByType(this.$route.params.type);
            break;
          case "microalgae":
            res = await this.fetchMicroorganismByType(this.$route.params.type);
            break;
          default:
            res = await axios.get("search/basic");
            break;
        }
        this.tableData = res.data.data;
        this.total = res.data.total;
        if (this.tableData.length == 0) {
          this.showEmpty = true;
        } else {
          this.showEmpty = false;
        }
        this.isLoading = false;
      } else {
        this.getTableData();

        this.toggleInstructionText = true;
        this.isLoading = false;
      }
      // this.close();
    },

    async getTableData() {
      if (this.$route.params.type) {
        let res = await this.fetchMicroorganismByType(this.$route.params.type);
        this.tableData = res.data.data;
        this.isLoading = false;
      } else {
        let res = await axios.get(`search/basic`, {
          params: {
            page: this.pageIndex,
            full_scientific_name: this.searchName,
            page_size: this.pageSize,
          },
        });
        this.tableData = res.data.data;
        this.total = res.data.total;
        if (this.tableData.length == 0) {
          this.showEmpty = true;
        } else {
          this.showEmpty = false;
        }
        this.isLoading = false;
      }
    },
    async getSeachedTableData() {
      let res = await axios.get(`search/basic`, {
        params: {
          page: this.pageIndex,
          full_scientific_name: this.searchName,
          page_size: this.pageSize,
        },
      });
      this.tableData = res.data.data;
      if (this.tableData.length == 0) {
        this.showEmpty = true;
      } else {
        this.showEmpty = false;
      }
    },
    async getAdvancedSearchTableData(search) {
      let res = await axios.get(`search/advanced`, {
        params: {
          page: this.pageIndex,
          page_size: this.pageSize,
          ...search.body,
        },
      });
      this.tableData = res.data.data;
      if (this.tableData.length == 0) {
          this.showEmpty = true;
        } else {
          this.showEmpty = false;
      }
    },
    pageChange(pageIndex) {
      this.pageIndex = pageIndex;
      if (this.searchName && this.searchTab == 0) {
        this.getSeachedTableData();
      } else if (
        Object.keys(this.searchBody).length !== 0 &&
        this.searchTab == 1
      ) {
        this.getAdvancedSearchTableData(this.searchBody);
      } else {
        this.getTableData();
      }
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      if (this.searchTab == 0) {
        this.getSeachedTableData();
      } else if (this.searchTab == 1) {
        this.getAdvancedSearchTableData(this.searchBody);
      } else {
        this.getTableData();
      }
    },
    show() {
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchByMicroorganismTypeField() {
      let listOfObjects = Object.keys(this.data).map((key) => {
        return this.data[key];
      });
      this.tableData = listOfObjects.filter((x) => {
        if (x.microorganism_type === null) {
          return false;
        }
        if (this.$route.params.type.includes(x.microorganism_type.name)) {
          return true;
        }
      });
    },
    async search(search) {
      switch (search.activeTab) {
        case 0:
          try {
            this.isLoading = true;
            let res = await MicroorganismStore.dispatch(
              "basicSearch",
              search.body
            );
            if (res.status === 200) {
              this.data = MicroorganismStore.getters.getFetchedMicroorganism;
              this.searchName = search.body.full_scientific_name;
              this.pageIndex = 1;
              this.pageSize = 10;
              this.total = this.data.total;
              this.getSeachedTableData();
              this.handleInstructionText();
              this.isLoading = false;
              this.searchTab = search.activeTab;
            }
          } catch (error) {
            this.isLoading = false;
            if (error.response.status == 401) {
              let isOk = await Alert.window(
                "error",
                "Your token has expired",
                "please login again"
              );
              if (isOk) {
                localStorage.removeItem("biodatabase");
                this.$router.push({
                  path: "/login",
                });
                window.location.reload(true);
              }
            }
          }
          break;
        case 1:
          try {
            this.isLoading = true;
            let res = await MicroorganismStore.dispatch(
              "advanceSearch",
              search.body
            );
            if (res.status === 200) {
              this.data = MicroorganismStore.getters.getFetchedMicroorganism;
              this.pageIndex = 1;
              this.pageSize = 10;
              this.total = this.data.total;
              this.getAdvancedSearchTableData(search);
              this.handleInstructionText();
              this.isLoading = false;
              this.searchTab = search.activeTab;
              this.searchBody = search;
            }
          } catch (error) {
            this.isLoading = false;
            if (error.response.status == 401) {
              let isOk = await Alert.window(
                "error",
                "ํYour token has expired",
                "please login again"
              );
              if (isOk) {
                localStorage.removeItem("biodatabase");
                this.$router.push({
                  path: "/login",
                });
                window.location.reload(true);
              }
            }
          }
          break;
      }
    },
    handleInstructionText() {
       return "ไม่พบข้อมูลที่ค้นหา (Data not found)";
    },
    async fetchMicroorganismByType(type) {
      try {
        let header = AuthHelper.getHeaders({
          Accept: "application/json",
        });
        let res = await axios.get(
          `search/basic?page=${this.pageIndex}&page_size=${this.pageSize}&type=${type}`,
          header
        );
        return res;
      } catch (error) {
        this.isLoading = false;
        if (error.response.status == 401) {
          let isOk = await Alert.window(
            "error",
            "ํYour token has expired",
            "please login again"
          );
          if (isOk) {
            localStorage.removeItem("biodatabase");
            this.$router.push({
              path: "/login",
            });
            window.location.reload(true);
          }
        }
        throw error;
      }
    },
  },
};
</script>

<style>
.table-header-cell-class {
  background: #3290dd !important;
  color: #fff !important;
}
.table-body-cell-class {
  @apply p-4 !important;
}
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
</style>
