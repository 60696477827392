<template>
  <div class="md:w-1/5">
      <c-flex
      left="5"
      h="100%"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      w="100%"
      flexDirection="column"
      justify="">
        <c-flex
        p="5%"
        flexDirection="column"
        alignItems="flex-start"
        >
        <c-box w="100%">
            <router-link to="/account">
                <NavItem menu="Account Information" icon="user"/>
            </router-link>
        </c-box>
        <c-box w="100%">
            <router-link to="/mymicroorganism">
                <NavItem menu="My Microorganism" icon="book"/>
            </router-link>
        </c-box>
        <c-box w="100%">
            <router-link to="/account/history">
                <NavItem menu="Transaction" icon="history"/>
            </router-link>
        </c-box>
        <c-box w="100%">
            <router-link to="/account/changepassword">
                <NavItem menu="Change Password" icon="key"/>
            </router-link>
        </c-box>
        </c-flex>
      </c-flex>
  </div>
</template>

<script>
import NavItem from "./NavItem.vue";
export default {
    components: {
        NavItem,
    }
}
</script>

<style>
</style>