import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      researchers: [],
  },
  getters: {
      getResearchers: (state) => state.researchers,
  },
  mutations: {
      setResearchers(state, data) {
          state.researchers = data;
      }
  },
  actions: {
      async fetchResearchers({ commit }) {
          try {
              let res = await axios.get(`/dropdown`);
              if (res.status === 200) {
                  commit('setResearchers', res.data.researcher)
                  return {
                      status: 200,
                  }
              }
          } catch (error) {
              throw error;
          }
      }
  },
  modules: {
  }
})
