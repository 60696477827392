import { render, staticRenderFns } from "./Location.vue?vue&type=template&id=4db309e5&"
import script from "./Location.vue?vue&type=script&lang=js&"
export * from "./Location.vue?vue&type=script&lang=js&"
import style0 from "./Location.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFormLabel: require('@chakra-ui/vue').CFormLabel, CTextarea: require('@chakra-ui/vue').CTextarea, CSelect: require('@chakra-ui/vue').CSelect, CBox: require('@chakra-ui/vue').CBox, CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CInput: require('@chakra-ui/vue').CInput, CTagLabel: require('@chakra-ui/vue').CTagLabel, CTagCloseButton: require('@chakra-ui/vue').CTagCloseButton, CTag: require('@chakra-ui/vue').CTag, CFormControl: require('@chakra-ui/vue').CFormControl})
