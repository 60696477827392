<template>
  <div>
    <loading v-if="isLoading" />
    <div class="flex">
      <c-box my=".5rem" class="flex justify-end w-full ">
        <div class="flex ">
          <c-input-group>
            <c-input-left-element>
              <c-icon name="search" color="gray.300"/>
            </c-input-left-element>
           <c-input type="search" placeholder="Isolate code" v-model="searchKey"/>
          </c-input-group>
          <c-button
            variant-color="blue"
            @click="searchMyData"
            ><font-awesome-icon icon="search" /></c-button
          >
          <c-select v-model="sortBy" class="w-auto mx-4" @change=searchMyData>
            <option value="" style="display: none">
              sort by
            </option>
            <option
              v-for="(option, index) in sortOptions"
              :key="index"
              :value="option"
              :selected="option === sortBy"
            >
              {{ option }}
            </option>
          </c-select>
        </div>
      </c-box>
    </div>
    <sci-name-component :sci_name="' '"/>
    <ve-table
      id="data"
      :columns="columns"
      :table-data="tableData"
      :paging-index="(pageIndex - 1) * pageSize"
      :cell-style-option="cellStyleOption"
      :expand-option="expandOption"
      row-key-field-name="id"
      :event-custom-option="eventCustomOption"
    />
    <div class="text-center">
      <c-heading my="3rem" v-if="toggleInstructionText">{{
        instructionText
      }}</c-heading>
      <ve-pagination
        @on-page-number-change="pageChange"
        @on-page-size-change="pageSizeChange"
        :total="total"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MicroorganismStore from "@/store/MicroorganismStore";
import HeaderHelper from "@/helpers/Header";
import Alert from "@/helpers/Alert";

import UserStore from "@/store/User";
import SciNameComponent from '../../components/SciNameComponent.vue';

export default {
  props: {
    type: String,
  },
  components: {
    SciNameComponent
  },
  data() {
    return {
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (column.title === "Isolate code") {
            return "table-header-cell-class";
          }
          if (column.title === "Scientific Name") {
            return "table-header-cell-class";
          }
          if (column.title === "Microorganism Type") {
            return "table-header-cell-class";
          }
          if (column.title === "Agriculture Application") {
            return "table-header-cell-class";
          }
          if (column.title === "Industrial Application") {
            return "table-header-cell-class";
          }
          if (column.title === "") {
            return "table-header-cell-class";
          }
        },
      },
      expandOption: {
        render: ({ row, column, rowIndex }, h) => {
          let authen = UserStore.getters.isAuthen;
          if (authen) {
            let routeData = this.$router.resolve({
              name: "Microorganism Information",
              params: { id: row.id },
            });
            window.open(routeData.href, "_blank");
          } else {
            let routeData = this.$router.resolve({
              name: "Microorganism guest",
              params: { id: row.id },
            });
            window.open(routeData.href, "_blank");
          }
        },
      },
      eventCustomOption: {
        // body row event custom
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            click: (event) => {
              let authen = UserStore.getters.isAuthen;
              if (authen) {
                let routeData = this.$router.resolve({
                  name: "Microorganism Information",
                  params: { id: row.id },
                });
                window.open(routeData.href, "_blank");
              } else {
                let routeData = this.$router.resolve({
                  name: "Microorganism guest",
                  params: { id: row.id },
                });
                window.open(routeData.href, "_blank");
              }
            },
            dblclick: (event) => {},
            contextmenu: (event) => {},
            mouseenter: (event) => {},
            mouseleave: (event) => {},
          };
        },
      },
      loadingInstance: null,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      columns: [
        {
          field: "",
          key: "g",
          title: "",
          width: 50,
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return ++rowIndex + (this.pageIndex - 1) * this.pageSize;
          },
        },
        {
          field: "isolate_code",
          key: "h",
          title: "Isolate code",
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span>{text || "-"}</span>;
          },
        },
        {
          field: "scientific_name",
          key: "a",
          title: "Scientific Name",
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <div><SciNameComponent sci_name={text}/></div>;
            // return <span>{text || "-"}</span>;
          },
        },
        {
          field: "microorganism_type",
          key: "b",
          title: "Microorganism Type",
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            const str2 = text.charAt(0).toUpperCase() + text.slice(1);
            return <span>{str2 || "-"}</span>;},
        },
        {
          field: "agricultural_application",
          key: "d",
          title: "Agriculture Application",
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span>{text || "-"}</span>;
          },
        },
        {
          field: "industrial_application",
          key: "e",
          title: "Industrial Application",
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field];
            return <span>{text || "-"}</span>;
          },
        },
        {
          field: "",
          key: "f",
          type: "expand",
          title: "",
          width: 100,
          align: "center",
        },
      ],
      data: {},
      tableData: [],
      sourceData: ["bacteria", "microalgae", "yeast", "fungi"],
      isLoading: true,
      instructionText: "Type scientific name or isolate code to search",
      toggleInstructionText: false,
      searchStatus: false,
      searchName: "",
      searchKey: "",
      sortIcon: "sort-up",
      sortBy: 'Newest first',
      sortOptions: [
        'Newest first',
        'Oldest first'
      ]
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {},
  methods: {
    async fetchData() {
      const AC = UserStore.getters.isAuthen ? "AUTHORIZED" : "GUEST";
      this.isLoading = true;

      if (AC === "AUTHORIZED") {
        this.getTableData();
      } else {
        this.toggleInstructionText = true;
        this.isLoading = false;
      }
    },

    async getTableData() {
      this.isLoading = true;
      // let header = HeaderHelper.getHeaders({Accept: 'application/json'});
      const auth_key = process.env.VUE_APP_AUTH_KEY;
      let auth = JSON.parse(localStorage.getItem(auth_key));
      let orderBy = 'asc'
      if(this.sortBy == 'Oldest first'){
        orderBy = 'desc'
      }
      try {
        let res = await axios.get(`auth/my/microorganisms`, {
          params: {
            page: this.pageIndex,
            page_size: this.pageSize,
            order_by: orderBy
          },
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth.access_token}`,
          },
        });
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response.status == 401) {
          let isOk = await Alert.window(
            "error",
            "Your token has expired",
            "please login again"
          );
          if (isOk) {
            localStorage.removeItem("biodatabase");
            this.$router.push({
              path: "/login",
            });
            window.location.reload(true);
          }
        }
      }
    },
    pageChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getTableData();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getTableData();
    },
    show() {
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    handleInstructionText() {
      if (this.data.length === 0) {
        this.instructionText = "Data not found";
        this.toggleInstructionText = true;
      } else {
        this.toggleInstructionText = false;
      }
    },
    
    async searchMyData() {
      this.isLoading = true;
      const auth_key = process.env.VUE_APP_AUTH_KEY;
      let auth = JSON.parse(localStorage.getItem(auth_key));
      let orderBy = 'asc'
      if(this.sortBy == 'Oldest first'){
        orderBy = 'desc'
      }
      try {
        let res = await axios.get(`auth/my/microorganisms`, {
          params: {
            page: this.pageIndex,
            page_size: this.pageSize,
            search: this.searchKey,
            order_field: 'id',
            order_by: orderBy
          },
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth.access_token}`,
          },
        });
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response.status == 401) {
          let isOk = await Alert.window(
            "error",
            "Your token has expired",
            "please login again"
          );
          if (isOk) {
            localStorage.removeItem("biodatabase");
            this.$router.push({
              path: "/login",
            });
            window.location.reload(true);
          }
        }
      }
    },
  },
};
</script>

<style>
.ve-table-header-th {
  /* font-size: 20px !important; */
  @apply text-xl !important
  font-bold !important;
}
.ve-table-body-td {
  @apply text-xl !important;
}
.table-header-cell-class {
  background: #3290dd !important;
  color: #fff !important;
}
</style>
