<template>
  <div>
    <loading v-if="isLoading" />
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      is-centered
    >
      <c-modal-content ref="content">
        <c-modal-header>CSV File</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <c-heading>Upload CSV file</c-heading>
          <c-form-control>
            <div>
            <c-form-label 
              class="text-blue-600 underline my-2 cursor-pointer"
              @click="openTemplateLink">CSV Template</c-form-label>
            </div>
            <c-form-label>Upload file</c-form-label>
            <c-input type="file" accept=".csv,.txt" placeholder="CSV file" @change="fileChanges($event)"/>
          </c-form-control>
        </c-modal-body>
        <c-modal-footer>
          <c-button variant-color="blue" mr="3" @click="upload">
            Upload
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import axios from 'axios'
import HeaderHelper from '../../helpers/Header'
import Alert from '../../helpers/Alert'
export default {
  data () {
    return {
      isOpen: false,
      files: [],
      isLoading: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
    fileChanges(event) {
        this.files = event.target.files[0];
    },
    async upload() {
      try {
        this.isLoading = true;
        let data = new FormData()
        let header = HeaderHelper.getHeaders({
          Accept: 'application/json',
          'content-type': 'multipart/form-data'
        })
        data.append('upload_file', this.files)
        let res = await axios.post('/upload/csv', data, header);
        if (res.status === 200) {
          this.isLoading = false
          this.$router.push('/database')
          await Alert.window('success', 'Upload CSV complete', 'Your data have been added to Bioresources Database.');
        }
        this.isLoading = false;
        this.close()
      } catch (error) {
        this.isLoading = false;
        await Alert.window(
          "error",
          "Upload CSV fail",
          "please check CSV format and try again."
        );
        
      }   
    },
    openTemplateLink(){
      window.open("https://docs.google.com/spreadsheets/d/1kDwAQY577hWQyPkcjrhk77CGHIXzAxTM/edit?usp=sharing&ouid=113314874249940251026&rtpof=true&sd=true")
    }
  }
}
</script>