<template>
  <div class="container mx-auto">
      <c-heading my="2rem">Account Settings</c-heading>
      <c-flex class="flex flex-col md:flex-row" bgColor="white" my="2rem">
          <sidebar />
          <history class="" />
      </c-flex>
  </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue'
import History from '../../components/users/History.vue'

export default {
    components: {
        Sidebar,
        History
    }
}
</script>

<style>

</style>