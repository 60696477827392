<template>
  <div>
    <loading v-if="isLoading" />
    <c-box mt="2rem">
      <c-text fontStyle="italic" textDecoration="underline" fontSize="2xl"
        >Location</c-text
      >
      <c-form-control>
        <div class="flex flex-col space-y-5">
          <div id="location_found">
            <c-form-label
              >Location that microorganism is found in Thailand
              (สถานที่ค้นพบในประเทศไทย)<span class="text-2xl text-red-600"
                >*</span
              ></c-form-label
            >
            <!-- <c-form-helper-text id="keyword-helper-text"
                  >In case, there are more than one, seperate each location by","
                  (comma)</c-form-helper-text
                > -->
            <c-textarea
              id="m-location-source"
              placeholder="Location that microorganism is found in Thailand"
              v-model="location.source"
              v-bind:value="location.source"
            ></c-textarea>
          </div>
          <div id="location_remark">
            <c-form-label
              >Location Remark (รายละเอียดแหล่งที่ค้นพบ)<span
                class="text-2xl text-red-600"
                >*</span
              ></c-form-label
            >
            <c-textarea
              id="m-location-remark"
              placeholder="Location Remark"
              v-model="location.location_remarks"
              v-bind:value="location.location_remarks"
            ></c-textarea>
          </div>
          <div id="description">
            <c-form-label
              >Description (รายละเอียดเพิ่มเติมของการค้นพบ)</c-form-label
            >
            <c-textarea
              id="m-location-description"
              placeholder="Description"
              v-model="location.found_description"
              v-bind:value="location.found_description"
            ></c-textarea>
          </div>
          <div id="map">
            <c-flex pb="1.25rem" alignItems="center">
              <c-box
                w="100%"
                mr="1rem"
                v-if="formData.province_id !== undefined"
              >
                <c-form-label
                  >Province (จังหวัด)<span class="text-2xl text-red-600"
                    >*</span
                  ></c-form-label
                >
                <c-select
                  id="m-location-province"
                  v-model="formData.province_id"
                >
                  <option value="" style="display: none">Province</option>
                  <option
                    v-for="(option, index) in provinceAPI.province"
                    :key="index"
                    :value="option.PROVINCE_ID"
                    :selected="option.id === formData.province_id"
                  >
                    {{ option.PROVINCE_NAME }}
                  </option>
                </c-select>
              </c-box>
              <c-box
                w="100%"
                mr="1rem"
                v-if="formData.district_id !== undefined"
              >
                <c-form-label
                  >District (เขต/อำเภอ)<span class="text-2xl text-red-600"
                    >*</span
                  ></c-form-label
                >
                <c-select
                  id="m-location-district"
                  v-model="formData.district_id"
                >
                  <option value="" style="display: none">District</option>
                  <option
                    v-for="(option, index) in provinceAPI.district"
                    :key="index"
                    :value="option.DISTRICT_ID"
                    :selected="option.id === formData.district_id"
                  >
                    {{ option.DISTRICT_NAME }}
                  </option>
                </c-select>
              </c-box>
              <c-box w="100%" v-if="formData.sub_district_id !== undefined">
                <c-form-label
                  >Sub district (ตำบล)<span class="text-2xl text-red-600"
                    >*</span
                  ></c-form-label
                >
                <c-select
                  id="m-location-sub-district"
                  v-model="formData.sub_district_id"
                >
                  <option value="" style="display: none">Sub district</option>
                  <option
                    v-for="(option, index) in provinceAPI.sub_district"
                    :key="index"
                    :value="option.SUB_DISTRICT_ID"
                    :selected="option.id === formData.sub_district_id"
                  >
                    {{ option.SUB_DISTRICT_NAME.replace("*", "") }}
                  </option>
                </c-select>
              </c-box>
              <c-box w="100%" class="text-right">
                <c-button
                  size="lg"
                  @click="handleSelectLocation()"
                  variant-color="blue"
                  :disabled="formData.toggleSaveLocationBtn"
                >
                  <c-icon name="save" mr="0.5rem" />Save</c-button
                >
              </c-box>
            </c-flex>
            <div>
              <table v-if="isAllLocationsDelete()" class="table-auto w-full">
                <thead>
                  <th>Province</th>
                  <th>District</th>
                  <th>Sub district</th>
                </thead>
                <tbody>
                  <tr v-for="(location, index) in savedLocations" :key="index">
                    <td v-if="!location.is_delete" class="text-center">
                      {{ location.province }}
                    </td>
                    <td v-if="!location.is_delete" class="text-center">
                      {{ location.district }}
                    </td>
                    <td v-if="!location.is_delete" class="text-center">
                      {{ location.sub_district }}
                    </td>
                    <td v-if="!location.is_delete" class="text-center">
                      <font-awesome-icon
                        icon="times"
                        class="
                          text-4xl text-gray-400
                          hover:text-black hover:cursor-pointer
                        "
                        @click="removeLocation(index)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <GmapMap
              :center="coordinates"
              :zoom="7"
              class="h-screen w-full"
              ref="mapRef"
              @dragend="handleDrag"
            >
              <div v-if="savedLocations.length > 0">
                <div v-for="(m, index) in savedLocations" :key="index">
                  <GmapMarker
                    v-if="!m.is_delete"
                    :position="m"
                    :clickable="true"
                    @click="center = m"
                  />
                </div>
              </div>
              <!-- <GmapMarker
              :position="{ lat: location.marker.lat, lng: location.marker.lng }"
              :draggable="true"
              @drag="updateCoordinates"
            /> -->
            </GmapMap>
            <!-- <h4 class="mt-2">พิกัดที่พบ</h4>
          <p class="my-2">เลื่อน marker ไปยังตำแหน่งที่ค้นพบจุลินทรีย์</p>
          <p ref="test">
            {{ location.marker.lat }} Latitude ,
            {{ location.marker.lng }} Longitude
          </p> -->
          </div>
          <div id="surveyer_name">
            <c-form-label
              >Surveyer name (ชื่อผู้สำรวจ)<span class="text-2xl text-red-600"
                >*</span
              ></c-form-label
            >
            <c-select
              id="m-location-surveyer-name"
              v-model="location.survey_by"
            >
              <option value="" style="display: none">Surveyer name</option>
              <option
                v-for="(option, index) in researchers"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </c-select>
            <!-- <c-box v-if="$props.validator.hasOwnProperty('surveyer')">
            <c-input
              placeholder="ชื่อผู้สำรวจ"
              v-model="location.surveyer"
              class="border-2 border-red-500"
            />
            <c-form-helper-text textColor="red.300">กรุณากรอกชื่อผู้สำรวจ</c-form-helper-text>
            </c-box> -->
          </div>
          <div id="survey_date">
            <c-form-label>Survey date (วันที่สำรวจ)</c-form-label>
            <c-input
              id="m-location-survey-date"
              type="date"
              placeholder="Survey date"
              v-model="location.survey_date"
            />
          </div>
          <div id="sampling_by">
            <c-form-label
              >Sampling by (ชื่อผู้เก็บตัวอย่าง)
              <span class="text-2xl text-red-600">*</span></c-form-label
            >
            <c-select
              id="m-location-sampling-by"
              v-model="location.sampling_by"
            >
              <option value="" style="display: none">Sampling by</option>
              <option
                v-for="(option, index) in researchers"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </c-select>
          </div>
          <div id="sampling_date">
            <c-form-label>Sampling date (วันที่เก็บตัวอย่าง)</c-form-label>
            <c-input
              id="m-location-sampling-date"
              type="date"
              placeholder="Sampling date"
              v-model="location.sampling_date"
            />
          </div>
          <div id="isolate_by">
            <c-form-label
              >Isolation by (ชื่อผู้แยกเชื้อ)<span class="text-2xl text-red-600"
                >*</span
              ></c-form-label
            >
            <c-select
              id="m-location-isolation-by"
              v-model="location.isolation_by"
            >
              <option value="" style="display: none">Isolation by</option>
              <option
                v-for="(option, index) in researchers"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </c-select>
          </div>
          <div id="isolation_date">
            <c-form-label>Isolation date (วันที่แยกเชื้อ)</c-form-label>
            <c-input
              id="m-location-isolation-date"
              type="date"
              placeholder="Isolation date"
              v-model="location.isolation_date"
            />
          </div>
          <div id="identification_by">
            <c-form-label
              >Identification by (ผู้ระบุชนิด)<span
                class="text-2xl text-red-600"
                >*</span
              ></c-form-label
            >
            <c-select
              id="m-location-identification-by"
              v-model="location.identification_by"
            >
              <option value="" style="display: none">Identification by</option>
              <option
                v-for="(option, index) in researchers"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </c-select>
          </div>
          <div id="found_status">
            <c-form-label
              >Found status (สถานะการค้นพบ)<span class="text-2xl text-red-600"
                >*</span
              ></c-form-label
            >
            <c-select
              id="m-location-found-status"
              v-model="location.found_status"
            >
              <option value="" style="display: none">Found status</option>
              <option
                v-for="(option, index) in foundStatus"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </c-select>
            <!-- <c-input v-if="location.found_status === `อื่น ๆ`" placeholder="กรอกสถานะการค้นพบ" v-model="location.found_status_other"/> -->
          </div>
          <div id="found_by">
            <c-form-label
              >Found by (ผู้ตั้งชื่อ/ผู้ค้นพบ)
              <span class="text-2xl text-red-600">*</span></c-form-label
            >
            <div
              v-if="location.found_by.length > 0"
              class="flex flex-wrap gap-3 p-4"
            >
              <c-tag
                v-for="researcher in location.found_by"
                size="md"
                :key="researcher"
                variant="solid"
              >
                <c-tag-label>{{ researcher }}</c-tag-label>
                <c-tag-close-button @click="closeBtn(researcher)" />
              </c-tag>
            </div>
            <div class="flex w-1/2">
              <autocomplete
                ref="autocomplete"
                placeholder="Founder"
                :search="searchResearcher"
                @submit="onSubmit"
                :get-result-value="getResultValue"
                auto-select
                style="flex-grow: 1"
              >
              </autocomplete>
              <button
                @click="clear()"
                class="bg-white px-4"
                style="border: 1px solid #eee; flex-grow: 0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </button>
            </div>
          </div>
          <!-- <c-select id="m-location-found-by" v-model="location.found_by">
              <option value="" style="display: none"
                >Found by</option
              >
              <option
                v-for="(option, index) in researchers"
                :key="index"
                :value="option.name"
                >{{ option.name }}</option
              >
            </c-select> -->
          <div id="found_date">
            <c-form-label>Found date (เดือน/ปีที่ค้นพบ) (mm/yyyy)</c-form-label>
            <c-input
              id="m-location-found-date"
              type="month"
              placeholder="Found date (mm/yyyy)"
              v-model="location.found_date"
            />
          </div>
          <div id="contact_person">
            <c-form-label
              >Contact person (ผู้ติดต่อ)<span class="text-2xl text-red-600"
                >*</span
              ></c-form-label
            >
            <c-select
              id="m-location-contact-person"
              v-model="location.contact_person"
            >
              <option value="" style="display: none">Contact person</option>
              <option
                v-for="(option, index) in researchers"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </c-select>
          </div>
          <div id="culture_collec_asses_code">
            <c-form-label>Culture Collection Assession Code</c-form-label>
            <c-input
              id="m-location-culture-collection-assession-code"
              placeholder="Culture Collection Assession Code"
              v-model="location.culture_collection_assession_code"
            />
          </div>
        </div>
      </c-form-control>
    </c-box>
  </div>
</template>

<script>
import MicroorganismStore from "../../store/MicroorganismStore";
import LocationStore from "../../store/LocationStore";
import ResearcherStore from "../../store/ResearcherStore";
import ApplicationStore from "../../store/ApplicationStore";
import axios from "axios";

const Comp = {
  template: "<div>Hello world</div>",
};

export default {
  props: ["validator"],
  data() {
    return {
      location: {
        source: "",
        location_remarks: "",
        found_description: "",
        survey_by: "",
        survey_date: "",
        sampling_by: "",
        sampling_date: "",
        isolation_by: "",
        isolation_date: "",
        identification_by: "",
        found_by: [],
        found_status: "",
        found_date: "",
        contact_person: "",
        culture_collection_assession_code: "",
        marker: {
          lat: 13.7589,
          lng: 100.5109,
        },
        gps: [],
      },
      map: null,
      savedLocations: [],
      savedLocationsTemp: [],
      foundStatus: [],

      formData: {
        province_id: "",
        district_id: "",
        sub_district_id: "",
        toggleSaveLocationBtn: true,
      },

      coordinates: {
        lat: 13.7589,
        lng: 100.5109,
      },
      provinceAPI: {
        province: [],
        district: [],
        sub_district: [],
      },
      isLoading: false,
      found_status_other: "",
      researchers: [],
      selected_researchers: [],

      components: [Comp],
    };
  },
  async created() {
    await this.fetchProvince();
    await this.fetchResearchers();
    this.foundStatus = ApplicationStore.getters.getFoundStatus;
    console.log("this is found status : " +this.foundStatus);

    // fetch from API
    let cmd = this.$route.name.split(" ")[0];
    if (cmd === "edit") {
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        for (const key of Object.keys(this.location)) {
          if (
            key == "survey_by" &&
            typeof microorganism["survey_by"] == "object"
          ) {
            this.location["survey_by"] = microorganism["survey_by"].name;
            continue;
          }
          if (
            key == "sampling_by" &&
            typeof microorganism["sampling_by"] == "object"
          ) {
            this.location["sampling_by"] = microorganism["sampling_by"].name;
            continue;
          }
          if (
            key == "isolation_by" &&
            typeof microorganism["isolation_by"] == "object"
          ) {
            this.location["isolation_by"] = microorganism["isolation_by"].name;
            continue;
          }
          if (
            key == "identification_by" &&
            typeof microorganism["identification_by"] == "object"
          ) {
            this.location["identification_by"] =
              microorganism["identification_by"].name;
            continue;
          }
          if (
            key == "contact_person" &&
            typeof microorganism["contact_person"] == "object"
          ) {
            this.location["contact_person"] =
              microorganism["contact_person"].name;
            continue;
          }
          if (key == "found_by") {
            microorganism.founders.forEach((element) => {
              this.location["found_by"].push(element.name);
            });
            continue;
          }
          this.location[key] = microorganism[key];
        }

        const { survey_date, sampling_date, isolation_date, found_date } =
          microorganism;
        if (this.location.survey_date) {
          this.location.survey_date = this.convertDate(survey_date);
        }
        if (this.location.sampling_date) {
          this.location.sampling_date = this.convertDate(sampling_date);
        }
        if (this.location.isolation_date) {
          this.location.isolation_date = this.convertDate(isolation_date);
        }
        if (this.location.found_date) {
          this.location.found_date = this.convertDate(found_date).substr(0, 7);
        }
        this.location.gps = microorganism.gps;
        this.savedLocations = microorganism.gps.map((item) => {
          return {
            id: item.id,
            lat: item.latitude,
            lng: item.longitude,
            province: item.province,
            district: item.district,
            sub_district: item.sub_district,
            is_new: item.is_new,
            is_delete: item.is_delete,
          };
        });
      }
    } else if (cmd === "add") {
      // new object
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        this.location = { ...microorganism };
        if (this.location.survey_date) {
          this.location.survey_date = new Date().toISOString().substr(0, 19);
        }
        if (this.location.sampling_date) {
          this.location.sampling_date = new Date().toISOString().substr(0, 19);
        }
        if (this.location.isolation_date) {
          this.location.isolation_date = new Date().toISOString().substr(0, 19);
        }
        if (this.location.found_date) {
          this.location.found_date = new Date().toISOString().substr(0, 7);
        }
      }
    }
  },
  watch: {
    location: {
      handler(e) {
        if (this.location.found_status === "อื่น ๆ") {
          this.location.found_status = this.location.found_status_other;
        }
        MicroorganismStore.commit("updateInformation", this.location);
      },
      deep: true,
    },
    "formData.province_id": async function (val, oldVal) {
      await this.fetchDistrict();
    },
    "formData.district_id": async function (val, oldVal) {
      await this.fetchSubDistrict();
    },
    "formData.sub_district_id": async function (val, oldVal) {
      this.formData.toggleSaveLocationBtn = false;
    },
    "location.source": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.location_remarks": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.survey_by": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.survey_date": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.sampling_by": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.sampling_date": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.isolation_by": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.isolation_date": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.identification_by": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.found_by": function (val, oldVal) {
      this.toggleStepThree();
    },
    "location.found_status": function (val, oldVal) {
      this.toggleStepThree();
    },
  },
  mounted() {
    //add the map to a data object
    if (this.$refs.mapRef) {
      this.$refs.mapRef.$mapPromise.then((map) => (this.map = map));
    }
  },
  computed: {
    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 2,
          lng: 0,
        };
      }
      return {
        lat: this.map.getCenter().lat().toFixed(4),
        lng: this.map.getCenter().lng().toFixed(4),
      };
    },
  },
  methods: {
    convertDate(date) {
      return date.substr(0, 10).replace(" ", "T");
    },
    async fetchProvince() {
      this.isLoading = true;
      let res = await LocationStore.dispatch("fetchProvince");
      if (res.status === 200) {
        this.provinceAPI.province = LocationStore.getters.getProvince;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    async fetchDistrict() {
      this.formData.district_id = "";
      this.isLoading = true;
      let res = await LocationStore.dispatch(
        "fetchDistrict",
        this.formData.province_id
      );
      if (res.status === 200) {
        this.provinceAPI.district = LocationStore.getters.getDistrict;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    async fetchSubDistrict() {
      this.formData.sub_district_id = "";
      this.isLoading = true;
      let res = await LocationStore.dispatch(
        "fetchSubDistrict",
        this.formData.district_id
      );
      if (res.status === 200) {
        this.provinceAPI.sub_district = LocationStore.getters.getSubDistrict;
        // this.formData.sub_district_id = this.provinceAPI.sub_district[0].name;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    async fetchResearchers() {
      try {
        this.isLoading = true;
        let res = await ResearcherStore.dispatch("fetchResearchers");
        if (res.status === 200) {
          this.researchers = ResearcherStore.getters.getResearchers;
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response.status == 401) {
          let isOk = await Alert.window(
            "error",
            "ํYour token has expired",
            "please login again"
          );
          if (isOk) {
            localStorage.removeItem("biodatabase");
            this.$router.push({
              path: "/login",
            });
            window.location.reload(true);
          }
        }
      }
    },
    updateCoordinates(location) {
      this.location.marker = {
        lat: parseFloat(location.latLng.lat().toFixed(4)),
        lng: parseFloat(location.latLng.lng().toFixed(4)),
      };
    },
    handleDrag() {
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };

      let zoom = this.map.getZoom();

      localStorage.center = JSON.stringify(center);

      localStorage.zoom = zoom;
    },
    toggleStepThree() {
      let value = {
        cmd: "stepThree",
        source: this.location.source,
        location_remarks: this.location.location_remarks,
        found_by: this.location.found_by,
        identification_by: this.location.identification_by,
        isolation_by: this.location.isolation_by,
        // isolation_date: this.location.isolation_date,
        sampling_by: this.location.sampling_by,
        // sampling_date: this.location.sampling_date,
        survey_by: this.location.survey_by,
        // survey_date: this.location.survey_date,
        found_status: this.location.found_status,
        gps: this.savedLocations,
      };
      this.$emit("toggleStepThree", value);
    },
    async handleSelectLocation() {
      let province = this.provinceAPI.province.find((element) => {
        return element.PROVINCE_ID == this.formData.province_id;
      });
      let district = this.provinceAPI.district.find(
        (element) => element.DISTRICT_ID == this.formData.district_id
      );
      let sub_district = this.provinceAPI.sub_district.find(
        (element) => element.SUB_DISTRICT_ID == this.formData.sub_district_id
      );
      let route = `https://maps.googleapis.com/maps/api/geocode/json?address=${province.PROVINCE_NAME}+${district.DISTRICT_NAME}+${sub_district.SUB_DISTRICT_NAME}&key=AIzaSyBkFopyMXAf4_PjKa2r7aaaPP_MabHW3eg`;
      let res = await axios.get(route);
      if (res.status !== "ZERO_RESULTS") {
        this.savedLocations.push({
          lat: res.data.results[0].geometry.location.lat,
          lng: res.data.results[0].geometry.location.lng,
          province: province.PROVINCE_NAME,
          district: district.DISTRICT_NAME,
          sub_district: sub_district.SUB_DISTRICT_NAME,
          is_new: true,
          is_delete: false,
        });
      }
      this.updateLocationPayload();

      this.formData.province_id = "";
      this.formData.district_id = "";
      this.formData.sub_district_id = "";
      this.formData.toggleSaveLocationBtn = true;

      this.toggleStepThree();
    },
    removeLocation(index) {
      this.savedLocations[index]["is_delete"] = true;
      MicroorganismStore.dispatch("isDelete", {
        index: index,
        is_delete: true,
      });
      this.updateLocationPayload();
    },
    updateLocationPayload() {
      // save to payload
      this.location.gps = this.savedLocations.map((item) => {
        return {
          id: item.id,
          lat: item.lat,
          lng: item.lng,
          province: item.province,
          district: item.district,
          sub_district: item.sub_district,
          is_new: item.is_new,
          is_delete: item.is_delete,
        };
      });
    },
    isAllLocationsDelete() {
      return this.savedLocations.some((item) => !item.is_delete);
    },
    searchResearcher(input) {
      // this.researchers.slice(Math.max(this.researchers.length - 5, 1))
      if (input.length < 1) {
        return [];
      }
      var resultList = this.researchers.filter((a) => {
        return a.toLowerCase().startsWith(input.toLowerCase());
      });
      return resultList;
    },
    getResultValue(result) {
      return result;
    },
    onSubmit(result) {
      if (result) {
        if (!this.location.found_by.includes(result)) {
          this.location.found_by.push(result);
        }
        this.clear();
        return;
      }
      if (this.$refs.autocomplete.value) {
        if (!this.location.found_by.includes(this.$refs.autocomplete.value)) {
          this.location.found_by.push(this.$refs.autocomplete.value);
        }
        this.clear();
      }
    },
    closeBtn(param1) {
      var findIndex = this.location.found_by.indexOf(param1);
      if (findIndex !== -1) {
        this.location.found_by.splice(findIndex, 1);
      }
    },
    clear() {
      // this.value = ''
      this.$refs.autocomplete.value = "";
    },
  },
};
</script>

<style>
.autocomplete-input {
  font-family: "Mitr", sans-serif;

  @apply w-full
    bg-none !important
    px-4 !important
    bg-white !important
    border-2 !important
    border-gray-100 !important
    rounded-md;
}
.autocomplete-result-list {
  font-family: "Mitr", sans-serif;

  @apply bg-white
    w-full
    rounded-md
    z-10;
}
.autocomplete-result {
  @apply bg-none !important
    p-4
    hover:bg-gray-200;
}
</style>
