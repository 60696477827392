<template>
  <div class="w-full">
    <div class="w-1/2 mx-auto px-5 mb-4 font-bold text-3xl">
      <c-text>{{ microorganismName }}</c-text>
    </div>

    <div class="text-center">
      <c-text>Upload microorganism image, multiple images are available</c-text>
    </div>

    <div class="flex">
      <div class="w-full h-full px-10 py-5 flex justify-center item-center">
        <!-- upload images -->

        <input
          type="file"
          ref="image"
          style="display: none"
          @change="addImage"
          multiple
        />

        <div
          v-if="!images.length"
          class="w-1/2 h-80 border-gray-500  bg-gray-200 border-2 relative"
          @mouseover="hoverImage = true"
          @mouseleave="hoverImage = false"
          @click="$refs.image.click()"
          @drop.prevent="addImage($event)"
        >
          <div
            class="absolute text-7xl text-gray-500 w-full h-full flex justify-center items-center"
          >
            <font-awesome-icon icon="camera" />
          </div>
          <div
            v-if="hoverImage"
            class="absolute w-full h-full flex justify-center items-center bg-black opacity-80 text-white text-xl"
          >
            Upload Image
          </div>
          <!-- <div class="absolute bottom-0   text-red-500 text-center">
            <p v-if="!errors.images" class="">
              Maximum: 5 images
            </p>
          </div> -->
        </div>
        <!-- sroll wrap -->
        <div
          class="h-80 px-4 overflow-y-auto overflow-hidden "
          v-if="images.length"
        >
          <!-- grid container -->
          <div class="grid grid-cols-3 w-full gap-4 ">
            <!-- uploaded images -->
            <div v-for="(image, index) in images" :key="index">
              <div class="w-48 h-32 bg-cover bg-center rounded-lg border-gray-500 border-2  relative "
                  @click="clickImg(image)"
                  @mouseover="onHover(index)"
                  @mouseleave="outHover(index)">
                <img
                  class="w-full h-full relative rounded-lg"
                  :src="getImagePath(image.path)"
                >
                <div
                  v-if="image.hover"
                  class="absolute w-full h-full bg-black opacity-80 rounded-lg top-0"
                >
                  <div
                    class="relative top-0 left-36 ml-3 w-8 h-8 bg-red-700 rounded-lg hover:bg-red-500 cursor-pointer"
                    @click="removeImage(index)"
                    v-on:click.stop="clickImg"
                  >
                    <font-awesome-icon
                      icon="trash"
                      class=" text-white absolute ml-0.5 left-2 top-2 "
                    />
                  </div>
                  <!-- <div class="relative left-16 ">
                    <font-awesome-icon
                      icon="search-plus"
                      class="absolute text-5xl text-gray-500 left-2 top-2"
                    />
                  </div> -->
                </div>

              </div>
              <!-- <div
                v-if="image.show"
                class="mx-auto w-1/2 left-0 right-0  bottom-0 absolute bg-black z-10 flex items-center cursor-pointer"
                @click="clickImg(image)"
              >
                <img
                  class="mx-auto cursor-pointer max-w-full max-h-full"
                  :src="getImagePath(image.path)"
                  alt="preview img"
                />
              </div> -->
            </div>

            <!-- add more images -->
            <div
              v-if="this.images.length < 5"
              class="w-48 h-32 rounded-lg border-gray-500 border-2 border-dashed relative"
              @click="$refs.image.click()"
              @drop.prevent="addImage($event)"
              @mouseover="hoverImage = true"
              @mouseleave="hoverImage = false"
            >
              <div class="relative top-1/2 left-4">
                <div class="absolute w-12 h-1.5 bg-gray-500  left-14"></div>
              </div>
              <div
                class="absolute w-1.5 h-12 bg-gray-500 top-10 left-1/2"
              ></div>

              <!-- <font-awesome-icon icon="plus" class="absolute text-7xl text-white top-6 left-16"/> -->
              <div
                v-if="hoverImage"
                class="absolute w-full h-full flex justify-center items-center bg-black opacity-80 text-white text-xl"
              >
                Upload Image
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MicroorganismStore from "../../store/MicroorganismStore";

export default {
  data() {
    return {
      hoverImage: false,

      user: {},
      maxItemsSelected: false,
      errors: [],

      images: [],
      categories: ["A", "B", "C"],
      form: {
        images: [],
      },
      microorganismName: "",
    };
  },
  created() {
    let cmd = this.$route.name.split(" ")[0];
    if (cmd === "edit") {
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        this.microorganismName = microorganism["full_scientific_name"];
        if (
          microorganism.hasOwnProperty("images") &&
          microorganism.images.length > 0
        ) {
          for (let i = 0; i < microorganism.images.length; i++) {
            let data = {
              id: microorganism.images[i].id,
              path:  microorganism.images[i].path,
              show: false,
              hover: false,
              is_delete: false,
              is_new : true
            };
            this.images.push(data);
            this.form.images.push(data);
          }

        }
      }
    } else if (cmd === "add") {
      // new object
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        this.microorganismName = microorganism["full_scientific_name"];
        // this.images = microorganism['images']
        // this.insertKeyDisplayImg()
      }
    }
  },
  watch: {
    form: {
      handler(e) {
        MicroorganismStore.commit("updateInformation", this.form);
      },
      deep: true,
    },
  },
  methods: {
    getImagePath(image) {
      let imageFirstString = image.charAt(0);
      if(imageFirstString === '/'){
        return process.env.VUE_APP_APIURL +":"+process.env.VUE_APP_PORT+ image;

      }
      return image
    },
    clickImg(image) {
      image.show = !image.show;
    },
    onHover(index) {
      this.images[index]["hover"] = true;
    },
    outHover(index) {
      this.images[index]["hover"] = false;
    },
    addImage(e) {
      let files = null;
      if (e.type === "drop") {
        files = e.dataTransfer.files;
      } else if (e.type === "change") {
        files = e.target.files;
      }

      if (files) {
        let isExceed = false;
        for (let i = 0; i < files.length; i++) {
          if (this.images.length === 5) {
            isExceed = true;
            break;
          }
          this.form.images.push({
            file: files[i],
            is_new: true,
            is_delete: false
             });
          let data = {
            path: URL.createObjectURL(files[i]),
            show: false,
            hover: false,
            is_delete: false,
            is_new : true
          };
          this.images.push(data);
        }

        if (isExceed)
          Alert.window(
            "error",
            "You adding more than 5 Images",
            "Maximum images are 5 images per adop. We will keep your first 5 images. In case you want to change your images consider removing some of those first."
          );
      }
      this.hoverImage = false;
    },
    removeImage(index) {
      this.images[index].is_delete = true
      this.images[index].is_new = false
      this.form.images[index].is_delete = true
      this.form.images[index].is_new = false
      this.images.splice(index, 1);
      // this.form.images.splice(index, 1);
    },
    maxSelected() {
      const selectedItems = this.form.catagory.length;
      this.maxItemsSelected = false;
      if (selectedItems >= 5) {
        this.maxItemsSelected = true;
      }
    },
    insertKeyDisplayImg() {
      for (let i = 0; i < this.images.length; i++) {
        this.images[i]["path"] =
          process.env.VUE_APP_APIURL +
          ":" +
          process.env.VUE_APP_PORT +
          this.images[i].path;
        this.images[i].push({
          show: false,
          hover: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
