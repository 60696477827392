import Vue from "vue";
import VueRouter from "vue-router";

import Database from "../views/Database.vue";
import Home from "../views/Home.vue";
import MicroorganismForm from "../views/MicroorganismForm.vue"
import ImageForm from "@/components/form/ImageForm.vue"
import MediaScroller from "@/components/HorizontalMediaScroller.vue"
import Microorganism from "../views/Microorganism.vue"
import Login from "../views/auth/Login.vue"
import Register from "../views/auth/Register.vue"
import AddMicroorganismMethod from '../views/AddMicroorganismMethod.vue'
import MicroMap from '../views/map/Map.vue'
import Profile from '../views/user/Profile.vue'
import MyMicroorganism from '../views/user/MyMicroorganism.vue'
import EditProfile from '../views/user/EditProfile.vue'
import History from '../views/user/History.vue'
import ChangePassword from '../views/user/ChangePassword.vue'
import Welcome from '../views/Welcome.vue'
import GuestMicroorganism from '../views/guest/Microorganism.vue'
import UserStore from '../store/User'

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "test",
    component: MediaScroller,
    props: true
  },
  {
    path: "/database",
    name: "Database",
    component: Database,
    props: true
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/welcome",
    name: "welcome page",
    component: Welcome
  },
  {
    path: "/form",
    name: "MicroorganismForm",
    component: AddMicroorganismMethod,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/form/add-by-csv",
    name: "MicroorganismFormByCSV",
    component: MicroorganismForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/form/add-manual",
    name: "add microorganism manual",
    component: MicroorganismForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/image",
    name: "add image",
    component: ImageForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/microorganism/:id",
    name: "Microorganism Information",
    component: Microorganism
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  }, 
  {
    path: "/database/:type",
    name: "Show data by type",
    component: Database,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/microorganism/edit/:id",
    name: "edit microorganism by ID",
    component: MicroorganismForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/map",
    name: "Map",
    component: MicroMap
  },
  {
    path: "/account",
    name: "user profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/edit",
    name: "edit profile",
    component: EditProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/history",
    name: "user history",
    component: History,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/changepassword",
    name: "change password",
    component: ChangePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guest/microorganism/:id",
    name: "Microorganism guest",
    component: GuestMicroorganism
  },
  {
    path: "/mymicroorganism",
    name: "My Microorganism",
    component: MyMicroorganism
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!UserStore.getters.isAuthen) {
        next({
          path: '/login'
        });
    } else {
      next()
    } 
  } else {
    next()
  }
})

export default router;