import { defaultTheme } from "@chakra-ui/vue";

export default {
  fonts: {
    heading: '"Mitr", sans-serif',
    body: '"Mitr", sans-serif',
    mono: '"Mitr", sans-serif',
  },
  colors: {
    yellow:{
      500: "#E6AC00"
    },
    brand: {
      400: "#F1E4FF",
      500: "#8C30F5",
    },
  },
  variants:{
    colors: {
      yellow:{
        500: "#E6AC00"
      },
      brand: {
        400: "#F1E4FF",
        500: "#8C30F5",
      },
    },
  },
  breakpoints: ["30em", "48em", "62em", "80em"],
  space: {
    px: "1px",
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem",
  },
  sizes: {
    ...defaultTheme.space,
    full: "100%",
    "3xs": "14rem",
    "2xs": "16rem",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
  },
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
};