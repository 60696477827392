export function validateStepTwo(schema) {
    return schema.full_scientific_name.length > 0 && 
        schema.scientific_name.length > 0 && 
        schema.type.length > 0 &&
        schema.ku_code.length > 0 &&
        schema.isolate_code.length > 0 &&
        schema.kingdom.length > 0 &&
        schema.phylum.length > 0 &&
        schema.class.length > 0 &&
        schema.order.length > 0 &&
        schema.family.length > 0 &&
        schema.genus.length > 0
}

export function validateStepThree(schema) {
    return schema.found_by.length > 0 &&
        schema.identification_by.length > 0 &&
        schema.survey_by.length > 0 &&
        schema.isolation_by.length > 0 &&
        schema.sampling_by.length > 0 &&
        schema.source.length > 0 &&
        schema.location_remarks.length > 0 &&
        schema.found_status.length > 0 &&
        schema.gps.length > 0

}

export function validateStepFour(schema) {
    return schema.ecology.length > 0 &&
        schema.storage_method.length > 0 &&
        schema.special_characteristic.length > 0 &&
        schema.culture_media.length > 0 &&
        schema.temperature_for_growth.length > 0 &&
        schema.oxygen_demand.length > 0 &&
        schema.storage_condition.length > 0 &&
        schema.growth_condition.length > 0 &&
        schema.ph_for_culture_media.length > 0
}

export function validateStepFive(schema) {
    return schema.agricultural_application.length > 0 &&
        schema.industrial_application.length > 0
}

export function validateStepSix(schema) {
    return schema.organization_by.length > 0 &&
        schema.project_number.length > 0
}

export function validateSaveBtn(schema) {
    return true
}