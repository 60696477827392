import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import AuthHelper from '@/helpers/Header.js'
import UserStore from '@/store/User.js'
import Alert from '@/helpers/Alert.js';
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    microorganism: {
      full_scientific_name: '',
      scientific_name: "",
      ku_code: "",
      other_microbial_code: "",
      isolate_code: "",
      strain_code: "",
      microorganism_type: '',
      microorganism_subtype: '',
      nomenclatural_code: "",
      synonym: "",
      higher_taxon: "",
      kingdom: "",
      phylum: "",
      class: "",
      order: "",
      family: "",
      genus: "",
      subspecies: "",
      variety: "",
      colony_morphology: "",
      cell_morphology: "",
      source: "",
      location_remarks: "",
      found_description: "",
      survey_by: '',
      survey_date: '',
      sampling_by: '',
      sampling_date: '',
      isolation_by: '',
      isolation_date: '',
      identification_by: "",
      found_by: [],
      found_status: "",
      found_date: '-1',
      contact_person: "",
      culture_collection_assession_code: "",
      marker: {
        lat: 13.7589,
        lng: 100.5109,
      },
      gps: [],
      ecology: "",
      storage_method: "",
      special_characteristic: "",
      culture_media: "",
      temperature_for_growth: "",
      oxygen_demand: "",
      storage_condition: "",
      growth_condition: "",
      ph_for_culture_media: "",
      agricultural_application: "",
      agriculture_application_details: "",
      industrial_application: "",
      industrial_application_details: "",
      others_applications: "",
      project_number:"",
      keyword: "",
      organization_by: "",
      document_published: "",
      document_url: "",
      images: [],
    },
    fetchedMicroorganism: {},
    latestUpdate: [],
    clearForm: false,
    totalAmount: 0,
  },
  getters: {
    getMicroorganism: (state) => state.microorganism,
    getFetchedMicroorganism: (state) => state.fetchedMicroorganism,
    getLatestUpdate: (state) => state.latestUpdate,
    getClearForm: (state) => state.clearForm,
    getTotalAmount: (state) => state.totalAmount,
  },
  mutations: {
    updateInformation(state, obj) {
      state.microorganism = {...state.microorganism, ...obj}
    },
    setFetchedMicroorganism(state, obj) {
      state.fetchedMicroorganism = obj
      state.microorganism = obj
    },
    setLatestUpdate(state, obj) {
      const fiveElems = obj.slice(obj.length - 5, obj.length);
      state.latestUpdate = fiveElems;
    },
    setNewForm(state) {
      state.microorganism = {
        full_scientific_name: '',
        scientific_name: "",
        ku_code: "",
        other_microbial_code: "",
        isolate_code: "",
        strain_code: "",
        microorganism_type: '',
        microorganism_subtype: '',
        nomenclatural_code: "",
        synonym: "",
        higher_taxon: "",
        kingdom: "",
        phylum: "",
        class: "",
        order: "",
        family: "",
        genus: "",
        subspecies: "",
        variety: "",
        colony_morphology: "",
        cell_morphology: "",
        source: "",
        location_remarks: "",
        found_description: "",
        survey_by: '',
        survey_date: "",
        sampling_by: "",
        sampling_date: "",
        isolation_by: "",
        isolation_date: "",
        identification_by: "",
        found_by: [],
        found_status: "",
        found_date: "",
        contact_person: "",
        culture_collection_assession_code: "",
        marker: {
          lat: 13.7589,
          lng: 100.5109,
        },
        gps: [],
        ecology: "",
        storage_method: "",
        special_characteristic: "",
        culture_media: "",
        temperature_for_growth: "",
        oxygen_demand: "",
        storage_condition: "",
        growth_condition: "",
        ph_for_culture_media: "",
        agricultural_application: "",
        agriculture_application_details: "",
        industrial_application: "",
        industrial_application_details: "",
        others_applications: "",
        project_number:"",
        keyword: "",
        organization_by: "",
        document_published: "",
        document_url: "",
        images: [],
      } 
    },
    setClearForm(state, flag) {
      state.clearForm = flag;
    },
    setIsDelete(state, gps) {
      state.microorganism.gps[gps.index]['is_delete'] = gps.is_delete;
    },
    setTotalAmount(state, amount) {
      state.totalAmount = amount;
    }
  }, 
  actions: {
    async submitForm({ commit }) {
      try {
        // finalize found date
        
        if(this.state.microorganism.found_date){
          const date = this.state.microorganism.found_date + '-01';
          this.state.microorganism.found_date = date;

        }
        if(this.state.microorganism.survey_date){
          this.state.microorganism.survey_date = new Date().toISOString().substr(0, 10);
        }
        if(this.state.microorganism.sampling_date){
          this.state.microorganism.sampling_date = new Date().toISOString().substr(0, 10);
        }
        if(this.state.microorganism.isolation_date){
          this.state.microorganism.isolation_date = new Date().toISOString().substr(0, 10);
        }
        if(this.state.microorganism.found_date){
          this.state.microorganism.found_date = new Date().toISOString().substr(0, 10);
        }


        let auth_user = JSON.parse(localStorage.getItem('biodatabase'));
        let headers = {
          headers: {
            Accept: 'application/json',
            Authorization: `bearer ${auth_user.access_token}`
          }
        };

        // using formdata instead of JSON object
        let payload = new FormData()

        // handle images key
        Object.keys(this.state.microorganism.images).forEach((key) => {
          payload.append(`images[${key}]`, this.state.microorganism.images[key].file);
        });
        this.state.microorganism.gps.forEach((gps , i) => {
          payload.append(`gps[${i}]`, JSON.stringify(gps));
        });
        this.state.microorganism.found_by.forEach((found_by , i) => {
          payload.append(`found_by[${i}]`, found_by);
        });

        for (let key in this.state.microorganism) {
          if (key !== 'images' ) {
            if( key !== 'gps'){
              if( key !== 'found_by'){
                payload.append(key, this.state.microorganism[key]);

              }
            }
          }
        }
        
        let res = await axios.post('/auth/microorganisms', payload, headers);
        if (res.status === 201) {
          return {  
            status: res.status,
            id: res.data.id,
          }
        }
        else{

        }
      } catch (error) {
        if (error.response.status == 401) {
          let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
          if (isOk) {
            localStorage.removeItem("biodatabase")
            router.push({
              path: "/login",
            })
            window.location.reload(true);
          }
        }
        throw error;
      }
    },
    async updateMicroorganism({ commit }, ID) {
      try {
        // finalize found date
        if(this.state.microorganism.found_date){
          const date = this.state.microorganism.found_date + '-01';
          this.state.microorganism.found_date = date;

        }
        if(!this.state.microorganism.survey_date){
          this.state.microorganism.survey_date = '';
        }
        if(!this.state.microorganism.sampling_date){
          this.state.microorganism.sampling_date = '';
        }
        if(!this.state.microorganism.isolation_date){
          this.state.microorganism.isolation_date = '';
        }
        if(!this.state.microorganism.found_date){
          this.state.microorganism.found_date = '';
        }

        let header = AuthHelper.getHeaders({Accept: 'application/json'})

        // check if there is location to be deleted
        let location = this.state.microorganism.gps.filter((item) => item.is_delete);
        if (location.length > 0) {
          for (let i = 0; i < location.length; i++) {
            try {
              let res = await axios.delete(`auth/microorganisms/gps/${location[i].id}`, header);
              if (res == 200) {
                // console.log(`delete `, location[i].id)
              }
            } catch (error) {
              if (error.response.status == 401) {
                let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
                if (isOk) {
                  localStorage.removeItem("biodatabase")
                  this.$router.push({
                    path: "/login",
                  })
                  window.location.reload(true);
                }
              }
              throw error;
            }
          }
        }
        let payload = new FormData()

        this.state.microorganism.gps = this.state.microorganism.gps.filter((item) => item.is_new)
        this.state.microorganism.gps.forEach((gps , i) => {
            payload.append(`gps[${i}]`, JSON.stringify(gps));

          
        });

        // using formdata instead of JSON object

        // delete image
        let img =  this.state.microorganism.images.filter((item) => item.is_delete)
        if(img.length > 0){
          for(let i = 0; i < img.length ; i++){
            try {
              let res = axios.delete(`auth/microorganisms/image/${img[i].id}`, header);
              if (res == 200) {
                // console.log(`delete `, img[i].id)
              }
            } catch (error) {
              if (error.response.status == 401) {
                let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
                if (isOk) {
                  localStorage.removeItem("biodatabase")
                  this.$router.push({
                    path: "/login",
                  })
                  window.location.reload(true);
                }
              }
              throw error;
            }
          }
        }
        
        this.state.microorganism.images = this.state.microorganism.images.filter((item) => item.is_new)
        if (this.state.microorganism.images.length > 0) {
          
          Object.keys(this.state.microorganism.images).forEach((key) => {
            if (this.state.microorganism.images[key].file) {
              payload.append(`images[${key}]`, this.state.microorganism.images[key].file);
              
            }
          });
        }

        this.state.microorganism.found_by.forEach((found_by , i) => {
          payload.append(`found_by[${i}]`, found_by);
        });


        for (let key in this.state.microorganism) {
          if (key !== 'images' ) {
            if( key !== 'gps'){
              if( key !== 'found_by'){

                payload.append(key,   this.state.microorganism[key] );
              }
              

            }
          }
        }
        let res = await axios.post(`/auth/microorganisms/${ID}`, payload, header);
        if (res.status === 200) {
          return {
            status: res.status,
            id: res.data.id,
          }
        }
      } catch (error) {
        if (error.response.status == 401) {
          let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
          // if (isOk) {
          //   localStorage.removeItem("biodatabase")
          //   this.$router.push({
          //     path: "/login",
          //   })
          //   window.location.reload(true);
          // }
        }
        throw error;
      }
    },
    async fetchMicroorganismByID({ commit }, ID) {
      try {
        let res;
        if (UserStore.getters.isAuthen) {
          let header = AuthHelper.getHeaders({Accept: 'application/json'})
          res = await axios.get(`/auth/microorganisms/${ID}`, header)
        } else {
          res = await axios.get(`/microorganisms/${ID}`)
        }
        if (res.status === 200) {
          commit('setFetchedMicroorganism', res.data)
          return {
            status: 200
          }
        }
      } catch (error) {
        if (error.response.status == 401) {
          let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
          if (isOk) {
            localStorage.removeItem("biodatabase")
            router.push({
              path: "/login",
            })
            window.location.reload(true);
          }
        }
        throw error
      }
    },
    async deleteMicroorganism({ commit }, ID) {
      try {
        let auth_user = JSON.parse(localStorage.getItem('biodatabase'));
        let header = AuthHelper.getHeaders({Accept: 'application/json'})

        let res = await axios.delete(`auth/microorganisms/${ID}`, header);
        if (res.status === 200) {
          return {
            status: 200,
          }
        }
      } catch (error) {
        console.log(error.response.body);
        if (error.response.status == 401) {
          // let isOk = await Alert.window("error", "token expired", "please login again");
          // if (isOk) {
          //   localStorage.removeItem("biodatabase")
          //   router.push({
          //     path: "/login",
          //   })
          //   window.location.reload(true);
          // }
        }
        throw error;
      }
    },
    async basicSearch({ commit }, body) {
      try {
        let res = await axios.get(`/search/basic`, {params: body})
        if (res.status === 200) {
          commit('setFetchedMicroorganism', res.data)
          return {
            status: 200
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async advanceSearch({ commit }, body) {
      try {
        let res = await axios.get(`/search/advanced`,  { params: body })
        if (res.status === 200) {
          commit('setFetchedMicroorganism', res.data)
          return {
            status: 200
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchLatestUpdate({ commit }) {
      try {
        let res = await axios.get(`/microorganisms`);
        commit('setTotalAmount', res.data.meta.total);
        let latest = await axios.get(res.data.links.last);
        if (latest.data.data.length < 5) {
          let prev = await axios.get(latest.data.links.prev);
          let elem = prev.data.data[9];
          latest.data.data.push(elem);
        }
        if (latest.status === 200) {
          commit('setLatestUpdate', latest.data.data)
          return {
            status: 200
          }
        }
      } catch (e) {
        throw e
      }
    },
    clearForm({ commit }) {
      commit('setNewForm')
    },
    toggleClearForm({ commit }, flag) {
      commit('setClearForm', flag)
    },
    isDelete({ commit }, gps) {
      commit('setIsDelete', gps)
    }
  },
  modules: {
  }
})
