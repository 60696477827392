<template>
  <div class="container mx-auto">
    <c-heading my="2rem">Account Settings</c-heading>
    <!-- <font-awesome-icon v-if="windowWidth < 768" icon="bars" 
    class="text-4xl ml-4 cursor-pointer" 
    @click="openMenu = !openMenu"/> -->
    <c-flex
      class="flex flex-col md:flex-row"
      bgColor="white"
      my="2rem"
    >
      <!-- <sidebar v-if="openMenu || windowWidth > 768" /> -->
      <sidebar  />
      <my-micro-table class="md:w-4/5 w-full" />
    </c-flex>
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import MyMicroTable from "@/components/users/MyMicroTable.vue";

export default {
  components: {
    Sidebar,
    MyMicroTable,
  },
  data() {
    return {
      windowWidth: window.innerHeight,
      success: false,
      openMenu: false
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style></style>
