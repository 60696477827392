<template>
  <div class="relative h-screen">
    <div class="z-50 absolute bg-white left-5 w-1/2 sm:1/3 md:w-1/5 top-5 rounded-lg ">
      <div>
        <h2 class="bg-blue-400 text-center text-white text-2xl rounded-t-md">
          Microorganism type
        </h2>
        <div v-for="(type , index) in Object.entries(types)" :key="index">
          <div class="m-2">
            <c-checkbox
              @change="
                types[type[0]] = !types[type[0]];
                infoWinOpen = false;
                onTypeChange(type[0]);
              "
              >{{type[0]}}</c-checkbox
            >
          </div>
        </div>
        
        
        <div class="">
          <h2 class="bg-blue-400 text-center text-white text-2xl">
            Discovered at
          </h2>
          <c-select v-model="selectedProvince">
              <option value="" style="display: none">Province</option>
              <option
                v-for="(option, index) in province"
                :key="index"
                :value="option.PROVINCE_NAME"
                
                >{{ option.PROVINCE_NAME }}</option
              >
            </c-select>
        </div>


      </div>

    </div>
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      class="z-0"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <div v-for="(microorganism, index) in microorganisms" :key="index">
      <div 
        :key="index"
        v-for="(location, index) in microorganism.gps">
          <l-marker
              v-if="location.longitude && location.longitude"
              :lat-lng="getLatLng(location.latitude , location.longitude)"
              :icon="getIcon(microorganism.microorganism_type)"
              ref="marker"
              @click="centerPopup()"
              >
              <l-popup>
                <div class="">
                  <div>
                    <div class="m-2"><span style="font-weight: bold;">Scientific name: </span>
                      <span>{{microorganism.scientific_name}}</span>
                      <br>
                    </div>
                    <div class="m-2"><span style="font-weight: bold;">Type:  </span>
                      {{microorganism.microorganism_type}}
                      <br>
                    </div>
                    <a :href="`microorganism/${microorganism.id}`" class="m-2 text-blue-500">
                      ดูรายละเอียดเพิ่มเติม
                    </a>
                  </div>
                </div>
              </l-popup>
            </l-marker>

      </div>
    </div>
    </l-map>
    <!-- <GmapMap
      :center="center"
      :zoom="mapZoom"
      ref="gmap"
      class="h-full"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      }"
    >
    <div v-for="(microorganism, index) in microorganisms" :key="index">
      <div 
        :key="index"
        v-for="(location, index) in microorganism.gps">
          <GmapMarker
              v-if="location.longitude"
              :position="{ lat: location.latitude, lng: location.longitude }"
              :clickable="true"
              @click="
                toggleInfoWindow(microorganism, index);
                center = { lat: location.latitude, lng: location.longitude };
              "
            />

      </div>
    </div>
            <GmapInfoWindow
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <div v-html="infoContent"></div>
            </GmapInfoWindow>
    </GmapMap> -->
    
  </div>
</template>

<script>
import axios from "axios";
import LocationStore from "../../store/LocationStore";
import UserStore from '../../store/User'

import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      types: {
      },
      fetchedType: [],
      province: ["กรุงเทพ"],
      microorganisms: [],
      colors: {
        bacteria: 'rgb(253, 127, 111)',
        molds: 'rgb(126, 176, 213)',
        yeasts: 'rgb(255, 181, 90)',
        microalgae: 'rgb(139, 211, 199)',
        actinobacteria: 'rgb(190, 185, 219)',
        fungi: 'rgba(54, 162, 235, 0.2)',
      },
      allMicro: [],
      mapZoom: 7,
      center: latLng(13.736717, 100.523186),
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      infoContent: "",
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      selectedProvince: "",

      zoom: 7,
      // center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true
    };
  },
  created() {
    UserStore.dispatch('toggleMenu', 3)
    this.fetchProvince();
    this.getMicroTypesData();
    this.fetchMicroorganismLocation();

  },
  watch: {
    selectedProvince: function(val) {
      if (val) {
        this.onProvinceChange();
      }
    },
    type: function(val) {
      if (val) {
        this.onProvinceChange();
      }
    },
  },
  methods: {
    async getMicroTypesData() {
      let res = await axios.get(`/home`);
      if (res.status === 200) {
        res.data.micro_types.forEach(type => {
          this.types[type.name] = false
        });
        
      }
    },
    async fetchMicroorganismLocation() {

      this.onTypeChange('bacteria')
    },
    markerClick(latitude, long) {
      this.zoom = 9;
      this.center = { lat: latitude, lng: long };
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = {
        lat: marker.gps[idx].latitude,
        lng: marker.gps[idx].longitude,
      };
      this.infoContent = this.getInfoWindowContent(marker);
      this.infoWinOpen = true;
    },
    async fetchProvince() {
      let res = await LocationStore.dispatch("fetchProvince");
      if (res.status === 200) {
        this.province = LocationStore.getters.getProvince;
      }
    },
    async onTypeChange(typee) {
      this.infoWinOpen = false;
      if (this.selectedProvince != "") {
        this.onProvinceChange();
        return
      }
      //todo loading
      const typeAsArray = Object.entries(this.types);
      var typeArr = typeAsArray.filter(([key, value]) => value );
      var typeA = []
      typeArr.forEach(element => {
        typeA.push(element[0])
      });
      if(this.fetchedType.includes(typee)){
        this.microorganisms = this.allMicro.filter(micro => typeA.includes(micro.microorganism_type))
        return

      }
      try{
      let res = await axios.get(`/map/${typee}`);

      
      this.allMicro = this.allMicro.concat(res.data.data);
      this.fetchedType.push(typee)


      
      this.microorganisms = this.allMicro.filter(micro => typeA.includes(micro.microorganism_type))
    } catch (e) {
        return
      }

    },
    onProvinceChange() {
      this.infoWinOpen = false;
      const typeAsArray = Object.entries(this.type);
      var typeArr = typeAsArray.filter(([key, value]) => value );
      var typeA = []
      typeArr.forEach(element => {
        typeA.push(element[0])
      });
      this.microorganisms = this.allMicro.filter(
        m => m.gps.filter(g => g.province == this.selectedProvince).length > 0 
        && typeA.includes(m.microorganism_type)
        )
    },

    getInfoWindowContent(marker) {
      let mID = marker.id;
      return `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">Full scientific name: </span>
                ${marker.full_scientific_name}
              </div>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Scientific name:  </span>
              ${marker.scientific_name}
              <br>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Type:  </span>
              ${marker.microorganism_type}
              <br>
            </div>
            <a href="microorganism/${mID}" class="m-2 text-blue-500">
              ดูรายละเอียดเพิ่มเติม
            <a>
          </div>
        </div>`;
    },

    getIcon(type) {
      return L.divIcon({
        className: "my-custom-pin",
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="50" width="20" >
      <g transform="translate(-814.59595,-274.38623)">
        <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
          <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${this.colors[type]};stroke:#ecc9c9;"/>
          <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;fill:#590000;"/>
        </g>
      </g>
    </svg>`
      });
    },

    getLatLng( lat , lng) {
      return latLng(lat , lng);
    },

    centerUpdate(center) {
      this.currentCenter = center;
    },

    centerPopup(){
      // console.log('centerPopup');
      // console.log(this.$refs.marker);
      // this.$refs.marker[0].openPopup();
      
    }
  },
};
</script>

<style>
.my-custom-pin{
   background-color: transparent;
   margin-left: 5px;
}
</style>
