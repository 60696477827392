<template>
  <div class="container mx-auto">
      <c-heading my="2rem">Account Settings</c-heading>
      <c-flex bgColor="white" my="2rem">
          <sidebar />
          <profile-setting />
      </c-flex>
  </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue'
import ProfileSetting from '../../components/users/ProfileSetting.vue'

export default {
    components: {
        Sidebar,
        ProfileSetting
    }
}
</script>

<style>

</style>