<template>
 <div class="mt-8">
     <c-text>Choose type of search (เลือกหัวข้อที่ต้องการค้นหา)</c-text>
     <c-flex  my="1rem" class="flex-row gap-6 justify-around">
        <div class="flex flex-col gap-4">
            <c-checkbox :is-checked="!disableSearchInput.isolate_code" name="isolate" @change="(val, $e) => toggleCheckBox($e.target.name)">Isolate code</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.genus" name="genus" @change="(val, $e) => toggleCheckBox($e.target.name)">Genus</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.province" name="province" @change="(val, $e) => toggleCheckBox($e.target.name)">Province</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.source" name="source" @change="(val, $e) => toggleCheckBox($e.target.name)">Source</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.location_remarks" name="location_remarks" @change="(val, $e) => toggleCheckBox($e.target.name)">Location remarks</c-checkbox>
        </div>
        <div class="flex flex-col gap-4">
            <c-checkbox :is-checked="!disableSearchInput.ecology" name="ecology" @change="(val, $e) => toggleCheckBox($e.target.name)">Ecology</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.special_characteristic" name="special_char" @change="(val, $e) => toggleCheckBox($e.target.name)">Special characteristic</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.keyword" name="keywords" @change="(val, $e) => toggleCheckBox($e.target.name)">Keywords</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.contact_person_name" name="contact_person" @change="(val, $e) => toggleCheckBox($e.target.name)">Contact person</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.agriculture" name="agri" @change="(val, $e) => toggleCheckBox($e.target.name)">Agriculture application</c-checkbox>
        </div>
        <div class="flex flex-col gap-4">
            <c-checkbox :is-checked="!disableSearchInput.agriculture_detail" name="agri_details" @change="(val, $e) => toggleCheckBox($e.target.name)">Agriculture application details</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.industrial" name="indust" @change="(val, $e) => toggleCheckBox($e.target.name)">Industrial application</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.industrial_detail" name="indust_details" @change="(val, $e) => toggleCheckBox($e.target.name)">Industrial applicaiton details</c-checkbox>
            <c-checkbox :is-checked="!disableSearchInput.others_applications" name="others_apps" @change="(val, $e) => toggleCheckBox($e.target.name)">Others applications</c-checkbox>
        </div>


     </c-flex>
    <c-form-control>
        <c-stack :spacing="5">
            <c-grid :template-columns="[ 'repeat(1, 1fr)','repeat(2, 1fr)' , 'repeat(2, 1fr)','repeat(2, 1fr)']" gap="6">
                <c-stack :spacing="3">
                    <c-box>
                        <c-form-label>Isolate code</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.isolate_code" 
                            :disabled="disableSearchInput.isolate_code" 
                            placeholder="e.g., AH-310" />
                    </c-box>
                    <c-box>
                        <c-form-label>Genus (สกุล)</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.genus" 
                            :disabled="disableSearchInput.genus" 
                            placeholder="e.g., Allokutzneria">
                        </c-input>
                    </c-box>
                    <c-box>
                        <c-form-label>Province (จังหวัด)</c-form-label>
                        <!-- <autocomplete
                            @input.native="handleAgricultureChange"
                            placeholder="Province"
                            :disabled="disableSearchInput.industrial" 
                            :search="searchIndustrialApplication"
                            :get-result-value="getResultValue" auto-select>
                        </autocomplete> -->
                        <c-select v-model="advanceSearch.body.province" :disabled="disableSearchInput.province">
                                <option value="" style="display:none">เลือกจังหวัด</option>
                                <option
                                v-for="(option, index) in dropdownData.province"
                                :key="index"
                                :value="option"
                                >{{ option }}
                                </option>
                        </c-select>
                    </c-box>
                    <c-box>
                        <c-form-label>Source (แหล่งค้นพบ)</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.source" 
                            :disabled="disableSearchInput.source"
                            placeholder="e.g., rice rhizosphere soil (ดินรอบรากข้าว)">
                        </c-input>
                    </c-box>
                    <c-box>
                        <c-form-label>Location remarks (รายละเอียดแหล่งค้นพบ)</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.location_remarks" 
                            :disabled="disableSearchInput.location_remarks"
                            placeholder="rice field (นาข้าว)">
                        </c-input>
                    </c-box>
                    <c-box>
                        <c-form-label>Ecology (ระบบนิเวศ)</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.ecology" 
                            :disabled="disableSearchInput.ecology"
                            placeholder="e.g., peat swamp forest (ป่าพรุ)">
                        </c-input>
                    </c-box>
                    <c-box>
                        <c-form-label>Special characteristic (ลักษณะเด่นของจุลินทรีย์)</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.special_characteristic" 
                            :disabled="disableSearchInput.special_characteristic"
                            placeholder="e.g., zinc solubilizing">
                        </c-input>
                    </c-box>
                    <c-box>
                        <c-form-label>Keywords (คำสำคัญ)</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.keyword" 
                            :disabled="disableSearchInput.keyword"
                            placeholder="คั่นแต่ละคำด้วยเครื่องหมาย ; (Semicolon separated)">
                        </c-input>
                    </c-box>
                </c-stack>

                <c-stack :spacing="3">
                    <c-box>
                        <c-form-label>Contact person (ผู้ติดต่อ)</c-form-label>
                        <c-input 
                            v-model="advanceSearch.body.contact_person_name" 
                            :disabled="disableSearchInput.contact_person_name"
                            placeholder="e.g., Nantana Srisuk">
                        </c-input>
                    </c-box>
                    <c-box>
                        <c-form-label>Agriculture application (ประโยชน์ทางการเกษตร)</c-form-label>
                        <c-select v-model="advanceSearch.body.agriculture" :disabled="disableSearchInput.agriculture">
                                <option value="" style="display:none">Agriculture application</option>
                                <option
                                v-for="(option, index) in dropdownData.agricultural"
                                :key="index"
                                :value="option"
                                >{{ option }}
                                </option>
                        </c-select>
                    </c-box>
                    <c-box>
                        <c-form-label>Agriculture application details (รายละเอียดของประโยชน์ด้านเกษตรกรรม)</c-form-label>
                        <c-textarea 
                            id="agri-details-textarea-input" 
                            v-model="advanceSearch.body.agriculture_detail" 
                            :disabled="disableSearchInput.agriculture_detail"
                            placeholder="IAA production">
                        </c-textarea>
                    </c-box>
                    <c-box>
                        <c-form-label>Industrial application (ประโยชน์ทางอุตสาหกรรม)</c-form-label>
                        <c-select v-model="advanceSearch.body.industrial" :disabled="disableSearchInput.industrial">
                                <option value="" style="display:none">Industrial application</option>
                                <option
                                v-for="(option, index) in dropdownData.industrial"
                                :key="index"
                                :value="option"
                                >{{ option }}
                                </option>
                        </c-select>
                    </c-box>
                    <c-box>
                        <c-form-label>Industrial application details (รายละเอียดของประโยชน์ด้านอุตสาหกรรม)</c-form-label>
                        <c-textarea 
                            id="indust-details-textarea-input" 
                            v-model="advanceSearch.body.industrial_detail" 
                            :disabled="disableSearchInput.industrial_detail"
                            placeholder="lipase producing yeast">
                        </c-textarea>
                    </c-box>
                    <c-box>
                        <c-form-label>Others applications (ประโยชน์อื่น ๆ)</c-form-label>
                        <c-textarea id="others-apps-textarea-input" v-model="advanceSearch.body.others_applications" :disabled="disableSearchInput.others_applications">
                        </c-textarea>
                    </c-box>
                </c-stack>
            </c-grid>
            <!-- <c-box>
                <c-form-label>ประเภทของจุลินทรีย์</c-form-label>
                 <c-select v-model="type">
                        <option value="" style="display:none">Microorganism Type</option>
                        <option value="bacteria">bacteria</option>
                        <option value="fungi">fungi</option>
                        <option value="yeast">yeast</option>
                        <option value="microalgae">microalgae</option>
                </c-select>
            </c-box> -->
            <!-- <c-box>
                <c-form-label>Agriculture application (ประโยชน์ทางการเกษตร)</c-form-label>
                <autocomplete
                    @input.native="handleAgricultureChange"
                    placeholder="Agriculture application"
                    :disabled="disableSearchInput.agriculture" 
                    :search="searchApplication"
                    :get-result-value="getResultValue" auto-select>
                </autocomplete>
                 
            </c-box> -->           
            <!-- <c-box>
                <c-form-label>Industrial application (ประโยชน์ทางอุตสาหกรรม)</c-form-label>
                <autocomplete
                    @input.native="handleAgricultureChange"
                    placeholder="Industrial application"
                    :disabled="disableSearchInput.industrial" 
                    :search="searchIndustrialApplication"
                    :get-result-value="getResultValue" auto-select>
                </autocomplete>
            </c-box> -->
        </c-stack>
    </c-form-control>
    <c-box textAlign="right " class="mt-4">
        <c-button @click="clearForm()">
          Clear All
        </c-button>
      </c-box>
    <!-- <div class="flex justify-end">clear</div> -->
  </div>
</template>

<script>
import ApplicationStore from '../../store/ApplicationStore'
import LocationStore from '../../store/LocationStore'
import ResearcherStore from '../../store/ResearcherStore'
import OrganizationStore from '../../store/OrganizationStore'
import Axios from 'axios'

export default {
    data() {
        return {
            agriculturalApplication: [],
            industrialApplication: [],
            province: [],
            researchers: [],
            organizations: [],
            disableSearchInput: { // disable by default
                isolate_code: true,
                agriculture: true,
                industrial: true,
                province: true,
                genus: true,
                source: true,
                location_remarks: true,
                contact_person_name: true,
                ecology: true,
                special_characteristic: true,
                agriculture_detail: true,
                industrial_detail: true,
                others_applications: true,
                keyword: true,
            },
            isLoading: true,
            advanceSearch: {
                activeTab: 1,
                body: {
                    isolate_code: "",
                    agriculture: "",
                    industrial: "",
                    province: "",
                    genus: "",
                    source: "",
                    location_remarks: "",
                    contact_person_name: "",
                    ecology: "",
                    special_characteristic: "",
                    agriculture_detail: "",
                    industrial_detail: "",
                    others_applications: "",
                    keyword: "",
                },
            },
            dropdownData: []
        }
    },
    created() {
        this.fetchDropDownData();
    },
    methods: {
        search() {
            return this.advanceSearch;
        },
        toggleCheckBox(cmd) {
            switch (cmd) {
                case 'isolate':
                    this.disableSearchInput.isolate_code = !this.disableSearchInput.isolate_code
                    break;
                case 'agri':
                    this.disableSearchInput.agriculture = !this.disableSearchInput.agriculture
                    break;
                case 'indust':
                    this.disableSearchInput.industrial = !this.disableSearchInput.industrial
                    break;
                case 'province':
                    this.disableSearchInput.province = !this.disableSearchInput.province
                    break;
                case 'genus':
                this.disableSearchInput.genus = !this.disableSearchInput.genus
                    break;
                case 'source':
                this.disableSearchInput.source = !this.disableSearchInput.source
                    break;
                case 'location_remarks':
                this.disableSearchInput.location_remarks = !this.disableSearchInput.location_remarks
                    break;
                case 'contact_person':
                this.disableSearchInput.contact_person_name = !this.disableSearchInput.contact_person_name
                    break;
                case 'ecology':
                this.disableSearchInput.ecology = !this.disableSearchInput.ecology
                    break;
                case 'special_char':
                this.disableSearchInput.special_characteristic = !this.disableSearchInput.special_characteristic
                    break;
                case 'agri_details':
                this.disableSearchInput.agriculture_detail = !this.disableSearchInput.agriculture_detail
                    break;
                case 'indust_details':
                this.disableSearchInput.industrial_detail = !this.disableSearchInput.industrial_detail
                    break;
                case 'others_apps':
                this.disableSearchInput.others_applications = !this.disableSearchInput.others_applications
                    break;
                case 'keywords':
                this.disableSearchInput.keyword = !this.disableSearchInput.keyword
                    break;
            }
        },
        async fetchDropDownData(){
            try {
                this.isLoading = true;
                let res = await Axios.get(`/dropdown`);
                if (res.status === 200) {
                    this.dropdownData = res.data;
                    this.isLoading = false;
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },
        handleAgricultureChange(e) {
            this.advanceSearch.body.agriculture = e.target.value
            
        },
        searchApplication(input ) {
            if (input.length < 1) { return [] }
            var resultList = this.agriculturalApplication.filter(a => {
                return a.category.startsWith(input.toLowerCase())
            })
            return resultList
        },
        searchIndustrialApplication(input) {
            if (input.length < 1) { return [] }
            var resultList = this.industrialApplication.filter(a => {
                return a.category.startsWith(input.toLowerCase())
            })
            return resultList
        },
        getResultValue(result) {
            this.advanceSearch.body.agriculture = result.category
            return result.category
        },
        clearForm(){
            this.advanceSearch.body = {
                isolate_code: "",
                agriculture: "",
                industrial: "",
                province: "",
                genus: "",
                source: "",
                location_remarks: "",
                contact_person_name: "",
                ecology: "",
                special_characteristic: "",
                agriculture_detail: "",
                industrial_detail: "",
                others_applications: "",
                keyword: "",
            }
            // clear display textarea
            document.getElementById("agri-details-textarea-input").value = ""
            document.getElementById("indust-details-textarea-input").value = ""
            document.getElementById("others-apps-textarea-input").value = ""

            // disable fields
            this.disableSearchInput = {
                isolate_code: true,
                agriculture: true,
                industrial: true,
                province: true,
                genus: true,
                source: true,
                location_remarks: true,
                contact_person_name: true,
                ecology: true,
                special_characteristic: true,
                agriculture_detail: true,
                industrial_detail: true,
                others_applications: true,
                keyword: true,
            }
        }
    }
}
</script>

<style>

</style>