<template>
  <div>
    <div class="py-8 pl-8 pr-10  rounded-md" >
      <VueSlickCarousel v-bind="settings" class="pl-3 pr-2 ">
        <div v-for="(media, index) in img" :key="index" class="media-element p-2">
          <img :src="media" alt="" class="pointer-events-none " />
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  props: ["img"],
  name: "MyComponent",
  components: { VueSlickCarousel },

  data() {
    return {
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        // arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 5,
      },
    };
  },
};
</script>

<style>
button.slick-prev:before,
button.slick-next:before {
  color: black;
  font-size: 30px;
}
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}


</style>
