<template>
  <div class="flex flex-col">
    <nav class="px-4 flex justify-between bg-white h-20">
      <!-- top bar left -->
      <ul class="flex items-center">
        <!-- add button -->
        <img
          class="h-4/5 w-full mx-auto sm:w-full sm:h-4/5 md:w-full md:h-full"
          src="../assets/2.png"
          alt="DB Bioresource Logo"
          @click="toHome()"
        />
      </ul>

      <!-- to bar right  -->
      <ul class="flex items-center">
        <li v-if="!status" class="h-10 w-full text-2xl">
          <router-link to="/login">
            <div>SIGN IN</div>
          </router-link>
        </li>
        <li v-if="status" class="relative h-10 w-full text-2xl">
          <div
            v-if="windowWidth > 850"
            class=" pr-4 flex flex-row hover:bg-blueGray-100 cursor-pointer bg-gray-100 rounded-3xl my-auto"
            @click="isOpen = !isOpen"
          >
            <!-- <img
              class="h-12 w-12 rounded-full mr-2"
              :src="`${getImagePath(image)}`"
              alt=""
            /> -->
            <div class="my-auto mr-2 ml-6">
              {{ name }}
              <!-- test -->
            </div>

            <font-awesome-icon
              v-if="!open"
              icon="sort-down"
              class="text-xl text-black  mt-1 "
            />
            <font-awesome-icon
              v-if="open"
              icon="sort-up"
              class="text-xl text-black my-auto "
            />

            <div
              v-if="open"
              class="bg-white absolute rounded-xl right-0 py-4 px-5 mr-4 w-full z-50 mt-12"
            >
              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg"
                @click="tosetting()"
              >
                Account Settings
              </div>
              <div class="border-b-2 border-gray-400 mx-2 my-1"></div>

              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg"
                @click="logout()"
              >
                Sign Out
              </div>
            </div>
          </div>
          <div
            v-if="windowWidth < 850"
            class=" pr-4 flex flex-row hover:bg-blueGray-100 cursor-pointer  rounded-3xl "
            @click="isOpen = !isOpen"
          >
            <!-- <img
              class="h-12 w-12 rounded-full mr-2"
              :src="`${getImagePath(image)}`"
              alt=""
            /> -->
            <font-awesome-icon
              v-if="!open"
              icon="sort-down"
              class="text-xl text-black  mt-1 "
            />
            <font-awesome-icon
              v-if="open"
              icon="sort-up"
              class="text-xl text-black my-auto mt-1"
            />
            
            
          </div>
        </li>
        <button
          v-if="open"
          @click="isOpen = !isOpen"
          class="fixed h-screen w-full bg-black inset-0 opacity-30 cursor-default z-40"
        ></button>
      </ul>
    </nav>
            
    <div class="border-b-2 bg-white">

      <div
        v-if="windowWidth > 850"
        class="flex flex-row items-center m-3 justify-around"
      >
        <!-- Item #1 -->
        <div class="flex group ">
          <div class="flex flex-col items-center">
            <!-- Text -->
            <router-link to="/">
              <div
                class="text-gray-400 header-menu"
                :class="toggleMenu === 0 ? 'text-blue-500' : ''"
              >
                Home
              </div>
            </router-link>

            <!-- Focus Dot -->
            <div
              class="h-2 w-2 rounded-full group-hover:bg-blue-500
									transition-all duration-150 delay-100"
              :class="
                toggleMenu === 0
                  ? 'bg-blue-500 group-hover:bg-blue-500 hover:h-3 hover:w-3'
                  : ''
              "
            ></div>
          </div>
        </div>

        <!-- Item #2 -->
        <div class="flex group">
          <div class="flex flex-col items-center">

            <!-- Text -->
            <router-link to="/database">
              <div
                class="text-gray-400 header-menu"
                :class="toggleMenu === 1 ? 'text-blue-500' : ''"
              >
                Database
              </div>
            </router-link>

            <!-- Focus Dot -->
            <div
              class="h-2 w-2 rounded-full group-hover:bg-blue-500
									transition-all duration-150 delay-100"
              :class="
                toggleMenu === 1
                  ? 'bg-blue-500 group-hover:bg-blue-500 hover:h-3 hover:w-3'
                  : ''
              "
            ></div>
          </div>
        </div>

        <!-- Item #3 -->
        <div class="flex group">
          <div class="flex flex-col items-center">

            <!-- Text -->
            <router-link to="/welcome" v-if="status">
              <div
                class="text-gray-400 header-menu"
                :class="toggleMenu === 2 ? 'text-blue-500' : ''"
              >
                Manage Microorganism
              </div>
            </router-link>

            <!-- Focus Dot -->
            <div
              class="h-2 w-2 rounded-full group-hover:bg-blue-500
									transition-all duration-150 delay-100 mx-auto"
              :class="
                toggleMenu === 2
                  ? 'bg-blue-500 group-hover:bg-blue-500 hover:h-3 hover:w-3'
                  : ''
              "
            ></div>
          </div>
        </div>

        <!-- Item #4 -->
        <div class="flex group">
          <div class="flex flex-col items-center">

            <!-- Text -->
            <router-link to="/map" v-if="status">
              <div
                class="text-gray-400 header-menu"
                :class="toggleMenu === 3 ? 'text-blue-500' : ''"
              >
                Microorganism Map
              </div>
            </router-link>

            <!-- Focus Dot -->
            <div
              class="h-2 w-2 rounded-full group-hover:bg-blue-500
									transition-all duration-150 delay-100"
              :class="
                toggleMenu === 3
                  ? 'bg-blue-500 group-hover:bg-blue-500 hover:h-3 hover:w-3'
                  : ''
              "
            ></div>
          </div>
        </div>

        <!-- Item #5 -->
        <div class="flex group">
          <div class="flex flex-col items-center">

            <!-- Text -->
            <router-link to="#" v-if="status">
              <div
                class="text-gray-400 header-menu"
                :class="toggleMenu === 4 ? 'text-blue-500' : ''"
              >
                Contact us
              </div>
            </router-link>

            <!-- Focus Dot -->
            <div
              class="h-2 w-2 rounded-full group-hover:bg-blue-500
									transition-all duration-150 delay-100"
                  :class="toggleMenu === 4 ? 'bg-blue-500 group-hover:bg-blue-500 hover:h-3 hover:w-3' : ''"></div>
							  </div>
					</div>
				</div>
			</div>
      <div
              v-if="open && windowWidth < 850"
              class="h-full bg-white   py-4 px-5  z-50"
            >
              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg cursor-pointer"
                @click="toHome()"
              >
                Home
              </div>
              <div class="border-b-2 border-gray-400 mx-2 my-1"></div>
              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg cursor-pointer"
                @click="toDatabase()"
              >
                Database
              </div>
              <div class="border-b-2 border-gray-400 mx-2 my-1"></div>
              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg cursor-pointer"
                @click="toManageMicro()"
              >
                Manage Microorganism
              </div>
              <div class="border-b-2 border-gray-400 mx-2 my-1"></div>
              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg cursor-pointer"
                @click="toMap()"
              >
                Microorganism Map
              </div>
              <div class="border-b-2 border-gray-400 mx-2 my-1"></div>
              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg cursor-pointer"
                @click="tosetting()"
              >
                Account Settings
              </div>
              <div class="border-b-2 border-gray-400 mx-2 my-1"></div>

              <div
                class="hover:bg-gray-100 mx-2 my-1 px-2 rounded-lg cursor-pointer"
                @click="logout()"
              >
                Sign Out
              </div>
            </div>
  </div>
</template>

<script>
import UserStore from "@/store/User";
import Alert from "@/helpers/Alert";

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      txt: "",
      user: {},
      name: "",
      profile: "",
      isOpen: false,
    };
  },
  computed: {
    status: function() {
      return UserStore.getters.isAuthen;
    },
    open: function() {
      return this.isOpen;
    },
    toggleMenu: function() {
      return UserStore.getters.getToggleMenu;
    },
    image: function() {
      return UserStore.getters.getUserImage;
    }
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      
      // console.log("new width: " + newWidth);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (localStorage.getItem("biodatabase")) {
      try {
        this.user = JSON.parse(localStorage.getItem("biodatabase"));
        this.name = this.user.user.username;
        this.profile = this.user.user.profile;
      } catch (e) {
        localStorage.removeItem("biodatabase");
      }
    }
  },
  methods: {
    async logout() {
      try {
        let res = await UserStore.dispatch("logout");
        Alert.mixin("success", res.data.message);
        this.$router.push("/login");
      } catch (error) {
        console.error(error.message);
      }
    },
    getImagePath(image) {
      return process.env.VUE_APP_APIURL +":"+process.env.VUE_APP_PORT+ image;
    },
    tosetting() {
      this.isOpen = false;
      this.$router.push("/account");
    },
    toHome(){
      this.isOpen = false;
      this.$router.push("/");
    },
    toDatabase(){
      this.isOpen = false;
      this.$router.push("/database");
    },
    toManageMicro(){
      this.isOpen = false;
      this.$router.push("/welcome");
    },
    toMap(){
      this.isOpen = false;
      this.$router.push("/map");
      return process.env.VUE_APP_APIURL + ":" + process.env.VUE_APP_PORT + image;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style></style>
