<template>
  <div class="mt-8">
    <c-form-control>
      <!-- <c-form-label>Type microorganism's scientific name</c-form-label> -->
        <c-input-group>
          <c-input-left-element>
            <c-icon name="search" color="gray.300"/>
          </c-input-left-element>
          <c-input type="search" placeholder="คำค้นหา" v-model="basicSearch.body.full_scientific_name"/>
        </c-input-group>
      <c-form-helper-text></c-form-helper-text>
    </c-form-control>
  </div>
</template>

<script>

export default {
  data() {
    return {
      basicSearch: {
        activeTab: 0,
        body: {
          full_scientific_name: "",
        },
      }
    }
  },
  methods: {
    search() {
      return this.basicSearch;
    }
  }
};
</script>

<style>
</style>