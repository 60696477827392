import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import css
import './assets/css/tailwind.css'
import 'leaflet/dist/leaflet.css';


//import FontAwesomeIcon
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//import sweetalert2
import 'sweetalert2/dist/sweetalert2.min.css';

//import vue geolocation
import VueGeolocation from 'vue-browser-geolocation';
Vue.config.productionTip = false
Vue.use(VueGeolocation);

//import vue=google-map
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps , {
  load:{
    // key: 'AIzaSyAdhiY8eQcl4D1uIO8kD3N53lLb9fNKu0w',
    key: 'AIzaSyBkFopyMXAf4_PjKa2r7aaaPP_MabHW3eg',
  },
})

//import leaflet map
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

// delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//    iconUrl: require('leaflet/dist/images/marker-icon.png'),
//    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });

//import Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL =
  process.env.VUE_APP_APIURL + ":" + process.env.VUE_APP_PORT + "/api" || "http://127.0.0.1:8000/api";

// import chakra ui
import Chakra, { CThemeProvider, CReset } from "@chakra-ui/vue"
import theme from '../style/theme.js'
import '../style/font.css'

// import vue easy table
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";

import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

Vue.use(Autocomplete)

// import vue slick carousel
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
Vue.component('vue-slick-carousel', VueSlickCarousel)

// import icons
import { 
  faSearch, 
  faTimes, 
  faEdit, 
  faTrash, 
  faFileCsv, 
  faKeyboard, 
  faSave,
  faUser,
  faKey,
  faHistory,
  faUserCog,
  faDatabase, 
  faArrowLeft,
  faHome,
  faBook,
  faCamera,
  faEye,
  faEyeSlash,
  faImages,
} from '@fortawesome/free-solid-svg-icons'

// import spinner
import Loading from '../src/components/misc/Loading.vue'
Vue.component('loading', Loading)

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

library.add(fas)
library.add(fab)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueEasytable);

// inject fontawesome icon right here
Vue.use(Chakra, {
  extendTheme: theme,
  icons: {
    iconPack: 'fa',
    iconSet: {
      faSearch,
      faTimes,
      faEdit, 
      faTrash, 
      faFileCsv,
      faKeyboard,
      faSave,
      faUser,
      faKey,
      faHistory,
      faUserCog,
      faDatabase,
      faArrowLeft,
      faHome,
      faBook,
      faCamera,
      faEye,
      faEyeSlash,
      faImages
    }
  }
})

new Vue({
  router,
  store,
  render: (h) => h(CThemeProvider, [h(CReset), h(App)]),
}).$mount('#app')