import { render, staticRenderFns } from "./MicroorganismFormCSV.vue?vue&type=template&id=03ea18d3&"
import script from "./MicroorganismFormCSV.vue?vue&type=script&lang=js&"
export * from "./MicroorganismFormCSV.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CHeading: require('@chakra-ui/vue').CHeading, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CModalBody: require('@chakra-ui/vue').CModalBody, CButton: require('@chakra-ui/vue').CButton, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
