<template>
  <div>
      <c-flex
      mt="30"
      flexDirection="column"
      w="100%"
      alignItems="stretch"
      >
        <c-button :disabled="disabled" v-bind:left-icon="icon" variant-color="blue">{{ menu }}</c-button>
      </c-flex>
  </div>
</template>

<script>
export default {
    props: ['menu', 'icon', 'disabled']
}
</script>

<style>
</style>