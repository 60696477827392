<template>
  <div>
      <loading v-if="isLoading" />
      <c-stack v-if="!isLoading" :spacing=5 align="center" my="2rem" >
          <c-flex flexDirection="column" width="80%" justify="center">
            <c-box bgColor="black" textAlign="center" py=".5rem">
                <SciNameComponent :sci_name="microorganism.scientific_name || 'ไม่ระบุชื่อ'" class="text-4xl text-white"/>
            </c-box>
            <c-stack bgColor="white" mb="0.75rem" py="2rem" px="4">
                <c-box px="1rem">
                    <c-text fontSize="4xl" class="font-bold">General Information</c-text>
                </c-box>
                <c-simple-grid :columns="['1','1','2','2']" :spacing="1" px="2rem" class="text-xl md:text-2xl">
                    <!-- Column 1 -->
                    <div>
                        <div class="pb-6">
                            <c-box >
                                <c-text class="font-bold">Scientific name (ชื่อวิทยาศาสตร์)</c-text>
                            </c-box>
                            <c-text class="data-text" >
                                <SciNameComponent :sci_name="microorganism.scientific_name || '-'"/>
                            </c-text>
                        </div>
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Isolate code (รหัสไอโซเลต)</c-text>
                            </c-box>
                            <c-box>
                                <c-text class="data-text">{{ microorganism.isolate_code || "-" }}</c-text>
                            </c-box>
                        </div>
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Microorganism type (ประเภทของจุลินทรีย์)</c-text>
                            </c-box>
                            <c-box>
                                
                                <c-text class="data-text">{{ formatHumanName(microorganism.microorganism_type)  || "-" }}</c-text>
                            </c-box>
                        </div>
                        <div class="pb-6">
                        <c-box>
                            <c-text class="font-bold">Classification (การจำแนกชั้น)</c-text>
                        </c-box>
                        <c-box>
                            <!-- <c-stack :spacing="1" class="data-text">
                                <c-text>{{ `Kingdom (อาณาจักร) : ${microorganism.kingdom || "-" }` }}</c-text>
                                <c-text>{{ `Phylum (ไฟลัม) : ${microorganism.phylum || "-"}` }}</c-text>
                                <c-text>{{ `Species (ชนิด): ${microorganism.species || "-"}` }}</c-text>
                            </c-stack> -->
                            <c-simple-grid  :columns="2" :spacingX="1" :spacingY="4" px="1rem" pb="4">
                                <c-text>{{
                                `Kingdom (อาณาจักร): `
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.kingdom || '-'"/>
                                </c-text>

                                <c-text>{{
                                `Phylum (ไฟลัม): `
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.phylum || '-'"/>
                                </c-text>

                                <c-text>{{
                                `Class (ชั้น):`
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.class || '-'"/>
                                </c-text>

                                <c-text>{{
                                `Order (อันดับ):`
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.order || '-'"/>
                                </c-text>

                                <c-text>{{
                                `Family (วงศ์):`
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.family || '-'"/>
                                </c-text>

                                <c-text>{{
                                `Genus (สกุล):`
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.genus || '-'"/>
                                </c-text>

                                <c-text>{{
                                `Species (ชนิด):`
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.species || ''"/>
                                </c-text>

                                <c-text>{{
                                `Subspecies (ชนิดย่อย):`
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.subspecies || ''"/>
                                </c-text>

                                <c-text>{{
                                `Variety (พันธุ์):`
                                }}</c-text>
                                <c-text class="data-text">
                                <SciNameComponent :sci_name="microorganism.variety || '-'"/>
                                </c-text>
                            </c-simple-grid>
                        </c-box>
                    </div>
                    </div>
                        
                    <!-- Column 2 -->
                    <div>        
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Location Remark (รายละเอียดแหล่งที่ค้นพบ)</c-text>
                            </c-box>
                            <c-box>
                                <c-text class="data-text">{{ microorganism.location_remarks || "-" }}</c-text>
                            </c-box>
                        </div> 
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Special characteristic (ลักษณะเด่นของจุลินทรีย์)</c-text>
                            </c-box>
                            <c-box>
                                <c-text class="data-text">{{ microorganism.special_characteristic || "-" }}</c-text>
                            </c-box>
                        </div> 
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Sampling by (ชื่อผู้เก็บตัวอย่าง)</c-text>
                            </c-box>
                            <c-box>
                                <c-text class="data-text">{{ formatHumanName(microorganism.sampling_by.name) || "-" }}</c-text>
                            </c-box>
                        </div> 
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Isolation by (ชื่อผู้แยกเชื้อ)</c-text>
                            </c-box>
                            <c-box>
                                <c-text class="data-text">{{ formatHumanName(microorganism.isolation_by.name) || "-" }}</c-text>
                            </c-box>
                        </div>
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Identification by (ผู้ระบุชนิด)</c-text>
                            </c-box>
                            <c-box>
                                <c-text class="data-text">{{ formatHumanName(microorganism.identification_by.name) || "-" }}</c-text>
                            </c-box>
                        </div>
                        <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Location(Province/District/Sub-district)</c-text>
                            </c-box>
                            <c-box v-if="microorganism.gps.length == 0">
                                <c-stack :spacing="1" class="data-text">
                                    <c-text>{{
                                    `จังหวัด: ${microorganism.province || "-"}`
                                    }}</c-text>
                                    <c-text>{{ `อำเภอ: ${microorganism.district || "-"}` }}</c-text>
                                    <c-text>{{
                                    `ตำบล: ${microorganism.sub_district || "-"}`
                                    }}</c-text>
                                </c-stack>
                            </c-box>
                            <c-box v-if="microorganism.gps.length > 0">
                            <c-stack :spacing="1">
                                <c-text
                                class="data-text"
                                v-for="location in microorganism.gps"
                                :key="location.id"
                                >
                                {{
                                    `${location.province || "-"} / ${location.district || "-"} / ${location.sub_district || "-"}`
                                }}
                                </c-text>
                            </c-stack>
                            </c-box>
                        </div>
                         <div class="pb-6">
                            <c-box>
                                <c-text class="font-bold">Contact person (ผู้ตั้งชื่อ/ผู้ค้นพบ)</c-text>
                            </c-box>
                            <c-box v-if="microorganism.founders.length == 0">
                                <c-text class="data-text">-</c-text>
                            </c-box>
                            <c-box v-if="microorganism.founders.length > 0">
                                <c-text
                                    class="data-text"
                                    v-for="(founder, index) in microorganism.founders"
                                    :key="index"
                                    >
                                    {{
                                        formatHumanName(founder.name)
                                    }}
                                </c-text>
                                <!-- <c-text>{{ microorganism.found_by || "-" }}</c-text> -->
                            </c-box>
                        </div>
                        <div class="pb-6">
                        <c-box>
                            <c-text class="font-bold">Contact for more detail (สอบถามรายละเอียดเพิ่มเติม)</c-text>
                        </c-box>
                        <c-box>
                            <c-text class="data-text">{{ formatHumanName(microorganism.contact_person.name) || "-" }} ติดต่อ. {{ microorganism.contact_person.phone_number || "-" }}</c-text>
                        </c-box>
                    </div>
                    </div>
                </c-simple-grid>
            </c-stack>
            <c-stack bgColor="white" my="0.75rem" py="6" px="4">
                <c-box px="1rem">
                    <c-text fontSize="4xl" class="font-extrabold header-text">Applications</c-text>
                </c-box>
                <c-simple-grid :columns="['1','1','2','2']" :spacing="2" px="2rem" class="text-xl md:text-2xl">
                    <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
                        <div>
                            <c-box>
                            <c-text class="font-bold header-text text-blue-600"
                                >Agriculture Application (ประโยชน์ทางการเกษตร)</c-text
                            >
                            </c-box>
                            <c-box>
                            <c-text class="data-text">{{
                                microorganism.industrial_application || "-"
                            }}</c-text>
                            </c-box>
                        </div>
                        </c-simple-grid>
                        <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
                        <div>
                            <c-box>
                            <c-text class="font-bold header-text text-blue-600"
                                >Agriculture application description
                                (รายละเอียดของประโยชน์ด้านการเกษตร)</c-text
                            >
                            </c-box>
                            <c-box>
                            <c-text class="data-text">{{
                                microorganism.agriculture_application_details || "-"
                            }}</c-text>
                            </c-box>
                        </div>
                        </c-simple-grid>
                    </c-simple-grid>

                    <div class=" border-b-2 border-gray-300 w-11/12 mx-auto" />

                    <c-simple-grid
                        :columns="['1', '1', '2', '2']"
                        :spacing="1"
                        px="2rem"
                        m="1.5rem"
                        class="text-xl md:info-text "
                    >
                        <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
                        <div>
                            <c-box>
                            <c-text class="font-bold header-text text-green-600"
                                >Industrial Application (ประโยชน์ทางอุตสาหกรรม)</c-text
                            >
                            </c-box>
                            <c-box>
                            <c-text class="data-text ">{{
                                microorganism.industrial_application || "-"
                            }}</c-text>
                            </c-box>
                        </div>
                        </c-simple-grid>
                        <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
                        <div>
                            <c-box>
                            <c-text class="font-bold header-text text-green-600"
                                >Industrial Application Description
                                (รายละเอียดของประโยชน์ด้านอุตสาหกรรม)</c-text
                            >
                            </c-box>
                            <c-box>
                            <c-text class="data-text">{{
                                microorganism.industrial_application_details || "-"
                            }}</c-text>
                            </c-box>
                        </div>
                        </c-simple-grid>
                    </c-simple-grid>

                    <div class=" border-b-2 border-gray-300 w-11/12 mx-auto" />

                    <c-simple-grid
                        :columns="['1', '1', '2', '2']"
                        :spacing="1"
                        px="2rem"
                        m="1.5rem"
                        class="text-xl md:info-text"
                    >
                        <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
                        <div>
                            <c-box>
                            <c-text class="font-bold header-text"
                                >Others Applications (ประโยชน์อื่น ๆ)</c-text
                            >
                            </c-box>
                            <c-box>
                            <c-text class="data-text">{{
                                microorganism.others_applications || "-"
                            }}</c-text>
                            </c-box>
                        </div>
                        </c-simple-grid>
                </c-simple-grid>
            </c-stack>

            <c-stack
            bgColor="white"
            my="0.75rem"
            py="6"
            px="4"
            >
            <c-box px="1rem">
                <c-text fontSize="4xl" class="font-extrabold header-text">Bibliography</c-text>
            </c-box>
            <div class="flex flex-col px-8 text-xl md:info-text">
                <c-simple-grid :columns="1" :spacing="1" px="2rem" py="1.5rem" class="">
                <div>
                    <c-box>
                    <c-text class="font-bold header-text">Keywords (คำสำคัญ)</c-text>
                    </c-box>
                    <c-box>
                    <c-text class="data-text">{{
                        microorganism.keyword || "-"
                    }}</c-text>
                    </c-box>
                </div>
                </c-simple-grid>                
            </div>
            
            </c-stack>
          </c-flex>
      </c-stack>
  </div>
</template>

<script>
import MicroorganismStore from '../../store/MicroorganismStore'
import UserStore from '../../store/User'
import SciNameComponent from '../../components/SciNameComponent.vue'
export default {
    components: {
        SciNameComponent
    },
    data() {
        return {
            microorganism: {},
            isLoading: true,
            AC: 'GUEST',
        }
    },
    async created() {
        await this.fetchMicroorganism()
    },
    methods: {
        async fetchMicroorganism() {
            this.isLoading = true
            this.AC = UserStore.getters.isAuthen ? 'AUTHORIZED' : 'GUEST'
            if (this.AC === 'AUTHORIZED') {
                this.$router.push(`/microorganism/${this.$route.params.id}`)
            }
            let res = await MicroorganismStore.dispatch('fetchMicroorganismByID', this.$route.params.id)
            if (res.status === 200) {
                this.microorganism = MicroorganismStore.getters.getFetchedMicroorganism
                this.isLoading = false
            }
        },
        formatHumanName(humanName){
            humanName = humanName.toLowerCase();
            const arr = humanName.split(" ");

            //loop through each element of the array and capitalize the first letter.


            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

            }
            return arr.join(" ");
        },
    }
}
</script>

<style>

</style>