<template>
  <div style="flex: 1">
      <loading v-if="isLoading" />
      <c-stack mt="2rem" :spacing="5" my="2rem">
        <c-box mx="2rem">
            <c-text fontSize="2xl">Change Password</c-text>
            <c-text fontSize="sm" class="text-red-600">* Password must be at least 8 characters</c-text>
        </c-box>
        <c-form-control>
            <c-stack :spacing="5">
                <c-box w="80%" mx="2rem">
                    <c-form-label>Old Password</c-form-label>
                    <c-input type="password" placeholder="Old Password" v-model="old_password"/>
                </c-box>
                <c-box w="80%" mx="2rem">
                    <c-form-label>New Password</c-form-label>
                    <c-input type="password" placeholder="New Password" v-model="new_password"/>
                </c-box>
                <c-box w="80%" mx="2rem">
                    <c-form-label>Confirm Password</c-form-label>
                    <c-input type="password" placeholder="Confirm Password" v-model="confirm_password"/>
                </c-box>
                <c-box textAlign="right" w="80%" mx="2rem">
                    <c-button @click="changePassword" :disabled="!toggleSaveBtn">Save</c-button>
                </c-box>
            </c-stack>
        </c-form-control>
      </c-stack>
  </div>
</template>

<script>
import axios from 'axios'
import Header from '../../helpers/Header'
import Alert from '../../helpers/Alert'
import Loading from '../misc/Loading.vue'

export default {
  components: { Loading },
    data() {
        return {
            old_password: "",
            new_password: "",
            confirm_password: "",
            isLoading: false,
            toggleSaveBtn: false,
        }
    },
    watch: {
        old_password: function (val, oldVal) {
            this.validate()
        },
        new_password: function (val, oldVal) {
            this.validate()
        },
        confirm_password: function (val, oldVal) {
            this.validate()
        },
    },
    methods: {
        showToast() {
            this.$toast({
                title: 'รหัสผ่านไม่ตรงกัน',
                description: "กรอกรหัสผ่านไม่ตรงกัน กรุณาตรวจสอบใหม่อีกครั้ง",
                status: "error",
                duration: 10000,
            })
        },
        async changePassword() {
            this.isLoading = true;
            if (this.new_password === this.confirm_password) {
                try {
                    const header = Header.getHeaders({Accept: 'application/json'})
                    let body = {
                        password_old: this.old_password,
                        password_new: this.new_password,
                        password_new_confirmation: this.new_password,
                    }
                    const res = await axios.post(`/auth/reset_password`, body, header)
                    if (res.status === 200) {
                        this.isLoading = false;
                        this.$router.push('/account');
                    }
                } catch (error) {
                    this.isLoading = false;
                    if (error.response.status == 401) {
                        let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
                        if (isOk) {
                            localStorage.removeItem("biodatabase")
                            this.$router.push({
                            path: "/login",
                            })
                            window.location.reload(true);
                        }
                    }
                    await Alert.window('error', 'Cannot change password', error.response.data.password_new[0])
                }
            } else {
                this.showToast();
            }
        },
        validate() {
            this.toggleSaveBtn = this.old_password.length > 0 && this.new_password.length >= 8 && this.confirm_password.length >= 8
        }
    }
}
</script>

<style>

</style>