<template>
  <div class="mx-8">
    <loading v-if="isLoading" />
    <c-heading 
    class="text-4xl py-8 tracking-widest">LATEST UPDATES</c-heading>
    <c-box class="bg-white" border-width="1px" shadow=md>
      <carousel :per-page="1" :speed="1500" :autoplay="true" :loop="true" :autoplayTimeout="5000">
        <slide v-for="item in data" :key="item.id">
          <microorganism-card 
          :microorganismName="item.scientific_name"
          :microorganismType="item.microorganism_type"
          :source="item.source"
          :keyword="item.keyword"
          :recorder="formatHumanName(item.recorder.name)"
          />
        </slide>
      </carousel>
    </c-box>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import MicroorganismCard from "./card/MicroorganismCard.vue";

export default {
  components: {
    Carousel,
    Slide,
    MicroorganismCard
  },
  props: {
    data : Array,
  },
  created(){
    console.log(this.data);
  },
  data() {
    return {
      items: [],
      isLoading: false,
    }
  },
  methods:{
    formatHumanName(humanName){
        humanName = humanName.toLowerCase();
        const arr = humanName.split(" ");

        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  
        }
        return arr.join(" ");
    },
  }
};
</script>

<style>
</style>