import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      agricuturalApplication: [],
      industrialApplication: [],
      foundStatus: [],
  },
  getters: {
      getAgricuturalApplication: (state) => state.agricuturalApplication,
      getIndustrialApplication: (state) => state.industrialApplication,
      getFoundStatus: (state) => state.foundStatus,
  },
  mutations: {
      setAgricuturalApplication(state, data) {
          state.agricuturalApplication = data;
      },
      setIndustrialApplication(state, data) {
          state.industrialApplication = data;
      },
      setFoundStatus(state, data) {
          state.foundStatus = data;
      }
  },
  actions: {
    //   async fetchAgriculturalApps({ commit }) {
    //       try {
    //           let res = await axios.get('/agriculturalApplication');
    //           if (res.status === 200) {
    //               commit('setAgricuturalApplication', res.data);
    //               return {
    //                   status: res.status
    //               }
    //           }
    //       } catch (error) {
    //         if (error.response.status == 401) {
    //             let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
    //             if (isOk) {
    //               localStorage.removeItem("biodatabase")
    //               this.$router.push({
    //                 path: "/login",
    //               })
    //               window.location.reload(true);
    //             }
    //           }
    //           throw error;
    //       }
    //   },
      async fetchIndustrialApps({ commit }) {
          try {
              let res = await axios.get('/industrialApplications');
              if (res.status === 200) {
                  commit('setIndustrialApplication', res.data);

                  return {
                      status: res.status
                  }
              }
          } catch (error) {
            if (error.response.status == 401) {
                let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
                if (isOk) {
                  localStorage.removeItem("biodatabase")
                  this.$router.push({
                    path: "/login",
                  })
                  window.location.reload(true);
                }
              }
              throw error;
          }
      },
      async fetchDropDown({ commit }) {
          try {
              let res = await axios.get('/dropdown');
              if (res.status === 200) {
                  commit('setAgricuturalApplication', res.data.agricultural);
                  commit('setIndustrialApplication', res.data.industrial);
                  commit('setFoundStatus', res.data.found_status);

                  return {
                      data : res.data,
                      status: res.status
                  }
              }
          } catch (error) {
            if (error.response.status == 401) {
                let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
                if (isOk) {
                  localStorage.removeItem("biodatabase")
                  this.$router.push({
                    path: "/login",
                  })
                  window.location.reload(true);
                }
              }
              throw error;
          }
      },
  },
  modules: {
  }
})
