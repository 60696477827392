import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import province from '../../public/mock/province.json';
import district from '../../public/mock/district.json';
import sub_district from '../../public/mock/sub_district.json';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      province: [],
      district: [],
      sub_district: []
  },
  getters: {
      getProvince: (state) => state.province,
      getDistrict: (state) => state.district,
      getSubDistrict: (state) => state.sub_district
  },
  mutations: {
      setProvince(state, obj) {
          state.province = obj
      },
      setDistrict(state, obj) {
          state.district = obj
      },
      setSubDistrict(state, obj) {
          state.sub_district = obj
      }
  },
  actions: {
      async fetchProvince({ commit }) {
        try {
            // let res = await axios.get(province);
            if (province) {
                commit('setProvince', province)
                return {
                    status: 200
                }
            }
          } catch (error) {
              return {
                  status: error
              }
          }
      },
      async fetchDistrict({ commit }, p) {
        try {
            // let res = await axios.get('/districts');
            if (district) {
                let filteredDistrict = district.filter(item => item.PROVINCE_ID === parseInt(p))
                commit('setDistrict', filteredDistrict)
                return {
                    status: 200

                }
            }
          } catch (error) {
              return {
                  status: error
              }
          }
      },
      async fetchSubDistrict({ commit }, d) {
        try {
            // let res = await axios.get('/subDistricts');
            if (sub_district) {
                let filteredSubDistrict = sub_district.filter(item => item.DISTRICT_ID === parseInt(d))
                commit('setSubDistrict', filteredSubDistrict)
                return {
                    status: 200
                }
            }
          } catch (error) {
              return {
                  status: error
              }
          }
      }
  },
  modules: {
  }
})
