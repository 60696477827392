import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import Header from "@/helpers/Header";
import Alert from "@/helpers/Alert"
import router from "@/router"
Vue.use(Vuex);

let auth_key = process.env.VUE_APP_AUTH_KEY;
const user_id = 0;

export default new Vuex.Store({
  state: {
    user: "",
    jwt: "",
    users: [],
    isAuthen: JSON.parse(localStorage.getItem(auth_key)) ? true : false,
    toggleMenu: 0,
    userImage: "",
  },
  getters: {
    user: (state) => state.user,
    jwt: (state) => state.jwt,
    users: (state) => state.users,
    isAuthen: (state) => state.isAuthen,
    getToggleMenu: (state) => state.toggleMenu,
    getUserImage: (state) => state.userImage,
  },
  mutations: {
    loginSuccess(state, { user, jwt }) {
      state.user = user;
      state.jwt = jwt;
      state.isAuthen = true;
    },
    logoutSuccess(state) {
      state.user = "";
      state.jwt = "";
      state.isAuthen = false;
      state.isAdmin = false;
    },
    userStore(state, { user }) {
      state.user = user;
      state.isAuthen = true;
    },
    fetchUser(state, users) {
      state.users = users;
    },
    setToggleMenu(state, index) {
      state.toggleMenu = index;
    },
    setUserImage(state, url) {
      state.userImage = url;
    }
  },
  actions: {
    async login({ commit }, payload) {
      try {
        let res = await Axios.post("/auth/login", payload);
        let body = {
          user: res.data.user,
          jwt: res.data.access_token,
        };
        localStorage.setItem(auth_key, JSON.stringify(res.data));
        commit("loginSuccess", body);
        return res;
      } catch (error) {
        throw error;
      }
    },
    async logout({ commit }) {
      let header = Header.getHeaders();
      try {
        let res = await Axios.post("/auth/logout", null, header);
        localStorage.removeItem(auth_key);
        commit("logoutSuccess");
        return res;
      } catch (error) {
        throw error;
      }
    },
    async register({ commit }, payload) {
      let header = Header.getHeaders({
        "Content-Type": "multipart/form-data",
      });
      try {
        let res = await Axios.post("/auth/register", payload, header);
        return res;
      } catch (error) {
        throw error;
      }
    },
    async getMe({ commit }) {
      try{
        let header = Header.getHeaders({ Accept: "application/json" });
        let res = await Axios.post("/auth/me", null, header);
        commit("userStore", res.data);
        this.user_id = res.data.id;
        return res;
        
      }catch(error){
        if (error.response.status == 401) {
          let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
          if (isOk) {
            localStorage.removeItem("biodatabase")
            router.push({
              path: "/login",
            })
            window.location.reload(true);
          }
        } 
      }
    },
    async getUser({ commit }, slug) {
      let header = Header.getHeaders({ Accept: "application/json" });
      let res = await Axios.get(`/user/${slug}`, header);

      return res;
    },
    async getHistory({ commit }, payload ) {
      const auth_key = process.env.VUE_APP_AUTH_KEY;
      let auth = JSON.parse(localStorage.getItem(auth_key));
      try {
        let res = await Axios.get("auth/my/log", { params: payload , headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth.access_token}`,
          },
        });
        
        return res;
      }catch(error){
        if (error.response.status == 401) {
          let isOk = await Alert.window("error", "ํYour token has expired", "please login again");
          if (isOk) {
            localStorage.removeItem("biodatabase")
            router.push({
              path: "/login",
            })
            window.location.reload(true);
          }
        }
      }
    },

    async updateProfile({ commit }, payload) {
      try {
        let header = Header.getHeaders({
          Accept: 'application/json',
        })
        const res = await Axios.post('/auth/update', payload, header);
        if (res.status == 201) {
          commit('setUserImage', res.data.user.profile);
          return {
            status: 201
          }
        }
      } catch (error) {
        throw error;
      }
    },
    toggleMenu({ commit }, index) {
      commit('setToggleMenu', index);
    }
  },
  modules: {},
});
