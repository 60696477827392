<template>
  <c-tabs varaint="enclosed-colored" variant-color="gray" is-fitted>
    <c-tab-list>
      <c-tab>BASIC SEARCH</c-tab>
      <c-tab>ADVANCE SEARCH</c-tab>
    </c-tab-list>

    <c-tab-panels>
      <c-tab-panel>
        <basic-search-vue ref="basic"/>
      </c-tab-panel>
      <c-tab-panel>
        <advance-search ref="advance"/>
      </c-tab-panel>
    </c-tab-panels>
    <c-box textAlign="right " class="mt-4">
        <c-button @click="search">
          <c-icon name="search" mr="0.5rem" />Search
        </c-button>
      </c-box>
  </c-tabs>
</template>

<script>
import BasicSearchVue from "./search/BasicSearch.vue";
import AdvanceSearch from "./search/AdvanceSearch.vue"

export default {
  components: {
    BasicSearchVue,
    AdvanceSearch,
  },
  methods: {
    search() {
      let body = {};
      if (this.$refs.basic.$options.parent.isSelected) {
        body = this.$refs.basic.search();
      } else {
        body = this.$refs.advance.search();
      }
      this.$parent.search(body);
    },
  }
};
</script>

<style>
</style>