<template>
  <c-box>
    <loading v-if="isLoading" />
    <c-text
      fontStyle="italic"
      textDecoration="underline"
      fontSize="4xl"
      class="mb-6"
      >General Information</c-text
    >
    <c-form-control>
      <div class="flex flex-col space-y-5">
        <div id="full_sci_name">
          <c-form-label
            >Full Scientific Name (ชื่อวิทยาศาสตร์แบบเต็ม)
            <span class="text-2xl text-red-600">*</span></c-form-label
          >
          <c-input
            id="m-full-scientific-name"
            placeholder="Full Scientific Name"
            v-model="general_info.full_scientific_name"
            v-if="!$props.validator.hasOwnProperty('full_scientific_name')"
          />
          <!-- <c-box v-if="general_info.full_scientific_name.length === 0">
                  <c-input placeholder="Full Scientific Name" v-model="general_info.full_scientific_name" class="border-2 border-red-500"/>
                  <c-form-helper-text textColor="red.300">กรุณากรอกชื่อวิทยาศาสตร์</c-form-helper-text>
              </c-box> -->
        </div>
        <div id="sci_name">
          <c-form-label
            >Scientific Name (ชื่อวิทยาศาสตร์)<span
              class="text-2xl text-red-600"
              >*</span
            ></c-form-label
          >
          <c-input
            id="m-scientific-name"
            placeholder="Scientific Name"
            v-model="general_info.scientific_name"
            v-if="!$props.validator.hasOwnProperty('scientific_name')"
          />
          <c-box v-if="$props.validator.hasOwnProperty('scientific_name')">
            <c-input
              id="m-scientific-name"
              placeholder="Scientific Name"
              v-model="general_info.scientific_name"
              class="border-2 border-red-500"
            />
            <c-form-helper-text textColor="red.300"
              >กรุณากรอกชื่อวิทยาศาสตร์</c-form-helper-text
            >
          </c-box>
        </div>
        <div class="flex flex-row justify-between items-start">
          <div class="flex flex-col space-y-5 w-2/5">
            <div id="ku_code">
              <c-form-label
                >KU (KU code)
                <span class="text-2xl text-red-600">*</span></c-form-label
              >
              <c-input
                id="m-ku-code"
                placeholder="KU code"
                v-model="general_info.ku_code"
              />
            </div>
            <div id="other_code">
              <c-form-label>Other Microbial Code (i.e., อพ.สธ.)</c-form-label>
              <c-input
                id="m-other-microbial-code"
                placeholder="Other Microbial Code (i.e., อพ.สธ.)"
                v-model="general_info.other_microbial_code"
              />
            </div>
            <div id="isolate_code">
              <c-form-label
                >Isolate code (รหัสไอโซเลต)<span class="text-2xl text-red-600"
                  >*</span
                ></c-form-label
              >
              <c-input
                id="m-isolate-code"
                placeholder="Isolate Code"
                v-model="general_info.isolate_code"
              />
            </div>
            <div id="strain_code">
              <c-form-label>Strain code (รหัสสายพันธุ์)</c-form-label>
              <c-input
                id="m-strain-code"
                placeholder="Strain Code"
                v-model="general_info.strain_code"
              />
            </div>
            <div id="microorganism_type">
              <c-form-label
                >Microorganism type (ประเภทของจุลินทรีย์)<span
                  class="text-2xl text-red-600"
                  >*</span
                ></c-form-label
              >
              <c-select
                id="m-micro-type"
                v-if="general_info.microorganism_type !== undefined"
                v-model="general_info.microorganism_type"
              >
                <option value="" style="display: none">
                  Microorganism Type
                </option>
                <option
                  v-for="(option, index) in type"
                  :key="index"
                  :value="option"
                  :selected="option === general_info.microorganism_type"
                >
                  {{ option }}
                </option>
              </c-select>
              <c-box
                v-if="$props.validator.hasOwnProperty('microorganism_type')"
              >
                <c-select
                  v-model="general_info.microorganism_type"
                  class="border-2 border-red-500 rounded-lg"
                >
                  <option value="" style="display: none">
                    Microorganism Type
                  </option>
                  <option
                    v-for="(option, index) in type"
                    :key="index"
                    :value="option"
                    :selected="option === general_info.microorganism_type"
                  >
                    {{ option }}
                  </option>
                </c-select>
                <c-form-helper-text textColor="red.300"
                  >กรุณาเลือกประเภทของจุลินทรีย์</c-form-helper-text
                >
              </c-box>
            </div>
            <div v-if="general_info.microorganism_type === `bacteria`">
              <c-form-label
                >Microorganism Subtype ( ประเภทย่อยของจุลินทรีย์)</c-form-label
              >
              <c-select
                id="m-micro-subtype"
                v-model="general_info.microorganism_subtype"
              >
                <option value="" style="display: none">
                  Microorganism Subtype
                </option>
                <option
                  v-for="(option, index) in subType"
                  :key="index"
                  :value="option"
                  :selected="option === general_info.microorganism_subtype"
                >
                  {{ option }}
                </option>
              </c-select>
            </div>
          </div>
          <div class="flex flex-col space-y-5 w-2/5 mt-1">
            <div id="higher_taxon">
              <c-form-label>Higher Taxon (ลำดับอนุกรมวิธาน)</c-form-label>
              <c-input
                id="m-higher-taxon"
                placeholder="Higher Taxon"
                v-model="general_info.higher_taxon"
              />
            </div>
            <div id="kingdom">
              <c-form-label
                >Kingdom (อาณาจักร)<span class="text-2xl text-red-600"
                  >*</span
                ></c-form-label
              >
              <c-input
                id="m-kingdom"
                placeholder="Kingdom"
                v-model="general_info.kingdom"
              />
            </div>
            <div id="phylum">
              <c-form-label
                >Phylum (ไฟลัม)
                <span class="text-2xl text-red-600">*</span></c-form-label
              >
              <c-input
                id="m-phylum"
                placeholder="Phylum"
                v-model="general_info.phylum"
              />
            </div>
            <div id="class">
              <c-form-label
                >Class (ชั้น)<span class="text-2xl text-red-600"
                  >*</span
                ></c-form-label
              >
              <c-input
                id="m-class"
                placeholder="Class"
                v-model="general_info.class"
              />
            </div>
            <div id="order">
              <c-form-label
                >Order (อันดับ)<span class="text-2xl text-red-600"
                  >*</span
                ></c-form-label
              >
              <c-input
                id="m-order"
                placeholder="Order"
                v-model="general_info.order"
              />
            </div>
            <div id="family">
              <c-form-label
                >Family (วงศ์)<span class="text-2xl text-red-600"
                  >*</span
                ></c-form-label
              >
              <c-input
                id="m-family"
                placeholder="Family"
                v-model="general_info.family"
              />
            </div>
            <div id="genus">
              <c-form-label
                >Genus (สกุล)<span class="text-2xl text-red-600"
                  >*</span
                ></c-form-label
              >
              <c-input
                id="m-genus"
                placeholder="Genus"
                v-model="general_info.genus"
              />
            </div>
            <div id="species">
              <c-form-label>Species (ชนิด)</c-form-label>
              <c-input
                id="m-species"
                placeholder="Species"
                v-model="general_info.species"
              />
            </div>
            <div id="subspecies">
              <c-form-label>Subspecies (ชนิดย่อย)</c-form-label>
              <c-input
                id="m-sub-species"
                placeholder="Subspecies"
                v-model="general_info.subspecies"
              />
            </div>
            <div id="variety">
              <c-form-label>Variety (พันธุ์)</c-form-label>
              <c-input
                id="m-variety"
                placeholder="Variety"
                v-model="general_info.variety"
              />
            </div>
          </div>
        </div>
        <div id="nomen_code">
          <c-form-label
            >Nomenclatural Code
            (หลักเกณฑ์ในการตั้งชื่อวิทยาศาสตร์)</c-form-label
          >
          <c-textarea
            id="m-nomenclatural-code"
            placeholder="Nomenclatural Code"
            v-model="general_info.nomenclatural_code"
          />
        </div>
        <div id="synonym">
          <c-form-label>Synonym (ชื่อพ้อง)</c-form-label>
          <c-input
            id="m-synonym"
            placeholder="Synonym"
            v-model="general_info.synonym"
          />
        </div>
        <div id="colony_morph">
          <c-form-label
            >Colony Morphology (ลักษณะทางสัณฐานวิทยาของโคโลนี)</c-form-label
          >
          <c-textarea
            id="m-colony"
            placeholder="Colony Morphology"
            v-model="general_info.colony_morphology"
            v-bind:value="general_info.colony_morphology"
          ></c-textarea>
        </div>
        <div id="cell_morph">
          <c-form-label
            >Cell Morphology (ลักษณะทางสัณฐานวิทยาของเซลล์)</c-form-label
          >
          <c-textarea
            id="m-cell"
            placeholder="Cell Morphology"
            v-model="general_info.cell_morphology"
            v-bind:value="general_info.cell_morphology"
          ></c-textarea>
        </div>
      </div>
    </c-form-control>
  </c-box>
</template>

<script>
import axios from "axios";
import MicroorganismStore from "../../store/MicroorganismStore";
import ApplicationStore from "../../store/ApplicationStore";

export default {
  props: ["validator"],
  data() {
    return {
      general_info: {
        full_scientific_name: "",
        scientific_name: "",
        ku_code: "",
        other_microbial_code: "",
        isolate_code: "",
        strain_code: "",
        microorganism_type: "",
        microorganism_subtype: "",
        nomenclatural_code: "",
        synonym: "",
        higher_taxon: "",
        kingdom: "",
        phylum: "",
        class: "",
        order: "",
        family: "",
        genus: "",
        species: "",
        subspecies: "",
        variety: "",
        colony_morphology: "",
        cell_morphology: "",
      },
      isLoading: true,
      type: [],
      subType: [],
    };
  },
  created() {
    this.fetchType();
    // fetch from API
    let cmd = this.$route.name.split(" ")[0];
    if (cmd === "edit") {
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        for (const key of Object.keys(this.general_info)) {
          this.general_info[key] = microorganism[key];
        }
      }
    }
    // case: next, prev
    else if (cmd === "add") {
      // new object
      if (MicroorganismStore.getters.getClearForm) {
        MicroorganismStore.dispatch("clearForm");
      }
      let microorganism = MicroorganismStore.getters.getMicroorganism;
      if (Object.keys(microorganism).length !== 0) {
        this.general_info = { ...microorganism };
      }
      this.toggleStepTwo();
      MicroorganismStore.dispatch("toggleClearForm", false);
    }
  },
  watch: {
    general_info: {
      handler(e) {
        if (this.general_info.microorganism_type !== `bacteria`) {
          this.general_info.microorganism_subtype = "";
        }
        MicroorganismStore.commit("updateInformation", this.general_info);
      },
      deep: true,
    },
    "general_info.full_scientific_name": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.scientific_name": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.microorganism_type": function (val, oldVal) {
      if (val === "bacteria") {
        this.general_info.microorganism_subtype = val;
      } else {
        this.general_info.microorganism_subtype = "";
      }
      this.toggleStepTwo();
    },
    "general_info.microorganism_subtype": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.ku_code": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.isolate_code": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.kingdom": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.phylum": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.class": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.order": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.family": function (val, oldVal) {
      this.toggleStepTwo();
    },
    "general_info.genus": function (val, oldVal) {
      this.toggleStepTwo();
    },
  },
  methods: {
    toggleStepTwo() {
      let value = {
        cmd: "stepTwo",
        full_scientific_name: this.general_info.full_scientific_name,
        scientific_name: this.general_info.scientific_name,
        type: this.general_info.microorganism_type,
        subType: this.general_info.microorganism_subtype,
        ku_code: this.general_info.ku_code,
        isolate_code: this.general_info.isolate_code,
        kingdom: this.general_info.kingdom,
        phylum: this.general_info.phylum,
        class: this.general_info.class,
        order: this.general_info.order,
        family: this.general_info.family,
        genus: this.general_info.genus,
      };
      this.$emit("toggleStepTwo", value);
    },
    async fetchType() {
      try {
        this.isLoading = true;
        let res = await ApplicationStore.dispatch("fetchDropDown");
        // let subTypeRes = await axios.get('/microorganismSubtypes');

        if (res.status === 200) {
          this.type = res.data.microorganism_type;
          this.subType = res.data.microorganism_Subtype;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 401) {
          let isOk = await Alert.window(
            "error",
            "ํYour token has expired",
            "please login again"
          );
          if (isOk) {
            localStorage.removeItem("biodatabase");
            this.$router.push({
              path: "/login",
            });
            window.location.reload(true);
          }
        }
      }
    },
  },
};
</script>

<style>
</style>
