import { render, staticRenderFns } from "./ProfileSetting.vue?vue&type=template&id=73212c00&"
import script from "./ProfileSetting.vue?vue&type=script&lang=js&"
export * from "./ProfileSetting.vue?vue&type=script&lang=js&"
import style0 from "./ProfileSetting.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CDivider: require('@chakra-ui/vue').CDivider, CInput: require('@chakra-ui/vue').CInput, CSelect: require('@chakra-ui/vue').CSelect, CSimpleGrid: require('@chakra-ui/vue').CSimpleGrid, CButton: require('@chakra-ui/vue').CButton, CStack: require('@chakra-ui/vue').CStack})
