<template>
  <div v-html="formatName()"></div>
</template>

<script>
export default {
    props: ['sci_name'],
    methods:{
      formatName(){
        if(this.sci_name){
          var exceptionWord = ['sp.' , 'aff.', 'subsp.'];
          let textArray = this.sci_name.toLowerCase().split(" ");
          console.log(textArray)
          if(textArray.length == 1){
            if(!exceptionWord.includes(this.sci_name)){
              let newSciname = this.sci_name.charAt(0).toUpperCase() + this.sci_name.slice(1);
              return newSciname.italics();
            }

          }
          textArray.forEach((element, index) => {
            if(!exceptionWord.includes(element)){
              if (index == 0) {
                textArray[index] = textArray[index].charAt(0).toUpperCase() + textArray[index].slice(1);
              }
              textArray[index] = textArray[index].italics()
            }
          });
          let newSciname = textArray.join(' ');
          // newSciname = newSciname.charAt(3).toUpperCase() + newSciname.slice(4);
          return newSciname;

        }
        return '-';

    },
    }
}
</script>

<style>

</style>