<template>
  <div>
    <div class="relative">
      <carousel
        :autoplay="true"
        :adjustableHeight="true"
        :perPage="1"
        :navigationEnabled="false"
        :speed="1500"
        :loop="true"
        :paginationEnabled="false"
        class="overflow-hidden"
        style="height: 75vh"
      
      >
        <slide v-for="(image, idx) in images" :key="idx">
          <img
            style="width: 100%;  object-fit: contain; margin: auto;filter: blur(2px); filter: brightness(.5);"
            :src="`${getImgUrl(image)}`"
          />
        </slide>
      </carousel>
      <div class="absolute  left-0 right-0 top-1/3 bottom-0">
        <div
          class="
            text-center text-4xl
            sm:text-4xl
            md:text-5xl
            lg:text-6xl
            xl:text-6xl
          "
        >
          <c-text color="white"> WELCOME </c-text>
          <c-text color="white"> KU BIORESOURCES ONLINE DATABASE </c-text>
          <router-link to="/database">
            <c-button variant-color="blue" size="lg">EXPLORE</c-button>
          </router-link>
        </div>
      </div>
    </div>
    
    
    <c-stack
      alignItems="center"
      class="text-center"
      :spacing="5"
      bgColor="white"
      py="4rem"
    >
      <c-heading>Bioresources Database</c-heading>
      <c-text fontSize="2xl"
        >Department of Microbiology, Faculty of Science</c-text
      >
      <c-text fontSize="2xl">Kasetsart University</c-text>
    </c-stack>
    
    <div class="flex space-x-14 justify-center mt-12">
      <!-- <div>
        <c-text fontSize="5xl">{{ totalrecords }}</c-text>
        <c-text textAlign="center">SPECIMENS</c-text>
      </div> -->
      <div>
        <c-text fontSize="5xl">{{ totalrecords }}</c-text>
        <c-text textAlign="center">RECORDS</c-text>
      </div>
    </div>
    <div class="flex justify-center items-center mt-8 space-x-14">
      <div class="text-center">
          <c-text fontSize="5xl">{{ data.searchCount }}</c-text>
          <c-text textAlign="center">SEARCH COUNTS</c-text>
      </div>
      <div class="text-center">
        <c-text fontSize="5xl">{{ data.viewCount }}</c-text>
        <c-text textAlign="center">VIEW COUNTS</c-text>
      </div>
    </div>
    <div class="container mx-auto my-8 space-y-8 px-4">
      <!-- <search-tabs></search-tabs> -->
      <latest-update :data="data.latest_micro"></latest-update>
      <div class="flex flex-wrap gap-8 justify-center"
        v-if="isAuthen"
      >
        <div v-for="(micro_type, index) in data.micro_types" :key="index" class="text-center">
          <microorganism-type :type="micro_type.name" />
        </div>
      </div>

      <c-stack alignItems="center">
        <c-heading
          letterSpacing="0.1rem"
          :fontSize="['xl', '2xl', '3xl', '4xl']"
          class="text-center"
          >Number of Microorganisms</c-heading
        >
      </c-stack>
      <div class="flex justify-center  w-full " style="height: 100;">
        <canvas
          id="micro-chart"
          width="600" height="300"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
// import SearchTabs from "../components/SearchTabs.vue";
import LatestUpdate from "../components/LatestUpdate.vue";
import MicroorganismType from "../components/card/MicroorganismType.vue";
import MicroorganismStore from "../store/MicroorganismStore";
import UserStore from "../store/User";
import axios from "axios";
import { Chart } from "chart.js";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    // SearchTabs,
    LatestUpdate,
    MicroorganismType,
    Carousel,
    Slide,
  },
  data() {
    return {
      data: [],
      latestUpdate: [],
      microdata: {},
      microtypes: [],
      totalrecords: 0,
      isAuthen: false,
      images: [
        'A-Actinobacteria',
        'A-Bacteria',
        'A-Microalgae',
        'A-Microbes',
        'A-Molds',
        'A-Mushrooms',
        'A-Yeasts',
      ]
    };
  },
  created() {
    UserStore.dispatch("toggleMenu", 0);
    this.getHomePageData();
    this.isAuthen = UserStore.getters.isAuthen;
  },
  methods: {
    async getHomePageData() {
      let res = await axios.get(`/home`);
      if (res.status === 200) {
        this.data = res.data;
        console.log(this.data);
        this.latestUpdate = this.data.latest_micro;
        this.totalrecords = this.data.total_record;
        this.microtypes = this.getMicroTypes(this.data.micro_types);
        this.data.micro_types.forEach((element) => {
          this.microdata[element.name] = element.count;
        });
        this.drawCanvas();
      }
    },
    drawCanvas() {
      const data = {
        labels: this.microtypes,
        datasets: [
          {
            label: "Microorganisms",
            data: Object.values(this.microdata),
            backgroundColor: [
              "rgb(253, 127, 111)",
              "rgb(126, 176, 213)",
              "rgb(255, 181, 90)",
              "rgb(139, 211, 199)",
              "rgb(190, 185, 219)"
            ],
          },
        ],
      };
      const ctx = document.getElementById("micro-chart");
      new Chart(ctx, {
        type: "pie",
        data: data,
        options: {
          responsive: false,
          plugins: {
            legend: {
              position: "right",
              labels: {
                font: {
                  size: 18,
                  family: "Mitr"
                },
                padding: 20,
              },
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  return context.label
                }
              }
            }
          },
        },
      });
    },
    getMicroTypes(micro_types){
      return micro_types.map(elem => `${elem.name.charAt(0).toUpperCase()}${elem.name.slice(1)} - ${elem.count} record(s)`)
    },
    getImgUrl(pet) {
      var images = require.context('../assets/cover-pictures', false, /\.png$/)
      return images('./' + pet + ".png")
    }

  },
};
</script>

<style></style>
