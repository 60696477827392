<template>
  <div>
    <div class="relative">
      <carousel
        :autoplay="true"
        :adjustableHeight="true"
        :perPage="1"
        :navigationEnabled="false"
        :speed="1500"
        :loop="true"
        :paginationEnabled="false"
        class="overflow-hidden"
        style="height: 75vh"
      
      >
        <slide v-for="(image, idx) in images" :key="idx">
          <img
            style="width: 100%;  object-fit: contain; margin: auto;filter: blur(2px); filter: brightness(.5);"
            :src="`${getImgUrl(image)}`"
          />
        </slide>
      </carousel>
      <div class="absolute  left-0 right-0 top-1/3 bottom-0">
        <div
          class="
            text-center text-4xl
            sm:text-4xl
            md:text-5xl
            lg:text-6xl
            xl:text-6xl
          "
        >
          <div class="text-center">
            <c-text color="white" fontSize="6xl">
                DATABASE
            </c-text>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto my-8 space-y-8 px-8">
        <search-tabs />
        <data-table ref="datatable"></data-table>
    </div>
  </div>
</template>

<script>
import DataTable from "../components/DataTable.vue"
import SearchTabs from "../components/SearchTabs.vue";
import UserStore from "../store/User"
import { Carousel, Slide } from "vue-carousel";

export default {
  data() {
    return {
      sizes: ['sm', 'md', 'lg' ,'aaaaa','bbb','aa'],
      resercher : [],
      images: [
        'A-Actinobacteria',
        'A-Bacteria',
        'A-Microalgae',
        'A-Microbes',
        'A-Molds',
        'A-Mushrooms',
        'A-Yeasts',
      ]
    }
  },
    components: {
        DataTable,
        SearchTabs,
        Carousel,
        Slide,
    },
    created() {
      UserStore.dispatch('toggleMenu', 1)
    },
    methods: {
      search(search) {
        this.$refs.datatable.search(search);
      },
      searchTest(input) {
            if (input.length < 1) { return this.sizes.slice(Math.max(this.sizes.length - 5, 1)) }
            var resultList = this.sizes.filter(a => {
                return a.startsWith(input.toLowerCase())
            })
            return resultList
      },
      getResultValue(result) {
          return result
      },
      onSubmit(result) {
        if(result){
          if(!this.resercher.includes(result))
          this.resercher.push(result);

        }
      },
      closeBtn(param1){
        var findIndex = this.resercher.indexOf(param1);
        if (findIndex !== -1) {
            this.resercher.splice(findIndex, 1);
        }
      },
      getImgUrl(pet) {
        var images = require.context('../assets/cover-pictures', false, /\.png$/)
        return images('./' + pet + ".png")
      }

    }
}
</script>

<style>

</style>