<template>
  <div>
    <loading v-if="isLoading" />
    <c-stack v-if="!isLoading" :spacing="5" align="center" my="2rem">
      <c-flex flexDirection="column" width="80%" justify="center">
        <c-flex
          flexDirection="row"
          justify="end"
          v-if="AC === 'AUTHORIZED' "
        >
          <div>
            <c-button
              v-if="can_edit"
              size="lg"
              m=".75rem"
              variant-color="yellow.500"
              variant="outline"
              class="text-yellow-600 hover:bg-yellow-50"
              @click="editMicroorganism"
            >
              <c-icon name="edit" />
              <span class="hidden md:inline-block ml-2">Edit</span></c-button
            >
            <c-button
            v-if="can_edit"
              size="lg"
              m=".75rem"
              variant-color="red"
              variant="outline"
              @click="deleteMicroorganism"
            >
              <c-icon name="trash" />
              <span class="hidden md:inline-block ml-2">Remove</span></c-button
            >
            <c-button
              size="lg"
              m=".75rem"
              variant-color="green"
              variant="outline"
              class="text-green-600"
              @click="open"
              :isDisabled="hasImage"
            >
              <c-icon name="images" />
              <span class="hidden md:inline-block ml-2"
                >Gallery
              </span></c-button
            >
          </div>
        </c-flex>
        <c-flex flexDirection="row" justify="flex-start">
          <!-- <c-button
            size="lg"
            mb="1.5rem"
            variant-color="blue"
            @click="$router.go(-1)"
          >
            <c-icon name="arrow-left" mr="0.5rem" />Back</c-button
          > -->
        </c-flex>
        <c-flex>
          <!-- size prop. => width size -->
          <c-modal
            :is-open="isOpen"
            :on-close="close"
            is-centered
            :size="`50vw`"
            :closeOnOverlayClick="false"
          >
            <c-modal-content ref="content">
              <c-modal-header>
                <SciNameComponent :sci_name="microorganism.scientific_name || 'ไม่ระบุชื่อ'"/>
          </c-modal-header>
              <c-modal-close-button />
              <c-modal-body>
                <c-box shadow="md" border-width="2px">
                  <carousel
                    :adjustableHeight="true"
                    :perPage="1"
                    :navigationEnabled="true"
                    :speed="1500"
                    :loop="true"
                  >
                    <slide v-for="(image, idx) in images" :key="idx">
                      <!-- adjust height prop. as you wish -->
                      <img
                        style="width: 200; height: 70vh; object-fit: contain; margin: auto;"
                        :src="image"
                      />
                    </slide>
                  </carousel>
                </c-box>
              </c-modal-body>
              <c-modal-footer> </c-modal-footer>
            </c-modal-content>
            <c-modal-overlay />
          </c-modal>
        </c-flex>
        <c-box bgColor="black" textAlign="center" py=".5rem">
          <SciNameComponent :sci_name="microorganism.scientific_name || 'ไม่ระบุชื่อ'" class="text-4xl text-white"/>
          <!-- <c-text fontSize="3xl" color="white" v-html="formatName(microorganism.scientific_name || 'ไม่ระบุชื่อ')"></c-text> -->
        </c-box>
        <c-stack bgColor="white" mb="0.75rem" pb="6">
          <c-box px="1rem" pt="1rem">
            <c-text fontSize="4xl" class="font-extrabold header-text"
              >General Information</c-text
            >
          </c-box>
          <!-- scroll media -->
          <div v-if="images.length > 0" class="pb-4">
            <horizontal-media-scroller
              :img="images"
            ></horizontal-media-scroller>
            <p class="italic flex justify-end pr-8">
              For more images, click gallery button
            </p>
          </div>
          <div class="flex flex-col md:flex-row text-xl md:info-text px-8 py-4">
            <div class=" md:flex-grow w-full md:w-1/2">
              <!-- <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
            <c-box>
              <c-text class="font-bold header-text">Full scientific name (ชื่อวิทยาศาสตร์แบบเต็ม)</c-text>
            </c-box>
            <c-box class="data-text">
              <c-text>{{ microorganism.full_scientific_name || "-" }}</c-text>
            </c-box>
          </c-simple-grid> -->

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Scientific name (ชื่อวิทยาศาสตร์)</c-text
                  >
                </c-box>
                <c-box class="data-text" >
                  <c-text>
                    <SciNameComponent :sci_name="microorganism.scientific_name || '-'"/>
                  </c-text><!-- <c-text v-html="formatName(microorganism.scientific_name || '-')"></c-text> -->
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                pb="6"
                px="2rem"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text">KU code (รหัส KU)</c-text>
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.ku_code || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                pb="6"
                px="2rem"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Microbial Code (รหัสอื่น ๆ เช่น รหัส อพ.สธ.)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{
                    microorganism.other_microbial_code || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                pb="6"
                px="2rem"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text">Isolate code (รหัสไอโซเลต)</c-text>
                </c-box>
                <c-box class="data-text ">
                  <c-text>{{ microorganism.isolate_code || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                pb="6"
                px="2rem"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text">Strain code (รหัสสายพันธุ์)</c-text>
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.strain_code || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Microorganism type (ประเภทของจุลินทรีย์)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ formatHumanName(microorganism.microorganism_type) || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <!-- <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Microorganism Subtype (ประเภทย่อยของจุลินทรีย์)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{
                    microorganism.microorganism_subtype || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid> -->

              <c-simple-grid
                :columns="1"
                :spacing="1"
                pb="6"
                px="2rem"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-extrabold"
                    >Nomenclatural code
                    (หลักเกณฑ์ในการตั้งชื่อวิทยาศาสตร์)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.nomenclatural_code || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                pb="6"
                px="2rem"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text">Synonym (ชื่อพ้อง)</c-text>
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.synonym || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <!-- <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box class="font-bold header-text">
                  <c-text>Taxonomic order (ลำดับอนุกรมวิธาน)</c-text>
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.higher_taxon || "-" }}</c-text>
                </c-box>
              </c-simple-grid> -->
              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="4">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Classification (การจำแนกชั้น)</c-text
                  >
                </c-box>
                <c-box>
                  <c-simple-grid :columns="2" :spacingX="1" :spacingY="4" px="1rem" pb="4">
                    <c-text>{{
                      `Kingdom (อาณาจักร) : `
                    }}</c-text>
                    <c-text>
                      <SciNameComponent :sci_name="microorganism.kingdom || '-'"/>
                    </c-text>

                    <c-text>{{
                      `Phylum (ไฟลัม) : `
                    }}</c-text>
                    <c-text >
                      <SciNameComponent :sci_name="microorganism.phylum || '-'"/>
                    </c-text>

                    <c-text>{{
                      `Class (ชั้น) :`
                    }}</c-text>
                    <c-text >
                      <SciNameComponent :sci_name="microorganism.class || '-'"/>
                    </c-text>

                    <c-text>{{
                      `Order (อันดับ) :`
                    }}</c-text>
                    <c-text>
                      <SciNameComponent :sci_name="microorganism.order || '-'"/>
                    </c-text>

                    <c-text>{{
                      `Family (วงศ์) :`
                    }}</c-text>
                    <c-text >
                      <SciNameComponent :sci_name="microorganism.family || '-'"/>
                    </c-text>

                    <c-text>{{
                      `Genus (สกุล) :`
                    }}</c-text>
                    <c-text>
                      <SciNameComponent :sci_name="microorganism.genus || '-'"/>
                    </c-text>

                    <c-text>{{
                      `Species (ชนิด) :`
                    }}</c-text>
                    <c-text>
                      <SciNameComponent :sci_name="microorganism.species || ''"/>
                    </c-text>

                    <c-text>{{
                      `Subspecies (ชนิดย่อย) :`
                    }}</c-text>
                    <c-text>
                      <SciNameComponent :sci_name="microorganism.subspecies || ''"/>
                    </c-text>

                    <c-text>{{
                      `Variety (พันธุ์) :`
                    }}</c-text>
                    <c-text>
                      <SciNameComponent :sci_name="microorganism.variety || '-'"/>
                    </c-text>
                  </c-simple-grid>

                  <c-stack :spacing="1" class="data-text">
                  </c-stack>
                </c-box>
              </c-simple-grid>
            </div>
            <div class="md:flex-grow w-full md:w-1/2">
              <c-simple-grid
                :columns="1"
                :spacing="1"
                pb="6"
                px="2rem"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Location Remark (รายละเอียดแหล่งที่ค้นพบ)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.location_remarks || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >รายละเอียดเพิ่มเติมของการค้นพบ</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.found_description || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text">Surveyer (ชื่อผู้สำรวจ)</c-text>
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ formatHumanName(microorganism.survey_by.name) || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text">Survey date (วันที่สำรวจ)</c-text>
                </c-box>
                <c-box class="data-text">
                  <c-text>{{
                    momentDate(microorganism.survey_date) || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Sampling by (ชื่อผู้เก็บตัวอย่าง)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ formatHumanName(microorganism.sampling_by.name) || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Sampling date (วันที่เก็บตัวอย่าง)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{
                    momentDate(microorganism.sampling_date) || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Isolation by (ชื่อผู้แยกเชื้อ)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ formatHumanName(microorganism.isolation_by.name) || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Isolation date (วันที่แยกเชื้อ)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{
                    momentDate(microorganism.isolation_date) || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Identification by (ผู้ระบุชนิด)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{
                    formatHumanName(microorganism.identification_by.name) || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Found status (สถานะการค้นพบ)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.found_status || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Contact person (ผู้ตั้งชื่อ/ผู้ค้นพบ)</c-text
                  >
                </c-box>
                <c-box
                  class="data-text"
                  v-if="microorganism.founders.length == 0"
                >
                  <c-stack :spacing="1">
                    <c-text>-</c-text>
                  </c-stack>
                </c-box>
                <c-box v-if="microorganism.founders.length > 0">
                  <c-stack :spacing="1">
                    <c-text
                      class="data-text"
                      v-for="founder in microorganism.founders"
                      :key="founder.id"
                    >
                      {{ `${formatHumanName(founder.name)}` }}
                    </c-text>
                  </c-stack>
                </c-box>
              </c-simple-grid>

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Found date (เดือน/ปีที่ค้นพบ)</c-text
                  >
                </c-box>
                <c-box class="data-text">
                  <c-text>{{ microorganism.found_date || "-" }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                v-if="AC === 'AUTHORIZED'"
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Culture collection assession code
                    (รหัสหน่วยเก็บรักษา)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.culture_collection_assession_code || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>
              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text">Source (แหล่งที่พบ)</c-text>
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.source || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>

              <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6">
                <c-box>
                  <c-text class="font-bold header-text"
                    >Location (Province/District/Sub-district)</c-text
                  >
                </c-box>
                <c-box
                  v-if="microorganism.gps && microorganism.gps.length == 0"
                >
                  <c-stack :spacing="1">
                    <c-text class="data-text">{{
                      `จังหวัด: ${microorganism.province || "-"}`
                    }}</c-text>
                    <c-text class="data-text">{{
                      `อำเภอ: ${microorganism.district || "-"}`
                    }}</c-text>
                    <c-text class="data-text">{{
                      `ตำบล: ${microorganism.sub_district || "-"}`
                    }}</c-text>
                  </c-stack>
                </c-box>
                <c-box v-if="microorganism.gps && microorganism.gps.length > 0">
                  <c-stack :spacing="1">
                    <c-text
                      class="data-text"
                      v-for="location in microorganism.gps"
                      :key="location.id"
                    >
                      {{
                        `${location.province || "-"} / ${location.district || "-"} / ${location.sub_district || "-"}`
                      }}
                    </c-text>
                  </c-stack>
                </c-box>
              </c-simple-grid>
            </div>
          </div>
        </c-stack>

        <c-stack
          bgColor="white"
          my="0.75rem"
          py="6"
          pl="4"
          v-if="AC === 'AUTHORIZED'"
        >
          <c-box px="1rem">
            <c-text fontSize="4xl" class="font-extrabold header-text">Morphology</c-text>
          </c-box>
          <c-simple-grid
            :columns="['1', '1', '2', '2']"
            :spacing="1"
            px="2rem"
            py="1.5rem"
            class="text-xl md:info-text"
          >
            <div>
              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                class=""
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Colony Morphology (ลักษณะทางสัณฐานวิทยาของโคโลนี)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="pl-4 data-text">{{
                    microorganism.colony_morphology || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>
            </div>
            <div>
              <c-simple-grid
                :columns="1"
                :spacing="1"
                px="2rem"
                pb="6"
                class=""
              >
                <c-box>
                  <c-text class="font-bold header-text"
                    >Cell Morphology (ลักษณะทางสัณฐานวิทยาของเซลล์)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="pl-4 data-text">{{
                    microorganism.cell_morphology || "-"
                  }}</c-text>
                </c-box>
              </c-simple-grid>
            </div>
          </c-simple-grid>
        </c-stack>

        <c-stack
          bgColor="white"
          my="0.75rem"
          py="6"
          px="4"
          v-if="AC === 'AUTHORIZED'"
        >
          <c-box px="1rem">
            <c-text fontSize="4xl" class="font-extrabold header-text"
              >Ecology & Conditions</c-text
            >
          </c-box>
          <c-simple-grid
            :columns="['1', '1', '2', '2']"
            :spacing="1"
            px="2rem"
            py="1.5rem"
            class="text-xl md:info-text"
          >
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Ecology (ระบบนิเวศ/สภาพถิ่นที่อยู่อาศัย)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.ecology || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Special characteristic (ลักษณะเด่นของจุลินทรีย์)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.special_characteristic || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Storage Method (วิธีการเก็บรักษา)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.storage_method || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Storage Condition (สภาวะในการเก็บรักษาเชื้อ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.storage_condition || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Temperature for growth(อุณหภูมิเพื่อการเจริญ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.temperature_for_growth || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Oxygen Demand (ความต้องการอากาศ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.oxygen_demand || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Culture media (อาหารเลี้ยงเชื้อ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.culture_media || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >pH (ความเป็นกรดด่างของอาหารเลี้ยงเชื้อ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.ph_for_culture_media || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <!-- <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Growth Condition (สภาวะที่เหมาะสมในการเจริญ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.growth_condition || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid> -->
          </c-simple-grid>
        </c-stack>

        <c-stack bgColor="white" my="0.75rem" py="6" px="4">
          <c-box px="1rem">
            <c-text fontSize="4xl" class="font-extrabold header-text">Applications</c-text>
          </c-box>
          <c-simple-grid
            :columns="['1', '1', '2', '2']"
            :spacing="1"
            px="2rem"
            m="1.5rem"
            class="text-xl md:info-text "
          >
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text text-blue-600"
                    >Agriculture Application (ประโยชน์ทางการเกษตร)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.industrial_application || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text text-blue-600"
                    >Agriculture application description
                    (รายละเอียดของประโยชน์ด้านการเกษตร)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.agriculture_application_details || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
          </c-simple-grid>

          <div class=" border-b-2 border-gray-300 w-11/12 mx-auto" />

          <c-simple-grid
            :columns="['1', '1', '2', '2']"
            :spacing="1"
            px="2rem"
            m="1.5rem"
            class="text-xl md:info-text "
          >
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text text-green-600"
                    >Industrial Application (ประโยชน์ทางอุตสาหกรรม)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text ">{{
                    microorganism.industrial_application || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text text-green-600"
                    >Industrial Application Description
                    (รายละเอียดของประโยชน์ด้านอุตสาหกรรม)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.industrial_application_details || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
          </c-simple-grid>

          <div class=" border-b-2 border-gray-300 w-11/12 mx-auto" />

          <c-simple-grid
            :columns="['1', '1', '2', '2']"
            :spacing="1"
            px="2rem"
            m="1.5rem"
            class="text-xl md:info-text"
          >
            <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Others Applications (ประโยชน์อื่น ๆ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.others_applications || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
          </c-simple-grid>
        </c-stack>

        <c-stack
          bgColor="white"
          my="0.75rem"
          py="6"
          px="4"
          v-if="AC === 'AUTHORIZED'"
        >
          <c-box px="1rem">
            <c-text fontSize="4xl" class="font-extrabold header-text">Bibliography</c-text>
          </c-box>
          <div class="flex flex-col px-8 text-xl md:info-text">
            <c-simple-grid :columns="1" :spacing="1" px="2rem" py="1.5rem" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Published document (ชื่อเอกสารที่ตีพิมพ์)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.document_published || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>

            <c-simple-grid :columns="1" :spacing="1" px="2rem" py="1.5rem" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text">Keywords (คำสำคัญ)</c-text>
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.keyword || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>

            <c-simple-grid :columns="1" :spacing="1" px="2rem" py="1.5rem" class="">
                <c-box>
                  <c-text class="font-bold header-text">Document URL (ลิงก์เอกสาร)</c-text>
                </c-box>
                <c-box>
                  <c-text class="data-text" v-if="microorganism.document_url">
                    <span
                      class="document_url"
                      @click="openSite(microorganism.document_url)"
                      >{{ microorganism.document_url || "" }}</span
                    ></c-text
                  >
                  <c-text class="data-text" v-else>
                    <span
                      >{{  "-" }}</span
                    ></c-text
                  >
                </c-box>
            </c-simple-grid>
            
          </div>
          <c-simple-grid
            :columns="['1', '1', '2', '2']"
            :spacing="2"
            px="2rem"
            class="text-xl md:info-text"
          >
            <c-simple-grid :columns="1" :spacing="1" px="2rem" py="1.5rem"  class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Recorded by (ผู้บันทึกข้อมูล)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    formatHumanName(microorganism.recorder.name) || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            <!-- <c-simple-grid :columns="1" :spacing="1" px="2rem" pb="6" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Project Number (โครงการวิจัยย่อยที่)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    microorganism.project_number || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid> -->
            
            <c-simple-grid :columns="1" :spacing="1" px="2rem" py="1.5rem" class="">
              <div>
                <c-box>
                  <c-text class="font-bold header-text"
                    >Organization (หน่วยงานที่ค้นพบ)</c-text
                  >
                </c-box>
                <c-box>
                  <c-text class="data-text">{{
                    formatHumanName(microorganism.organization.name) || "-"
                  }}</c-text>
                </c-box>
              </div>
            </c-simple-grid>
            
            
          </c-simple-grid>
        </c-stack>
      </c-flex>
    </c-stack>
  </div>
</template>

<script>
import MicroorganismStore from "../store/MicroorganismStore";
import UserStore from "../store/User";
import Alert from "../helpers/Alert";
import { Carousel, Slide } from "vue-carousel";
import HorizontalMediaScroller from "@/components/HorizontalMediaScroller.vue";
import moment from "moment";
import SciNameComponent from '../components/SciNameComponent.vue';

export default {
  components: {
    Carousel,
    Slide,
    HorizontalMediaScroller,
    SciNameComponent
  },
  data() {
    return {
      microorganism: {},
      isLoading: true,
      AC: "AUTHORIZED",
      can_edit: false,
      user: {},
      images: [],
      isOpen: false,
      hasImage: true,
    };
  },
  async created() {
    UserStore.dispatch("toggleMenu", 1);
    await this.fetchMicroorganism();
    console.log(this.microorganism);
  },
  mounted() {
    if (localStorage.getItem("biodatabase")) {
      try {
        this.user = JSON.parse(localStorage.getItem("biodatabase"));
      } catch (e) {
        localStorage.removeItem("biodatabase");
      }
    }

  },
  methods: {
    momentDate(date) {
      if (date) {
        return moment(date).format("D MMM YYYY");
      }
      return;
    },
    formatName(sciName){
      var exceptionWord = ['sp.' , 'aff.'];
      let textArray = sciName.split(" ");
      textArray.forEach((element, index) => {
        if(!exceptionWord.includes(element)){
          textArray[index] = element.italics()

        }
      });
      return textArray.join(' ');

    },
    formatHumanName(humanName){
      humanName = humanName.toLowerCase();
      const arr = humanName.split(" ");

      //loop through each element of the array and capitalize the first letter.


      for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

      }
      return arr.join(" ");
    },
    async fetchMicroorganism() {
      this.isLoading = true;
      this.AC = UserStore.getters.isAuthen ? "AUTHORIZED" : "GUEST";
      this.user = JSON.parse(localStorage.getItem("biodatabase"));

      let res = await MicroorganismStore.dispatch(
        "fetchMicroorganismByID",
        this.$route.params.id
      );
      if (res.status === 200) {
        // TODO: validate some field before inject to element
        this.microorganism = MicroorganismStore.getters.getFetchedMicroorganism;
        this.validate();
        if (this.microorganism.recorder.id === this.user.user.researcher_id) {
          this.can_edit = true;
        }
        this.isLoading = false;
      }
    },
    validate() {
      if (this.microorganism.microorganism_sub_type === null) {
        this.microorganism.microorganism_sub_type = {
          name: "-",
        };
      }

      if (this.microorganism.hasOwnProperty("found_date")) {
        if (this.microorganism.found_date) {
          this.microorganism.found_date = this.microorganism.found_date.substr(
            0,
            7
          ).split("-").reverse().join("/");
        }
      }

      if (
        this.microorganism.hasOwnProperty("images") &&
        this.microorganism.images.length > 0
      ) {
        this.hasImage = false;
        for (let image of this.microorganism.images) {
          this.images.push(
            process.env.VUE_APP_APIURL +
              ":" +
              process.env.VUE_APP_PORT +
              image.path
          );
        }
      }
    },
    editMicroorganism() {
      this.$router.push(`edit/${this.$route.params.id}`);
    },
    async deleteMicroorganism() {
      let isDelete = await Alert.yesNo("Will you delete?");
      if (isDelete) {
        try {
          let res = await MicroorganismStore.dispatch(
            "deleteMicroorganism",
            this.$route.params.id
          );
          if (res.status === 200) {
            this.$router.push("/database");
          }
        } catch (error) {
          // if (error.response.status == 401) {
          //     let isOk = await Alert.window("error", "token expired", "please login again");
          //     if (isOk) {
          //       localStorage.removeItem("biodatabase")
          //       this.$router.push({
          //         path: "/login",
          //       })
          //       window.location.reload(true);
          //     }
          //   }
          await Alert.window(
            "error",
            "Deletion Error",
            "Cannot delete the microorganism"
          );
        }
      }
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    openSite(url) {
      window.open(url);
    },
  },
};
</script>

<style>
.document_url {
  @apply underline
  text-blue-600
  hover:text-blue-400
  cursor-pointer;
}
</style>
