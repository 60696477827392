<template>
  <div class="mx-auto py-48 w-full justify-center items-center flex login-bg">
    <loading v-if="isLoading" />

    <div
      class=" flex justify-center items-center sm:w-4/5 md:w-4/5 lg:w-3/5 xl:w-2/5 h-1/2 my-auto border rounded-lg flex-col p-5 sm:p-20 shadow-lg bg-white"
    >
      <div class="w-full text-center mb-4 text-3xl md:text-5xl lg:text-7xl">
        <c-heading>Login</c-heading>
      </div>
      <div class="w-3/4 mx-auto my-auto">
        <div class="grid grid-cols-6 gap-4  ">
          <div class="col-span-1 text-center my-auto">
            <font-awesome-icon icon="user-circle" class="text-4xl" />
          </div>
          <div class="col-span-5 ">
            <c-input placeholder="Username" v-model="form.username" />
          </div>
          <div class="col-span-1 text-center my-auto">
            <font-awesome-icon icon="lock" class="text-3xl" />
          </div>
          <div class="col-span-5 ">
            <c-input-group size="md">
              <c-input
                pr="4.5rem"
                :type="show ? 'text' : 'password'"
                placeholder="Enter password"
                v-model="form.password"
              />
              <c-input-right-element width="4.5rem">
                <c-button mr="0.5em" h="1.75rem" size="sm" @click="show = !show" :left-icon="show ? `eye-slash` : `eye`">
                </c-button>
              </c-input-right-element>
            </c-input-group>
          </div>
        </div>
        <div class="w-full my-4 mx-auto">
          <c-button variant-color="blue" class="w-full" @click="login"
            >SIGN IN</c-button
          >
        </div>
        <c-button
          variant-color="blue"
          variant="link"
          v-on:click="$router.push({ name: 'Register' })"
          >REGISTER</c-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import UserStore from "@/store/User.js";
import Alert from "@/helpers/Alert";

export default {
  data() {
    return {
      errors: [],
      form: {
        username: "",
        password: "",
      },
      password: "",
      show: false,
      isLoading: false,
    };
  },
  created() {
    UserStore.dispatch("toggleMenu", 0);
  },
  methods: {
    async login() {
      let payload = {
        validate: this.form.username,
        password: this.form.password,
      };
      try {
        let res = await UserStore.dispatch("login", payload);
        this.isLoading = true;
        if (res.status == 200) {
          this.isLoading = false;
          location.href = '/welcome'

        }
        Alert.mixin("success", "Login successful");

      } catch (error) {
        this.errors = error.response.data.validator;
        let isOk = Alert.window(
          "error",
          "Sign in Failed",
          error.response.data.error
        );
        if (isOk) {
          this.isLoading = false;
        }
      }
      // alert(`System test: Login success (dev. phase)\nUse path '/welcome' to go to the page after user log in`);
      // this.$router.push('/welcome');
    },
  },
};
</script>

<style>
.login-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../../public/login-bg.jpg");
}
</style>
